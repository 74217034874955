/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This helper function gets the number of notifications
 * that the user has not seen since the last time that
 * they clicked on the notifications button
 *
 * @param {object} notifications - the notifications object
 * @param {string} lastClickDate - the last time the button was clicked
 * @returns {number} the number of unseen notificaitons
 */
export const getNotificationCount = (notifications, lastClickDate) => {
  return notifications
    ? Object.keys(
        Object.fromEntries(
          Object.entries(notifications).filter(
            ([_, value]) => value.dateCreated > lastClickDate
          )
        )
      ).length
    : 0;
};
