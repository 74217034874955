/**
 * Copyright 2022 Loop Technology, Inc.
 */

import { CLEAR_GENERAL_NOTIFICATIONS } from "../../actionTypes/notificationTypes";

/**
 * This action function resets the user feed in
 * scenarios when the user changes their community
 *
 * @param {object} dispatch - The redux dispatch object
 */
export const clearGeneralNotifications = () => async (dispatch) => {
  return dispatch({ type: CLEAR_GENERAL_NOTIFICATIONS });
};
