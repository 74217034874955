/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  //Session Types
  SET_USER_SESSION_LOGOUT,
  SET_USER_SESSION_ERRORS,
} from "../../../../actionTypes/sessionTypes";

// Persisted Store
import combinedStore from "../../../../store";

// Auth Axios Instance
import authAxios from "../../../../../util/axios/Instances/auth.instance";

/**
 * This action function logouts out the authenticated user. The action
 * requests the removal of the refreshToken HTTP-Only Cookie from the
 * (/logout) route which is built ontop of the "Logout" handler in Express.
 * This function additionally removes the then removes the Authorization
 * Bearer token from the user's local storage.
 *
 * @param {boolean} revokeOnly - only revoke the refresh token
 * @param {object} dispatch - The redux dispatch object
 */
export const logoutUser = (revokeOnly, navigate, isEnd) => async (dispatch) => {
  if (!revokeOnly) handleReduxStoreReset(navigate, isEnd);
  await revokeRefreshCookie(revokeOnly, dispatch, navigate, isEnd);
};

/**
 * This helper function flushes and then perges the data
 * from each of the reducers in the redux store.
 * @returns {Promise}
 */
const handleReduxStoreReset = async (navigate, isEnd) => {
  if (navigate && !isEnd) navigate("/");
  return combinedStore.persistor
    .purge()
    .then(() => {
      return combinedStore.persistor.flush();
    })
    .then(() => {
      combinedStore.persistor.pause();
    });
};

/**
 * This helper function returns a promise that revokes
 * the refresh token and dispatches an update to the
 * redux store.
 *
 * @param {boolean} revokeOnly - only revoke the refresh token
 * @param {object} dispatch - the dispatch object
 * @returns {Promise}
 */
const revokeRefreshCookie = async (revokeOnly, dispatch, navigate, isEnd) => {
  if (!revokeOnly) localStorage.removeItem("auth");
  localStorage.removeItem("m-");
  return await authAxios
    .get("/logout")
    .then(() => {
      localStorage.removeItem("AuthToken");
      if (!revokeOnly) dispatch({ type: SET_USER_SESSION_LOGOUT });
      if (navigate && isEnd) navigate("/");
      if (!navigate && isEnd) window.location = "/";
    })
    .catch((error) => {
      dispatch({
        type: SET_USER_SESSION_ERRORS,
        payload: error,
      });
    });
};
