/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";

// Material UI
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

const RouteLoading = () => {
  return (
    <Stack
      direction="column"
      sx={{ width: "100%", mt: "10vh" }}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress color="secondary" />
    </Stack>
  );
};

export default RouteLoading;
