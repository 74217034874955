/**
 * This helper function creates the Bearer token for the
 * Authoriztion HTTP Header and creates a reference to the
 * token in the in the user's local storage.
 *
 * @param {string} token - the JWT Authorization token
 */
export const setAuthorizationHeaderHelper = (token) => {
  const AuthToken = `Bearer ${token}`;
  localStorage.setItem("AuthToken", AuthToken);
};