/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// Product Analytics
import { Mixpanel } from "../../../Analytics/mixpanel";

// Redux
import { connect } from "react-redux";
import { getCommunityRules } from "../../../../redux/actions/user/atomic/get/getCommunityRules";

// Components
import StyledFlowDialog from "../../Dialogs/styledDialogs/styledFlowDialog";
import { rulesContent } from "./Functions/ruleFunctions";
//import { getAnalyticsEventNameForStep } from "./Analytics/onboardingAnalytics";

const RulesFlow = ({
  open,
  lastId,
  isMobile,
  isTablet,
  lastLevel,
  handleClose,
  type = null,
  getCommunityRules,
  hasHeaderButton = true,
  reachedVotingThreshold = false,
  RULES = {},
}) => {
  const containerRef = useRef(null);
  const [dialogType, setDialogType] = useState(type);
  const [clickedRule, setClickedRule] = useState(null);
  const hasBackButton =
    hasHeaderButton &&
    ["change", "proposal", "removal", "details"].includes(dialogType);

  useEffect(() => {
    Mixpanel.time_event("__rules");
  }, []);

  /**
   * This handler function toggles the open state of the
   * selected rules dialog.
   *
   * @param {object} e - the event object
   * @param {string} type - the type of rule
   * @param {object} rule - the rule object
   * @returns {function} toggles the open state of the select dialog
   */
  const handleDialogClick = (e, type, rule) => {
    if (["change", "removal", "details"].includes(type)) setClickedRule(rule);
    return setDialogType(type);
  };

  useEffect(() => {
    if (!lastId && !lastLevel && open) fetchRulesHelper(lastId, lastLevel);
  }, [open]);

  /**
   * This helper function fetches more rules and adds
   * them to the redux feed.
   *
   * @param {string} lastId - the last rule id
   * @param {string} lastLevel - the last rules importance level
   * @returns {function} - the action function with the correct parameters
   */
  const fetchRulesHelper = useCallback(
    (lastId = null, lastLevel = null) => {
      getCommunityRules(lastId, lastLevel, 6);
    },
    [lastId, lastLevel]
  );

  /**
   * Get the active component content
   */
  const active = rulesContent(
    dialogType,
    isMobile,
    clickedRule,
    handleClose,
    handleDialogClick,
    reachedVotingThreshold,
    RULES
  );

  return (
    <StyledFlowDialog
      open={open}
      isMobile={isMobile}
      isTablet={isTablet}
      backButton={hasBackButton}
      title={active.content.title}
      containerRef={containerRef}
      onClose={() => handleClose(false)}
      paperSxProps={active.content?.paperSxProps ?? {}}
      ariaLabelledby={active.content.ariaLabelledby}
      handleClick={
        hasBackButton ? () => setDialogType(null) : () => handleClose(false)
      }
    >
      {active.component}
    </StyledFlowDialog>
  );
};

RulesFlow.propTypes = {
  open: PropTypes.bool,
  lastId: PropTypes.string,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  lastLevel: PropTypes.string,
  handleClose: PropTypes.func,
  type: PropTypes.string,
  hasHeaderButton: PropTypes.bool,
  getCommunityRules: PropTypes.func,
  reachedVotingThreshold: PropTypes.bool,
  RULES: PropTypes.object,
};

const mapStateToProps = (state) => ({
  RULES: state.user.community.rules.rules,
  lastId: state.user.community.rules.lastId,
  lastLevel: state.user.community.rules.lastLevel,
  reachedVotingThreshold: state.user.community.reachedVotingThreshold,
});

const mapActionsToProps = {
  getCommunityRules,
};

export default connect(mapStateToProps, mapActionsToProps)(RulesFlow);
