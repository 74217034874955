/**
 * Copyright 2022 Loop Technology, Inc.
 */

import * as yup from "yup";

const ruleChangeSchema = yup.object().shape({
  title: yup.string().required("Rule must have a short title"),
  description: yup
    .string()
    .required("Rule must have details about what the rule covers."),
  importanceLevel: yup
    .string()
    .required("We need to know the importance of this rule"),
});

export default ruleChangeSchema;
