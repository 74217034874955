/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useCallback } from "react";

/**
 * This custom hook sets up the "socket" (really long-polling) into
 * the notifications collection in the Firestore DB. It uses the last
 * date that the user clicks on the notification or friend request
 * button to determine where it should start its query.
 *
 * @param {string} type - the type of notification (friend request, general)
 * @param {string} handle - the user handle
 * @param {string} communityHandle - the auth user community handle
 * @param {string} lastClickDate - the last time the notification button was clicked
 * @param {function} setSnapshotNotifications - the action function to set notifications
 * @param {boolean} isAppRoute - if the current route is the app
 * @returns {null}
 */
const useRealtimeNotificationSocket = (
  type,
  handle,
  communityHandle,
  lastClickDate,
  setSnapshotNotifications,
  isAppRoute
) => {
  /**
   * This callback function creates and wraps
   * the functions to create the snapshot listener
   * in Firestore
   */
  const createSnapshot = useCallback(
    async (lastClickDate) =>
      await createSnapshotHelper(
        type,
        handle,
        communityHandle,
        lastClickDate,
        setSnapshotNotifications
      ),
    [communityHandle]
  );

  useEffect(() => {
    if (!lastClickDate || !isAppRoute || !handle || !communityHandle) return;
    let unsubscribe = createSnapshot(lastClickDate);
    return () => (typeof unsubscribe === "function" ? unsubscribe() : null);
  }, []);

  return;
};

export default useRealtimeNotificationSocket;

/**
 * This helper function returns a promise
 * that dynamically imports the firebase
 * packages.
 *
 * @returns {Promise}
 */
const fbAuthAndDb = async () => {
  return Promise.all([
    import("../util/firebase/initAuth"),
    import("../util/firebase/initFirestore"),
  ]).then(([auth, firestore]) => {
    return { auth, firestore };
  });
};

/**
 * This helper function creates the snapshot for the
 * notifications. It builds the correct query based on
 * the submitted type and the users last click date.
 *
 * @param {string} type - the type of notification
 * @param {string} handle - the user handle
 * @param {string} communityHandle - the community handle
 * @param {string} lastClickDate - the last click date
 * @param {function} setSnapshotNotifications - the set state handler
 * @returns {Promise} - the onSnapshot promise
 */
const createSnapshotHelper = async (
  type,
  handle,
  communityHandle,
  lastClickDate,
  setSnapshotNotifications
) => {
  const {
    firestore: { db, query, collection, orderBy, where, onSnapshot },
  } = await fbAuthAndDb();
  const ref = query(
    collection(db, "notifications"),
    where("type", "==", type),
    where("notificationDetails.to.handle", "in", [handle, communityHandle]),
    where("dateCreated", ">=", lastClickDate),
    orderBy("dateCreated", "desc")
  );
  return getSnapshotHelper(ref, type, onSnapshot, setSnapshotNotifications);
};

/**
 * This helper function creates the correct snapshot listener
 * to firestore for the users notifications.
 *
 * @param {object} ref - the firestore document reference
 * @param {string} type - the type of notifications (general, friendRequest)
 * @param {function} setSnapshotNotifications - the action handler to set notifs
 * @returns {function} - the onSnapshot listener
 */
const getSnapshotHelper = (ref, type, onSnapshot, setSnapshotNotifications) => {
  return onSnapshot(
    ref,
    (querySnapshot) => {
      let data = {};
      querySnapshot.forEach((doc) => {
        let id = doc.data().notificationId;
        data = { ...data, [id]: { ...doc.data() } };
      });
      setSnapshotNotifications(data, type);
    },
    (error) => {
      setSnapshotNotifications({}, type, error);
      return () => ({});
    }
  );
};
