/**
 * Copyright 2022 Loop Technology, Inc.
 */

//Session Action Types
export const SET_USER_SESSION = "SET_USER_SESSION";
export const LOADING_USER_SESSION = "LOADING_USER_SESSION";
export const SET_USER_SESSION_ERRORS = "SET_USER_SESSION_ERRORS";
export const SET_USER_SESSION_REFRESH_ERRORS =
  "SET_USER_SESSION_REFRESH_ERRORS";
export const SET_EMAIL_VERIFIED = "SET_EMAIL_VERIFIED";
export const SET_APP_LOADING = "SET_APP_LOADING";
export const CLEAR_USER_SESSION_ERRORS = "CLEAR_USER_SESSION_ERRORS";
export const SET_USER_SESSION_LOGOUT = "SET_USER_SESSION_LOGOUT";

//ReportABug
export const SET_PLATFORM_BUG = "SET_PLATFORM_BUG";
export const LOADING_PLATFORM_BUG = "LOADING_PLATFORM_BUG";
export const SET_PLATFORM_BUG_ERRORS = "SET_PLATFORM_BUG_ERRORS";
export const CLEAR_PLATFORM_BUG_ERRORS = "CLEAR_PLATFORM_BUG_ERRORS";
