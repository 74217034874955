/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React from "react";
import { Navigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import ProtectedComponent from "../RouteComponents/protectedComponent";

// Checks if user is authenticated
const getAuthStatus = () => localStorage.getItem("auth");

const ProfileRoute = ({
  handle,
  component,
  isAuthenticated = Boolean(getAuthStatus()),
}) => {
  const params = useParams();
  return !isAuthenticated ? (
    <Navigate to="/" />
  ) : handle === params.handle ? (
    <Navigate to="/profile" />
  ) : (
    <ProtectedComponent component={component} />
  );
};

const mapStateToProps = (state) => ({
  handle: state.user.core.handle,
});

ProfileRoute.propTypes = {
  handle: PropTypes.string,
  isAuthenticated: PropTypes.bool,
};

export default connect(mapStateToProps)(React.memo(ProfileRoute));
