/**
 * Copyright 2022 Loop Technology, Inc.
 */

export const globalThemeSettings = (darkMode = false, isAppRoute = false) => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 450,
      md: 768,
      lg: 1024,
      xl: 1380,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: darkMode ? "rgba(29, 36, 52, 1)" : null,
          boxShadow: darkMode ? "0 2px 0.4rem 0 rgba(48, 79, 254, 0.09)" : null,
        },
      },
    },
  },
  palette: {
    mode: darkMode ? "dark" : "light",
    primary: {
      light: "#7a7cff",
      main: "#304ffe",
      dark: "#0026ca",
      contrastText: "#fff",
    },
    secondary: {
      light: "#BCEAFF",
      main: "#00B0FF",
      dark: "#0091EA",
      contrastText: "#fff",
    },
    error: {
      light: "#FF80AB",
      main: "#FF4081",
      dark: "#F50057",
      contrastText: "#fff",
    },
    warning: {
      light: "#FFAB40",
      main: "#FF9100",
      dark: "#FF6D00",
      contrastText: "#fff",
    },
    info: {
      light: "#FECB34",
      main: "#FEC10B",
      dark: "#F4B701",
      contrastText: "#fff",
    },
    success: {
      light: "#69F0AE",
      main: "#00CC69",
      dark: "#00A354",
      contrastText: "#ffffff",
    },
    text: {
      primary: darkMode ? "rgba(240, 243, 247, 1)" : "rgba(53, 69, 89, 1)", //#354559
      secondary: darkMode
        ? "rgba(240, 243, 247, 0.8)"
        : "rgba(53, 69, 89, 0.76)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
    grey: {
      50: "rgba(240, 243, 247, 0.8)",
      100: "rgba(240, 243, 247, 1)", //F0F3F7
      200: "rgba(230, 235, 239, 1)",
      300: "rgba(218, 225, 231, 1)",
      400: "rgba(206, 215, 223, 1)",
      500: "rgba(193, 205, 215, 1)",
      600: "rgba(180, 195, 207, 1)",
      700: "rgba(168, 185, 199, 1)",
      800: "rgba(155, 175, 191, 1)",
    },
    background: {
      paper: darkMode ? "rgba(29, 36, 52, 1)" : "#fff", //"rgba(29, 36, 52, 1)"
      default: darkMode ? "rgba(9, 13, 35, 1)" : "rgba(240, 243, 247, 1)",
    },
    action: {
      disabledBackground: "rgba(233, 237, 241, 0.5)",
    },
  },
  typography: {
    useNextVariants: true,
    htmlFontSize: 16,
    fontfamily: ["Dm Sans", "Poppins"],
    h1: {
      fontFamily: "Poppins",
      fontSize: "96px",
      lineHeight: "112px",
      fontWeight: 400,
      letterSpacing: "1px",
    },
    h2: {
      fontFamily: "Poppins",
      fontSize: "60px",
      lineHeight: "72px",
      fontWeight: 400,
      letterSpacing: "-0.5px",
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: "48px",
      lineHeight: "56px",
      fontWeight: 400,
      letterSpacing: "0px",
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: "34px",
      lineHeight: "38px",
      fontWeight: 400,
      letterSpacing: "0.25px",
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: 400,
      letterSpacing: "0px",
    },
    h6: {
      fontFamily: "Poppins",
      fontSize: "20px",
      lineHeight: "26px",
      fontWeight: 400,
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontFamily: "Dm Sans",
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 400,
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontFamily: "Dm Sans",
      fontSize: "15px",
      lineHeight: "22px",
      fontWeight: 400,
      letterSpacing: "0.1px",
    },
    body1: {
      fontFamily: "Dm Sans",
      fontSize: "15px",
      lineHeight: "20px",
      fontWeight: 400,
      letterSpacing: "0.15px",
    },
    body2: {
      fontFamily: "Dm Sans",
      fontSize: "13.5px",
      lineHeight: "16px",
      fontWeight: 400,
      letterSpacing: "0.15px",
    },
    caption: {
      fontFamily: "Dm Sans",
      fontSize: "13px",
      lineHeight: "20px",
      fontWeight: 500,
      letterSpacing: "0.4px",
    },
    overline: {
      fontFamily: "Dm Sans",
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: 400,
      letterSpacing: "1px",
    },
    button: {
      textTransform: "none",
      fontFamily: "Dm Sans",
      fontSize: "15px",
      lineHeight: "15px",
      fontWeight: 400,
      letterSpacing: "0.4px",
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    "none",
    darkMode
      ? "0 2px 0.3rem 0 rgba(240, 243, 247, 0.12)"
      : "0 2px 0.3rem 0 rgba(0, 0, 0, 0.12)",
    darkMode
      ? "0 2px 0.8rem 0 rgba(240, 243, 247, 0.19)"
      : "0 2px 0.8rem 0 rgba(0, 0, 0, 0.19)",
    darkMode
      ? "0 4px 0.9rem 0 rgba(240, 243, 247, 0.15)"
      : "0 4px 0.9rem 0 rgba(0, 0, 0, 0.15)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
});
