/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React from "react";
import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Components
import ProtectedComponent from "../RouteComponents/protectedComponent";

// Checks if user is authenticated
const getAuthStatus = () => localStorage.getItem("auth");

const LeaderRoute = ({
  isLeader,
  component,
  isAuthenticated = Boolean(getAuthStatus()),
}) => {
  return !isAuthenticated ? (
    <Navigate to="/" />
  ) : !isLeader ? (
    <Navigate to="/community" />
  ) : (
    <ProtectedComponent component={component} />
  );
};

const mapStateToProps = (state) => ({
  isLeader: state.user.core.community.isLeader,
});

LeaderRoute.propTypes = {
  isLeader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(React.memo(LeaderRoute));
