/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledChip from "../../../styledComponents/styledChip";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const CharacteristicSection = ({
  array,
  arrayType,
  title,
  subtitle,
  values,
  color = "primary",
  colorType = "main",
}) => {
  /**
   * This helper function sets the clicked state
   * of the chips in each section. For the personality section
   * we only allow one choice, so this function ensures that
   * only one is clicked at all times.
   *
   *
   * @param {string} label current chip label
   * @returns {boolean}
   */
  const clicked = (label) => {
    if (values[`${arrayType}`] !== "personality") {
      return values[`${arrayType}`].includes(label);
    } else {
      return values[`${arrayType}`] === label;
    }
  };

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          sx={{ color: `${color}.${colorType}`, mt: 1 }}
          align="center"
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ mt: 0.5, mb: 2 }}
          align="center"
        >
          {subtitle}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {array.map((section, index) => (
          <React.Fragment key={index}>
            <StyledChip
              clicked={clicked(section.label)}
              icon={section.icon}
              label={section.label}
              name={arrayType}
              value={section.label}
              type={arrayType}
            />
          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  );
};

CharacteristicSection.propTypes = {
  array: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.object,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  colorType: PropTypes.oneOf(["main", "dark", "light"]),
  arrayType: PropTypes.oneOf([
    "lifestyles",
    "activities",
    "identities",
    "personality",
  ]),
};

export default CharacteristicSection;
