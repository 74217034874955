/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Components
import BaseRules from "../Content/baseRules";
import StyledRuleChange from "../Content/styledRuleChange";
import StyledRuleProposal from "../Content/styledRuleProposal";
import StyledRuleDetails from "../Content/styledRuleDetails";
import StyledRuleRemoval from "../Content/styledRuleRemoval";
import { StyledSuccess, StyledWarning } from "../../../Text/styledText";

export const rulesContent = (
  type,
  isMobile,
  clickedRule,
  handleClose,
  handleDialogClick,
  reachedVotingThreshold,
  RULES
) => {
  switch (type) {
    case "proposal":
      return {
        component: (
          <StyledRuleProposal isMobile={isMobile} handleClose={handleClose} />
        ),
        content: {
          title: (
            <span>
              Suggest a <StyledSuccess bold>New Rule</StyledSuccess>
            </span>
          ),
          ariaLabelledby: "Propose a New Rule - Loop Community Rules",
        },
      };
    case "removal":
      return {
        component: (
          <StyledRuleRemoval
            isMobile={isMobile}
            rule={clickedRule}
            handleClose={handleClose}
          />
        ),
        content: {
          title: (
            <span>
              Suggest Rule <StyledWarning bold>Removal</StyledWarning>
            </span>
          ),
          ariaLabelledby:
            "Suggest the Removal of an Existing Rule - Loop Community Rules",
        },
      };
    case "change":
      return {
        component: (
          <StyledRuleChange
            isMobile={isMobile}
            rule={clickedRule}
            handleClose={handleClose}
          />
        ),
        content: {
          title: (
            <span>
              Suggest a Rule <StyledSuccess bold>Change</StyledSuccess>
            </span>
          ),
          ariaLabelledby:
            "Propose a Change to an Existing Rule - Loop Community Rules",
        },
      };
    case "details":
      return {
        component: (
          <StyledRuleDetails
            isMobile={isMobile}
            rule={clickedRule}
            handleClose={handleClose}
          />
        ),
        content: {
          title: (
            <span>
              Rule <StyledSuccess bold>Details</StyledSuccess>
            </span>
          ),
          ariaLabelledby: "Specific Rule Details - Loop Community Rules",
        },
      };
    default:
      return {
        component: (
          <BaseRules
            isMobile={isMobile}
            handleDialogClick={handleDialogClick}
            reachedVotingThreshold={reachedVotingThreshold}
            RULES={RULES}
          />
        ),
        content: {
          title: (
            <span>
              Community <StyledSuccess bold>Rules</StyledSuccess>
            </span>
          ),
          ariaLabelledby: "Loop Community Rules",
        },
      };
  }
};
