/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledUserAvatar from "../../Avatars/styledAvatars/styledUserAvatar";

// Material UI
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";

const StyledIconElement = ({
  item: { type, icon, primary, secondary },
  textColor = false,
}) => {
  return (
    <React.Fragment>
      <ListItemIcon
        sx={
          type === "subElement" ? { minWidth: 30 } : { minWidth: 56, mr: 0.75 }
        }
      >
        {icon ? icon : <StyledUserAvatar height={5} width={5} />}
      </ListItemIcon>
      <ListItemText
        sx={
          type === "avatar"
            ? { minWidth: 160 }
            : type === "subElement" && !textColor
            ? { color: (theme) => theme.palette.text.primary }
            : {}
        }
        primaryTypographyProps={
          type === "subElement" && textColor
            ? {
                classes: {
                  root: {
                    color: "rgba(68, 84, 106, 0.95)",
                  },
                },
              }
            : {}
        }
        primary={primary}
        secondary={secondary}
      />
      {type === "avatar" && <Divider variant="middle" />}
    </React.Fragment>
  );
};

StyledIconElement.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.oneOf(["subElement", "avatar"]),
    handler: PropTypes.func,
    route: PropTypes.string,
    icon: PropTypes.node,
    primary: PropTypes.string,
    secondary: PropTypes.string,
  }),
};

export default StyledIconElement;
