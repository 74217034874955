/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Formik
import { FastField } from "formik";

// Material UI
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

const StyledChip = ({ label, icon, type, ...props }) => {
  return (
    <FastField name={props.name}>
      {({ field, form, meta }) => {
        const { onBlur: blur, ...remainingFields } = field;
        const clicked = meta.value.includes(label);

        /**
         * This handler function updates the values in
         * the field array and sets the local clicked
         * state when the chip is clicked.
         */
        const handleClick = () => {
          if (field.name !== "personality") {
            // Add to Array
            if (!meta.value.includes(label))
              return form.setFieldValue(field.name, [...meta.value, label]);
            return form.setFieldValue(
              field.name,
              meta.value.filter((v) => v !== label)
            );
          }
          return form.setFieldValue(field.name, label);
        };

        return (
          <Chip
            {...remainingFields}
            size="medium"
            sx={{
              my: 0.5,
              mx: 0.25,
              "&.MuiChip-root": {
                backgroundColor: clicked ? getColor(0.15, type) : "transparent",
                borderSize: "1px",
                borderStyle: "solid",
                borderColor: clicked ? getColor(0.01, type) : "grey.50",
                color: getColor(1, type),
                "&:hover": {
                  borderColor: clicked ? getColor(0.01, type) : "grey.50",
                  backgroundColor: getColor(0.15, type),
                },
                "&:focus": {
                  borderColor: clicked ? getColor(0.01, type) : "grey.50",
                  backgroundColor: clicked
                    ? getColor(0.15, type)
                    : "transparent",
                },
              },
            }}
            onClick={handleClick}
            avatar={
              <Avatar
                sx={{
                  "&.MuiAvatar-colorDefault": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                {icon}
              </Avatar>
            }
            variant="outlined"
            label={
              <Typography
                color="textPrimary"
                variant="body2"
                sx={{ fontSize: 13, py: 0.5 }}
              >
                {label}
              </Typography>
            }
            clickable
          />
        );
      }}
    </FastField>
  );
};

StyledChip.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    "lifestyles",
    "activities",
    "identities",
    "personality",
  ]).isRequired,
};

export default StyledChip;

/**
 * This function toggles between the appropriate
 * colors for the characteristics chips
 *
 * @param {number} opacity the level of opacity
 * @returns {string} with the correct rgb color and defined opacity
 */
const getColor = (opacity, type) => {
  switch (type) {
    case "identities":
      return `rgba(48, 79, 254, ${opacity})`;
    case "personality":
      return `rgba(0, 176, 255, ${opacity})`;
    case "lifestyles":
      return `rgba(245, 0, 87, ${opacity})`;
    case "activities":
      return `rgba(0, 200, 83, ${opacity})`;
    default:
      return `rgba(239, 241, 244, 1)`;
  }
};
