/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { logoutUser } from "../../../../redux/actions/auth/atomic/post/logout";

// Material UI
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import StyledSnackbar from "../../../UI/Snackbar/styledSnackbar";

// Checks if user is authenticated
const getAuthStatus = () => localStorage.getItem("auth");
const sessionWasLoggedOut = () => sessionStorage.getItem("--slo");

const AuthRoute = ({
  logoutUser,
  isLoggedOut,
  component: Component,
  isAuthenticated = Boolean(getAuthStatus()),
  wasSLO = Boolean(sessionWasLoggedOut()),
}) => {
  const qParams = useLocation().search;
  const referral = new URLSearchParams(qParams).get("invite");
  const [msgOpen, setMsgOpen] = useState(wasSLO);

  useEffect(() => {
    if (referral) localStorage.setItem("referral_entity", referral);
    if (wasSLO) sessionStorage.removeItem("--slo");
  }, [referral]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const isLargeTablet = useMediaQuery(theme.breakpoints.only("md"));
  const isComputer = useMediaQuery(theme.breakpoints.only("lg"));

  useEffect(() => {
    if (!isLoggedOut && !isAuthenticated) logoutUser(false);
  }, []);

  /**
   * This handler function closes the message in
   * the snackbar notification.
   *
   * @param {object} e - the event object
   * @param {string} reason event reason
   * @returns {null}
   */
  const handleMsgClose = (e, reason) => {
    if (reason === "clickaway") return;
    setMsgOpen(false);
  };

  return !isAuthenticated ? (
    <React.Fragment>
      <Component
        isMobile={isMobile}
        isTablet={isTablet}
        isComputer={isComputer}
        isLargeTablet={isLargeTablet}
      />
      {msgOpen && (
        <StyledSnackbar
          isMobile={isMobile}
          open={msgOpen}
          duration={5000}
          handleClose={handleMsgClose}
          color="text"
          colorType="primary"
          text={
            <span>
              Oops, something went wrong. For your security, Loop logged you out. Log
              back in. Your community is waiting 👋
            </span>
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        />
      )}
    </React.Fragment>
  ) : (
    <Navigate to="/community" />
  );
};

const mapStateToProps = (state) => ({
  isLoggedOut: state.session.isLoggedOut,
});

const mapActionsToProps = {
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(React.memo(AuthRoute));
