/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { memo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import { getBaseRoute } from "../../../../functions/getBaseRoute";
import { ReactComponent as UnselectedFeed } from "../../../../images/feed_white.svg";
import { ReactComponent as SelectedFeed } from "../../../../images/feed.svg";
import { ReactComponent as UnselectedDisco } from "../../../../images/disco_white.svg";
import { ReactComponent as SelectedDisco } from "../../../../images/disco.svg";
import { ReactComponent as UnselectedProfile } from "../../../../images/profile_white.svg";
import { ReactComponent as SelectedProfile } from "../../../../images/profile.svg";

//Material UI
import Grid from "@mui/material/Grid";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

// Component
const MobileAppNav = ({ value, handleNavChange }) => {
  const location = useLocation();
  const baseRoute = getBaseRoute(location.pathname);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "fixed",
        bottom: 10,
        mt: 2,
      }}
    >
      <Grid item xs={12}>
        <BottomNavigation
          value={value}
          onChange={(e, newValue) => handleNavChange(e, newValue)}
          showLabels
          sx={{
            borderRadius: 1.25,
            mx: 1,
            height: 60,
            boxShadow: (theme) => theme.shadows[2],
          }}
        >
          <BottomNavigationAction
            label="Profile"
            icon={
              location.pathname === "/profile" ? (
                <SelectedProfile height={30} width={30} />
              ) : (
                <UnselectedProfile height={30} width={30} />
              )
            }
            disableTouchRipple
            component={NavLink}
            to="/profile"
            sx={{
              mt: 0.25,
              "& .Mui-selected": {
                color: (theme) => theme.palette.text.secondary,
                fontSize: 12,
              },
            }}
          />
          <BottomNavigationAction
            label="Feed"
            icon={
              baseRoute === "/community" ? (
                <SelectedFeed height={30} width={30} />
              ) : (
                <UnselectedFeed height={30} width={30} />
              )
            }
            component={NavLink}
            disableTouchRipple
            to="/community"
            sx={{
              mt: 0.25,
              "& .Mui-selected": {
                color: (theme) => theme.palette.text.secondary,
                fontSize: 12,
              },
            }}
          />
          <BottomNavigationAction
            label="Discover"
            icon={
              baseRoute === "/discover" ? (
                <SelectedDisco height={30} width={30} />
              ) : (
                <UnselectedDisco height={30} width={30} />
              )
            }
            disableTouchRipple
            component={NavLink}
            to="/discover"
            sx={{
              mt: 0.25,
              "& .Mui-selected": {
                color: (theme) => theme.palette.text.secondary,
                fontSize: 12,
              },
            }}
          />
        </BottomNavigation>
      </Grid>
    </Grid>
  );
};

MobileAppNav.propTypes = {
  value: PropTypes.number,
  handleNavChange: PropTypes.func,
};

export default memo(MobileAppNav);
