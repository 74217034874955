/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Reviews
export const LOADING_REVIEWS = "LOADING_REVIEWS";
export const SET_REVIEWS_ERRORS = "SET_REVIEWS_ERRORS";
export const CLEAR_REVIEWS_ERRORS = "CLEAR_REVIEWS_ERRORS";
export const LOADING_NOT_REVIEWED = "LOADING_NOT_REVIEWED";
export const SET_NOT_REVIEWED = "SET_NOT_REVIEWED";
export const SET_NOT_REVIEWED_ERRORS = "SET_NOT_REVIEWED_ERRORS";
export const CLEAR_NOT_REVIEWED_ERRORS = "CLEAR_NOT_REVIEWED_ERRORS";
export const LOADING_IN_REVIEW = "LOADING_IN_REVIEW";
export const SET_IN_REVIEW = "SET_IN_REVIEW";
export const SET_IN_REVIEW_ERRORS = "SET_IN_REVIEW_ERRORS";
export const CLEAR_IN_REVIEW_ERRORS = "CLEAR_IN_REVIEW_ERRORS";
export const LOADING_PAST_DECISIONS = "LOADING_PAST_DECISIONS";
export const SET_PAST_DECISIONS = "SET_PAST_DECISIONS";
export const SET_PAST_DECISIONS_ERRORS = "SET_PAST_DECISIONS_ERRORS";
export const CLEAR_PAST_DECISIONS_ERRORS = "CLEAR_PAST_DECISIONS_ERRORS";
export const ASSIGN_LEADER_TO_REVIEW = "ASSIGN_LEADER_TO_REVIEW";

// Leader Decision
export const LOADING_LEADER_DECISION = "LOADING_LEADER_DECISION";
export const SET_LEADER_DECISION = "SET_LEADER_DECISION";
export const SET_LEADER_DECISION_ERRORS = "SET_LEADER_DECISION_ERRORS";
export const CLEAR_LEADER_DECISION_ERRORS = "CLEAR_LEADER_DECISION_ERRORS";
