/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { reportABug } from "../../../../redux/actions/user/atomic/post/reportABug";

// Form Components
import BaseForm from "../../Forms/baseForm";
import reportBugSchema from "./validationSchema/reportBugSchema";

// Components
import BaseDialog from "../baseDialog";
import { StyledSuccess } from "../../Text/styledText";
import StyledDialogHeader from "../styledComponents/styledDialogHeader";
import StyledDialogContent from "../styledComponents/styledDialogContent";
import StyledDialogFooter from "../styledComponents/styledDialogFooter";
import StyledInputField from "../../Forms/styledComponents/styledMultilineInputField";
import StyledMultilineField from "../../Forms/styledComponents/styledMultilineInputField";
import StyledRadioGroup from "../../Forms/styledComponents/styledRadioGroup";

// Material UI
import Grid from "@mui/material/Grid";

// Component
const StyledReportBugDialog = ({
  open,
  isMobile,
  isTablet,
  reportABug,
  handleClick,
}) => {
  let bugRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * This helper function dispatches the
   * form to submit outside of the formik
   * baseForm component.
   */
  const onSubmit = () => {
    bugRef.current.submitForm();
  };

  /**
   * This handler function toggles the report bug
   * dialog closed
   */
  const handleClose = (e) => {
    return handleClick(e, "reportBug");
  };

  /**
   * This submit handler function is used
   * to update the core details.
   *
   * @param {object} data
   */
  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    await reportABug(data);
    setIsSubmitting(false);
    return handleClose();
  };

  return (
    <BaseDialog
      open={open}
      isMobile={isMobile}
      isTablet={isTablet}
      onClose={handleClick}
      aria-labelledby="Loop Network - Report Bug Dialog"
    >
      <StyledDialogHeader
        title={DIALOG_CONTENT.title}
        handleClose={handleClick}
      />
      <StyledDialogContent content={DIALOG_CONTENT.content}>
        <BaseForm
          data={DIALOG_CONTENT.initialFormState}
          handleSubmit={handleSubmit}
          validationSchema={reportBugSchema}
          formRef={bugRef}
          noMargin
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <StyledInputField
              name="url"
              color="success"
              placeholder="What was the url where the bug happened?"
              required
            />
            <StyledMultilineField
              name="description"
              placeholder="Tell us about what happened when the error occured."
              color="success"
              rows={6}
              rowsMax={10}
              required
            />
            <StyledRadioGroup
              name="severity"
              title="How does the bug leave you feeling?"
              formLabels={DIALOG_CONTENT.labels}
              isMobile={isMobile}
              required
            />
          </Grid>
        </BaseForm>
      </StyledDialogContent>
      <StyledDialogFooter
        text="Report Bug"
        footerText={DIALOG_CONTENT.footerText}
        isSubmitting={isSubmitting}
        handleSubmit={onSubmit}
        color="success"
        colorType="main"
      />
    </BaseDialog>
  );
};

StyledReportBugDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
};

const mapActionsToProps = {
  reportABug,
};

export default connect(null, mapActionsToProps)(StyledReportBugDialog);

/**
 * This object holds all of the content used
 * within the report bug dialog.
 */
const DIALOG_CONTENT = {
  title: (
    <span>
      Report a <StyledSuccess bold>Bug/Issue</StyledSuccess>
    </span>
  ),
  initialFormState: {
    url: "",
    description: "",
    severity: "Mehh",
  },
  content: (
    <span>
      Find a bug/issue with Loop? Tell us about the issue you are experiencing.
    </span>
  ),
  footerText: <span>A big Texas thank you 🙏 for reporting this issue</span>,
  labels: [
    {
      value: "Mehh",
      label: "😐 Mehh",
      colorType: "main",
      color: "success",
    },
    {
      value: "Sad",
      label: "😢 Sad",
      color: "warning",
      colorType: "main",
    },
    {
      value: "Mad",
      label: "😡 Mad",
      color: "error",
      colorType: "main",
    },
  ],
};
