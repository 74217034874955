/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import Routes from "../../Pages/Routes/Routes";
import { useScrollToTopOnMount } from "../../../hooks/useScrollToTopOnMount";

// Material UI
import { styled } from "@mui/system";

const LayoutMain = ({ isAppRoute, isCentered, disableTopMargin }) => {
  const StyledSection = styled("section")(({ theme }) => ({
    marginBottom: 0,
    marginTop: isCentered
      ? "auto"
      : isAppRoute
      ? theme.spacing(8)
      : theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: isAppRoute ? theme.spacing(10) : 0,
      marginTop:
        isAppRoute || disableTopMargin ? theme.spacing(8) : theme.spacing(3),
    },
  }));

  useScrollToTopOnMount();

  return (
    <StyledSection id="_loop_app_">
      <Routes />
    </StyledSection>
  );
};

LayoutMain.propTypes = {
  isAppRoute: PropTypes.bool.isRequired,
  isCentered: PropTypes.bool.isRequired,
};

export default React.memo(LayoutMain);
