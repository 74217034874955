/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState } from "react";

// Components
import StyledTooltip from "./styledTooltip";

// Material UI
import { Box } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const StyledClickableTooltip = ({
  title,
  children,
  buttonSize = 'small',
  divSxProps = {},
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box sx={{ ...divSxProps }}>
        <StyledTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={title}
          fontSize="large"
          {...props}
        >
          <IconButton size={buttonSize} onClick={handleTooltipOpen}>
            {children}
          </IconButton>
        </StyledTooltip>
      </Box>
    </ClickAwayListener>
  );
};

export default StyledClickableTooltip;
