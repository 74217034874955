/**
 * Copyright 2022 Loop Technology, Inc.
 */

// General Notifications Action Types
export const CLEAR_GENERAL_NOTIFICATIONS = "CLEAR_GENERAL_NOTIFICATIONS";
export const LOADING_GENERAL_NOTIFICATIONS = "LOADING_GENERAL_NOTIFICATIONS";
export const SET_GENERAL_NOTIFICATIONS = "SET_GENERAL_NOTIFICATIONS";
export const SET_NEW_GENERAL_NOTIFICATIONS = "SET_NEW_GENERAL_NOTIFICATIONS";
export const CLEAR_GENERAL_NOTIFICATIONS_ERRORS =
  "CLEAR_GENERAL_NOTIFICATIONS_ERRORS";
export const SET_GENERAL_NOTIFICATIONS_ERRORS =
  "SET_GENERAL_NOTIFICATIONS_ERRORS";
export const REMOVE_GENERAL_NOTIFICATION = "REMOVE_GENERAL_NOTIFICATION";
export const MARK_GENERAL_NOTIFICATION_CLICKED =
  "MARK_GENERAL_NOTIFICATION_CLICKED";
export const CLEAR_GENERAL_NOTIFICATION_COUNT =
  "CLEAR_GENERAL_NOTIFICATION_COUNT";
export const SET_GENERAL_NOTIFICATION_COUNT = "SET_GENERAL_NOTIFICATION_COUNT";

// Friend Request Action Types
export const LOADING_FRIEND_REQUESTS = "LOADING_FRIEND_REQUESTS";
export const SET_FRIEND_REQUESTS = "SET_FRIEND_REQUESTS";
export const SET_NEW_FRIEND_REQUESTS = "SET_NEW_FRIEND_REQUESTS";
export const CLEAR_FRIEND_REQUESTS_ERRORS = "CLEAR_FRIEND_REQUESTS_ERRORS";
export const SET_FRIEND_REQUESTS_ERRORS = "SET_FRIEND_REQUESTS_ERRORS";
export const REMOVE_FRIEND_REQUEST = "REMOVE_FRIEND_REQUEST";
export const MARK_FRIEND_REQUEST_CLICKED = "MARK_FRIEND_REQUEST_CLICKED";
export const CLEAR_FRIEND_REQUESTS_COUNT = "CLEAR_FRIEND_REQUESTS_COUNT";
export const CLEAR_FRIEND_REQUEST = "CLEAR_FRIEND_REQUEST";
export const SET_FRIEND_REQUESTS_COUNT = "SET_FRIEND_REQUESTS_COUNT";
