/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BaseList from "../baseList";
import BaseUserSearchElement from "../styledComponents/baseUserSearchElement";
import { ReactComponent as Search } from "../../../../images/search.svg";

// Material UI
import { Box } from "@mui/system";
import Typography from "@mui/material/Typography";

const BaseRecentSearchesList = ({
  recents = {},
  recentHandles = [],
  listProps = {},
  isDialog = false,
  isSearch = false,
  handleSearchClick,
}) => {
  return (
    <React.Fragment>
      <BaseList
        sx={{
          borderRadius: 1,
          ...listProps,
        }}
      >
        {recentHandles.length > 0 ? (
          <React.Fragment>
            <Typography
              variant={isSearch ? "body1" : "body2"}
              color="textSecondary"
              sx={{ mb: 0.5 }}
            >
              Recently {isSearch ? "Searched" : "Tagged"}
            </Typography>
            {recentHandles.map((handle) => (
              <React.Fragment key={`recent-user-search-${handle}`}>
                <BaseUserSearchElement
                  user={recents[handle]}
                  recents={recents}
                  recentHandles={recentHandles}
                  handleSearchClick={handleSearchClick}
                  isSearch={isSearch}
                  isRecent={true}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        ) : isDialog ? (
          <Box textAlign="center" sx={{ mt: 4 }}>
            <Search height={150} width={200} />
          </Box>
        ) : (
          <Typography
            variant={isSearch ? "body1" : "body2"}
            color="textSecondary"
            sx={{ mb: 0.5 }}
            align="center"
          >
            Keep typing to tag a user...
          </Typography>
        )}
      </BaseList>
    </React.Fragment>
  );
};

BaseRecentSearchesList.propTypes = {
  isDialog: PropTypes.bool,
  listProps: PropTypes.object,
  isSearch: PropTypes.bool,
  recents: PropTypes.object.isRequired,
  recentHandles: PropTypes.array.isRequired,
  handleSearchClick: PropTypes.func.isRequired,
};

export default BaseRecentSearchesList;
