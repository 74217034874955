/**
 * Copyright 2022 Loop Technology, Inc.
 */

import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

const ruleActions = (
  handleSuggestChange,
  handleSuggestRemove,
  handleViewDetails
) => [
  {
    icon: <ArrowForwardRoundedIcon />,
    type: "subElement",
    primary: "View Rule Details",
    handler: handleViewDetails,
  },
  {
    icon: <EditRoundedIcon />,
    type: "subElement",
    primary: "Suggest Rule Change",
    handler: handleSuggestChange,
  },
  {
    icon: <DeleteOutlineRoundedIcon />,
    type: "subElement",
    primary: "Propose Rule Removal",
    handler: handleSuggestRemove,
  },
];

export default ruleActions;
