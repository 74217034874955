/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { changeProfileImage } from "../../../../../redux/actions/user/atomic/post/changeProfileImage";

// Components
import StyledUserAvatar from "../../../Avatars/styledAvatars/styledUserAvatar";
import BaseImageUploadButton from "../../../Buttons/UploadButtons/baseImageUploadButton";
import { imageResizeHelper } from "../../../../../functions/imageResizeHelper";

// Material UI
import Grid from "@mui/material/Grid";

// Component
const StyledEditProfileImage = ({ isMobile, changeProfileImage }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * This handler function calls the "changeUserImage"
   * action function which submits the new profile
   * image.
   *
   * @param {object} e - event object
   * @param {object} e.target - event target object
   * @param {string[]} e.target.files - the new background image
   */
  const handleNewProfileImageSubmit = (e) => {
    let form = new FormData();
    imageResizeHelper(e.target.files[0], (file) => {
      setIsSubmitting(true);
      form.append("image", file);
      changeProfileImage(form).then(() => {
        form.delete("image");
        return setIsSubmitting(false);
      });
    });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        <StyledUserAvatar
          height={isMobile ? 12 : 15}
          width={isMobile ? 12 : 15}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }}>
        <BaseImageUploadButton
          color="primary"
          text="Add your picture"
          ariaLabel="Upload Profile Photo"
          id="update-profile-picture"
          handleImageChange={handleNewProfileImageSubmit}
          isSubmitting={isSubmitting}
        />
      </Grid>
    </Grid>
  );
};

StyledEditProfileImage.propTypes = {
  isMobile: PropTypes.bool,
  changeUserImage: PropTypes.func,
};

const mapActionsToProps = {
  changeProfileImage,
};

export default connect(null, mapActionsToProps)(StyledEditProfileImage);
