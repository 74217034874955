/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useRef } from "react";
import PropTypes from "prop-types";

// Form Components
import { Formik, Form } from "formik";

// Material UI
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const BaseForm = ({
  data,
  handleSubmit,
  formRef,
  noMargin = true,
  validationSchema = false,
  children,
  spacing = 0,
}) => {
  const otherRef = useRef();
  let formValues = formRef ? formRef : otherRef;
  const StyledForm = styled(Form)(({ theme }) => ({
    margin: theme.spacing(noMargin ? 0 : 1),
    width: "100%",
  }));
  return (
    <Formik
      initialValues={data}
      innerRef={formValues}
      validationSchema={validationSchema}
      onSubmit={async (values, helpers) => {
        await handleSubmit(values, helpers);
      }}
    >
      {({ values, errors }) => (
        <StyledForm>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={spacing}
          >
            {children}
            {errors && errors.general && (
              <Typography
                variant="body1"
                sx={{ color: "error.main", my: 0.5 }}
                align="center"
              >
                {errors.general}
              </Typography>
            )}
          </Grid>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </StyledForm>
      )}
    </Formik>
  );
};

BaseForm.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleGetCurrentState: PropTypes.func,
  validationSchema: PropTypes.object,
  formRef: PropTypes.object,
  spacing: PropTypes.number,
};

export default BaseForm;
