/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Formik
import { FastField } from "formik";

// Material UI
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Button from "@mui/material/Button";

const StyledAppSearchField = ({
  handleClose,
  placeholder = "",
  fullWidth = true,
  color = "secondary",
  colorType = "main",
  ...props
}) => {
  return (
    <React.Fragment>
      <FastField name={props.name}>
        {({ field, meta }) => {
          const errorText = meta.error && meta.touched ? meta.error : null;
          return (
            <TextField
              {...field}
              {...props}
              sx={{
                mt: 1,
                pt: 1,
                mb: 0,
                width: fullWidth ? "100%" : "auto",
                "& .MuiInput-root": {
                  fontSize: 20,
                  pb: 1,
                  borderBottomColor: (theme) => theme.palette.grey[400],
                  "&:before": {
                    borderBottomColor: (theme) => theme.palette.grey[400],
                  },
                  "&:hover:not(.Mui-disabled):before": {
                    borderBottomColor: (theme) => theme.palette.grey[400],
                  },
                  "&:after": {
                    borderBottomColor: (theme) =>
                      theme.palette[color][colorType],
                  },
                },
              }}
              inputProps={{ "aria-label": "platform search" }}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ pl: 1 }}>
                    <SearchRoundedIcon sx={{ color: "grey.400" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start" sx={{ pr: 1 }}>
                    <Button
                      variant="outlined"
                      type="reset"
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        color: "secondary.dark",
                        borderColor: "secondary.main",
                        p: 0.5,
                        minWidth: 0,
                        "&:hover": {
                          color: "secondary.dark",
                          borderColor: "secondary.main",
                        },
                      }}
                    >
                      esc
                    </Button>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  fontSize: 16,
                  "&.Mui-focused": {
                    color: (theme) =>
                      `${theme.palette[color][colorType]} !important`,
                  },
                },
              }}
              type="input"
              margin="normal"
              label={null}
              variant="standard"
              aria-label="Loop search input"
              placeholder={placeholder}
              helperText={errorText}
              error={errorText ? true : false}
              fullWidth
              autoFocus
            />
          );
        }}
      </FastField>
      <label htmlFor={props.name} />
    </React.Fragment>
  );
};

StyledAppSearchField.propTypes = {
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "success",
    "text",
    "warning",
    "error",
  ]),
  colorType: PropTypes.oneOf(["main", "light", "dark", "secondary", "primary"]),
};

export default React.memo(StyledAppSearchField);
