/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Components
import MobileAppNav from "../Nav/Footer/mobileAppNav";
import LoggedOutFooter from "../Nav/Footer/loggedOutFooter";
import loggedInRoutes from "../../Pages/Routes/loggedInRoutes";

// Material UI
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
// import StyledAppMessage from "../Nav/Footer/styledAppMessage";

const getCookieStatus = () => localStorage.getItem("c_a__");
const getAppMsgStatus = () => localStorage.getItem("m-");

// const StyledText = styled("span")(() => ({
//   fontSize: "14px",
// }));

const LayoutFooter = ({
  location,
  isAppRoute,
  isNewUser,
  acceptedCookies = getCookieStatus() === "true",
  appStatus = getAppMsgStatus() === "true",
}) => {
  const theme = useTheme();
  let isTabletDown = useMediaQuery(theme.breakpoints.down("md"));
  const [value, setValue] = useState(1);
  const [acceptCookiesOpen, setAcceptCookiesOpen] = useState(!acceptedCookies);
  // const [appMsgOpen, setAppMsgOpen] = useState(!appStatus);

  if (!acceptedCookies) localStorage.setItem("c_a__", "false");

  /**
   * This useEffect sets the value of the mobile nav
   * to the correct value when the nav is not
   * clicked, but there is a new route.
   */
  useEffect(() => {
    if (loggedInRoutes.includes(location.pathname)) {
      setValue(switchValueHelper(location.pathname));
    }
  }, []);

  /**
   * This handler function sets the value
   * in the mobile nav.
   *
   * @param {object} e - the event object
   * @param {string} newValue - the new page value
   */
  const handleNavChange = (e, newValue) => {
    setValue(newValue);
  };

  const handleCookieApproval = () => {
    setAcceptCookiesOpen(false);
    return localStorage.setItem("c_a__", "true");
  };

  // const handleMsgApproval = () => {
  //   setAppMsgOpen(false);
  //   return localStorage.setItem("m-", "true");
  // };

  return (
    <React.Fragment>
      {isAppRoute ? (
        <React.Fragment>
          {/* {appMsgOpen && !isNewUser && (
            <StyledAppMessage
              open={appMsgOpen}
              color="secondary"
              buttonText="Close"
              handleClick={handleMsgApproval}
              isTabletDown={isTabletDown}
              text={
                <StyledText>
                  Friendly Reminder(s): If something doesn't work/look right,
                  simply use the "report a bug" button in the app menu.
                  <br />
                  <br />
                  Say hello 👋 to your community's ambassador, Nic! Ambassadors
                  are Loop team members who help the conversations flow, until
                  your community begins to grow.
                  <br />
                  <br />
                  Take it easy ✌️
                  <br />
                  Jeremy
                </StyledText>
              }
            />
          )} */}
          {!isTabletDown ? (
            <footer />
          ) : (
            <MobileAppNav handleNavChange={handleNavChange} value={value} />
          )}
        </React.Fragment>
      ) : (
        <LoggedOutFooter
          location={location}
          handleCookieApproval={handleCookieApproval}
          isTabletDown={isTabletDown}
          cookieMsgOpen={acceptCookiesOpen}
        />
      )}
    </React.Fragment>
  );
};

LayoutFooter.propTypes = {
  isAppRoute: PropTypes.bool.isRequired,
  isNewUser: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isNewUser: state.user.core.isNewUser,
});

export default connect(mapStateToProps, null)(LayoutFooter);

/**
 * This helper function switch the mobile footer
 * based on the location pathname
 *
 * @param {string} pathname - the current pathname
 * @returns {number} - index of active mobile nav
 */
const switchValueHelper = (pathname) => {
  switch (pathname) {
    case "/profile":
      return 0;
    case "/community":
      return 1;
    case "/discover":
      return 2;
    default:
      return 4;
  }
};
