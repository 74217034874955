/**
 * Copyright 2022 Loop Technology, Inc.
 */

import Compressor from "compressorjs";
import { parseImageFilename } from "./parseImageFilename";

let DEFAULT_OPTIONS = {
  maxWidth: 1920,
  maxHeight: 1920,
  mimetype: "image/jpeg",
};

// const BASE_IPFS_URL = "https://ipfs.looop.cool/";

/**
 * This helper function takes an unformatted Blob passed
 * into the image input. It returns the compressed and/or
 * resized the Blob object.
 *
 * @param {Blob} file - the unformatted file blob
 * @param {func} handleFile - the file handler when file is processed
 * @param {object} [options] - the additional compression options
 * @returns {object} the formatted image blob and file
 */
export const imageResizeHelper = (
  file,
  handleFile,
  imageName = null,
  options = DEFAULT_OPTIONS
) => {
  let COMPRESSION_OPTIONS = getCompressionOptionsHelper(options);
  return compressImageHelper(file, handleFile, COMPRESSION_OPTIONS, imageName);
};

/**
 * This helper function returns a promise with the
 * formatted Blob.
 *
 * @param {Blob} file - the unformatted file Blob
 * @param {object} options - the compression options object
 * @returns {Blob} the compressed Blob object
 */
const compressImageHelper = (file, handleFile, options, imageName) => {
  return new Compressor(file, {
    ...options,
    success: async (res) => {
      await handleFile(
        new File([res], parseImageFilename(imageName ? imageName : res.name), {
          type: res.type,
        })
      );
    },
  });
};

/**
 * This helper function returns an object with the correct
 * compression options.
 *
 * @param {object} [options] - additional compression options
 * @returns {object} the updated compression options
 */
const getCompressionOptionsHelper = (options = {}) => ({
  ...DEFAULT_OPTIONS,
  ...options,
});
