/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import { NavLink } from "react-router-dom";

// Components
import StyledBaseButton from "../UI/Buttons/styledBaseButton";
import ErrorImage from "../../images/404_page.svg";

// Material UI
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

const StyledText = styled("span")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 400,
}));

const StyledImg = styled("img")(({ theme }) => ({
  height: 175,
  [theme.breakpoints.only("xs")]: {
    height: 150,
  },
  marginBottom: theme.spacing(4),
}));

const NotFound = ({ isMobile }) => {
  return (
    <Container maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <StyledImg src={ErrorImage} alt="404 Loop Page Error" />
        <Grid item xs={12}>
          <Typography
            variant={isMobile ? "h6" : "h4"}
            sx={{ fontWeight: 300 }}
            color="textPrimary"
            align="center"
          >
            This page <StyledText>Does Not Exist</StyledText>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant={isMobile ? "body1" : "h6"}
            color="textSecondary"
            align="center"
            sx={{ my: isMobile ? 0.5 : 1.5, fontWeight: 400 }}
          >
            No biggie ✌️ Click below to find your way back to Loop
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: isMobile ? 1.5 : 3 }}>
          <StyledBaseButton
            text="Back to Loop"
            color="primary"
            fullWidth={false}
            component={NavLink}
            to="/community"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
