/**
 * * Copyright 2022 Loop Technology, Inc.
 */

import {
  SET_PLATFORM_BUG,
  SET_PLATFORM_BUG_ERRORS,
  CLEAR_PLATFORM_BUG_ERRORS,
  LOADING_PLATFORM_BUG,
} from "../../../../actionTypes/sessionTypes";

// Axios Instance
import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function lets the authenticated user submit
 * an issue/platform bug.
 *
 * @param {object} platformBug - The platformBug from the user
 * @param {string} platformBug.url - The platformBug url
 * @param {string} platformBug.description - The platformBug description
 * @param {string} platformBug.severity - The platformBug severity
 * @param {object} dispatch - The redux dispatch object
 */
export const reportABug = (platfomBug) => async (dispatch) => {
  dispatch({ type: CLEAR_PLATFORM_BUG_ERRORS });
  dispatch({ type: LOADING_PLATFORM_BUG });

  return await baseAxios
    .post("/report-bug", platfomBug)
    .then(() => {
      dispatch({ type: CLEAR_PLATFORM_BUG_ERRORS });
      dispatch({ type: SET_PLATFORM_BUG });
    })
    .catch((error) => {
      dispatch({
        type: SET_PLATFORM_BUG_ERRORS,
        payload: error ? (error.response ? error.response.data : error) : null,
      });
    });
};
