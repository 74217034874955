/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  UPLOADING_NEW_RULE,
  CLEAR_NEW_RULE_ERRORS,
  SET_NEW_RULE_ERRORS,
} from "../../../../actionTypes/ruleTypes";

import { SET_NEW_POST } from "../../../../actionTypes/feedTypes";

// Axios
import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function creates a new post to remove
 * a rule from the entity rules.
 *
 * @param {string} ruleId - The original rule which is being removed
 * @param {string} entityType - The type of entity (community, coalition)
 * @param {object} dispatch - The redux dispatch object
 */
export const createRuleRemoval =
  (ruleId, entityType = "community") =>
  async (dispatch) => {
    dispatch({ type: CLEAR_NEW_RULE_ERRORS });
    dispatch({ type: UPLOADING_NEW_RULE });

    return await baseAxios
      .post(`/${entityType}/rule/${ruleId}/remove`)
      .then((res) => {
        let data = res.data.data;
        dispatch({ type: CLEAR_NEW_RULE_ERRORS });
        dispatch({ type: SET_NEW_POST, payload: data });
      })
      .catch((error) => {
        dispatch({
          type: SET_NEW_RULE_ERRORS,
          payload: error
            ? error.response
              ? error.response.data
              : error
            : null,
        });
      });
  };
