/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Component
import { UpTransition } from "../Transitions/slides";
import { FadeTransition } from "../Transitions/fade";

// Material UI
import { BACKDROP_SX } from "../../../styles/general";
import Dialog from "@mui/material/Dialog";

const BaseDialog = ({
  open,
  onClose,
  isMobile,
  isTablet,
  ariaLabelledBy,
  PaperProps = {},
  TransitionProps = { timeout: 275 },
  BackdropProps = {
    timeout: 0,
    sx: BACKDROP_SX,
  },
  PaperSxProps = {},
  fullWidth = true,
  hasFullScreen = true,
  children,
  ...props
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={isMobile || isTablet}
      fullScreen={(isMobile || isTablet) && hasFullScreen}
      aria-labelledby={ariaLabelledBy}
      PaperProps={{
        ...PaperProps,
        sx: {
          border: (theme) =>
            theme.palette.mode === "dark" ? "2px solid rgb(19, 47, 76)" : "0px",
          ...PaperSxProps,
        },
      }}
      BackdropProps={{ ...BackdropProps }}
      TransitionProps={{ ...TransitionProps }}
      TransitionComponent={isMobile || isTablet ? UpTransition : FadeTransition}
      {...props}
    >
      {children}
    </Dialog>
  );
};

BaseDialog.propTypes = {
  isTablet: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  ariaLabelledBy: PropTypes.string,
  PaperProps: PropTypes.object,
  PaperSxProps: PropTypes.object,
  BackdropProps: PropTypes.object,
  TransitionProps: PropTypes.object,
  fullWidth: PropTypes.bool,
};

export default BaseDialog;
