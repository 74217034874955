/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  LOADING_USER_COMMUNITY,
  SET_USER_COMMUNITY,
  SET_USER_COMMUNITY_ERRORS,
  CLEAR_USER_COMMUNITY_ERRORS,
} from "../../../../actionTypes/userTypes";

// Axios
import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function gets the authenticated user's core community 
 * information. The action requests the communities information from
 * the (/core/:type/:handle?) route which is built on top of the 
 * "getEntityCore" handler. If the route does not recieve (req.param.handler) 
 * the authenticated user's community is used. Given this, this action 
 * does not pass in any params.

 * @param {object} dispatch - The redux dispatch object 
 */
export const getUserCommunity = () => async (dispatch) => {
  dispatch({ type: CLEAR_USER_COMMUNITY_ERRORS });
  dispatch({ type: LOADING_USER_COMMUNITY });

  return await baseAxios
    .get(`/entity/community/core/`)
    .then((res) => {
      return dispatch({
        type: SET_USER_COMMUNITY,
        payload: res.data,
      });
    })
    .catch((error) => {
      return dispatch({
        type: SET_USER_COMMUNITY_ERRORS,
        payload: error ? (error.response ? error.response.date : error) : null,
      });
    });
};
