/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import { FadeTransition } from "../Transitions/fade";

// Material UI
import Menu from "@mui/material/Menu";

const BaseMenu = ({
  name,
  anchorEl,
  handleMenuClose,
  children,
  gutters = true,
  sxProps = {},
  ...props
}) => {
  return (
    <Menu
      id={`${name}-menu-desktop`}
      TransitionComponent={FadeTransition}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      open={Boolean(anchorEl)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      {...props}
      sx={{
        "& .MuiMenu-list": { p: gutters ? 1 : 0, ...sxProps },
      }}
      PaperProps={{
        sx: {
          border: (theme) =>
            theme.palette.mode === "dark" ? "2px solid rgb(19, 47, 76)" : "0px",
        },
      }}
    >
      {children}
    </Menu>
  );
};

BaseMenu.propTypes = {
  name: PropTypes.string,
  anchorEl: PropTypes.object,
  handleMenuClose: PropTypes.func,
  sxProps: PropTypes.object,
  children: PropTypes.node,
};

export default React.memo(BaseMenu);
