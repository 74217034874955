/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Material UI
import { alpha } from "@mui/system";

export const BACKDROP_SX = {
  backdropFilter: "blur(4px)",
  backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.3),
};
