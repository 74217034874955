/**
 * Copyright 2022 Loop Technology, Inc.
 */

import * as yup from "yup";

const reportBugSchema = yup.object().shape({
  url: yup
    .string()
    .url("Must be a valid URL (i.e. https://looop.cool/....)")
    .required("Must include a URL"),
  description: yup.string().required("Must add a description of bug/issue"),
  severity: yup
    .string()
    .required("We need to know how to prioritize this issue"),
});

export default reportBugSchema;
