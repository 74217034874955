/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Product Analytics
import { Mixpanel } from "../../../Analytics/mixpanel";

// Components
import { getMessage, onboardingContent } from "./Functions/onboardingFunctions";
import StyledOnboardingDialog from "../../Dialogs/styledDialogs/styledOnboardingDialog";
import { getAnalyticsEventNameForStep } from "./Analytics/onboardingAnalytics";

// Material UI
import Container from "@mui/material/Container";

const OnboardingFlow = ({
  handle,
  isMobile,
  isTablet,
  authUserIsSeed,
  handleMsgOpen,
  isNewMember = false,
}) => {
  const experimentRef = useRef();
  const increment = isNewMember ? 50 : 25;
  const [progress, setProgress] = useState(increment);
  const [activeStep, setActiveStep] = useState(0);
  const { eventName } = getAnalyticsEventNameForStep(activeStep);

  useEffect(() => {
    Mixpanel.time_event("__onboarding");
  }, []);

  /**
   * Toggle to the next step in the stepper
   * component.
   */
  const handleNext = () => {
    Mixpanel.track(eventName);
    const nextStep = isNewMember ? 3 : 1;
    if (experimentRef?.current ?? null) experimentRef.current.win();
    setActiveStep((prevActiveStep) => prevActiveStep + nextStep);
    setProgress(progress + increment);
    handleMsgOpen(null, {
      text: getMessage(activeStep),
      color: "text",
      colorType: "primary",
      duration: 5000,
      origin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
  };

  /**
   * Get the active component content
   */
  const active = onboardingContent(
    activeStep,
    handleNext,
    authUserIsSeed,
    isMobile,
    experimentRef,
    handle
  );

  return (
    <React.Fragment>
      {activeStep < 4 && (
        <Container maxWidth="md">
          <StyledOnboardingDialog
            open={activeStep < 4}
            handleClick={handleNext}
            isMobile={isMobile}
            isTablet={isTablet}
            progress={progress}
            PaperSxProps={active.content?.paperSxProps ?? {}}
            title={active.content.title}
            color={active.content?.color ?? "primary"}
            aria-labelledby={active.content.ariaLabelledby}
          >
            {active.component}
          </StyledOnboardingDialog>
        </Container>
      )}
    </React.Fragment>
  );
};

OnboardingFlow.propTypes = {
  isNewMember: PropTypes.bool,
  authUserIsSeed: PropTypes.bool,
  handleMsgOpen: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  authUserIsSeed: state.user.core.community.isSeed,
  handle: state.user.core.handle,
});

export default connect(mapStateToProps)(OnboardingFlow);
