/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  LOADING_USER_CORE,
  SET_USER_CORE,
  SET_USER_CORE_ERRORS,
  CLEAR_USER_CORE_ERRORS,
} from "../../../../actionTypes/userTypes";

import { SET_EMAIL_VERIFIED } from "../../../../actionTypes/sessionTypes";

import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function gets the core user information for the authenticated user. The action
 * requests the user information from the (/core/:type/:handle?) route which is built ontop of
 * the "getEntityCore" handler in Express. If the route does not recieve (req.param.handler)
 * the authenticated user is used. Given this, this action does not pass in any params.
 *
 * @param {object} dispatch - The redux dispatch object
 */
export const getUserCore = () => async (dispatch) => {
  dispatch({ type: CLEAR_USER_CORE_ERRORS });
  dispatch({ type: LOADING_USER_CORE });

  // Get User
  return await baseAxios
    .get(`/entity/user/core/`)
    .then(async (res) => {
      let { emailVerified: state, ...remainingCore } = res.data;
      dispatch({ type: SET_EMAIL_VERIFIED, payload: res.data.emailVerified });
      return await dispatch({
        type: SET_USER_CORE,
        payload: { ...remainingCore },
      });
    })
    .catch((error) => {
      return dispatch({
        type: SET_USER_CORE_ERRORS,
        payload: error ? (error.response ? error.response.date : error) : null,
      });
    });
};
