/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledDialogContent from "../../../Dialogs/styledComponents/styledDialogContent";
import StyledRuleDetailTemplate from "../../../../FeatureSpecific/Rules/styledRuleDetailTemplate";

/**
 * This object holds all of the content used
 * within the report bug dialog.
 */
const DIALOG_CONTENT = {
  content: (
    <span>
      Check out the rule details below. See a way that this rule could be
      changed? Suggest a rule change. Think it needs to be removed? Suggest a
      rule removal.
    </span>
  ),
};

// Component
const StyledRuleDetails = ({ rule }) => {
  return (
    <StyledDialogContent content={DIALOG_CONTENT.content}>
      <StyledRuleDetailTemplate rule={rule} />
    </StyledDialogContent>
  );
};

StyledRuleDetails.propTypes = {
  rule: PropTypes.object,
};

export default StyledRuleDetails;
