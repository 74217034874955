/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from 'react';

// Material UI
import Slide from "@mui/material/Slide";


// Slide Transition
export const RightTransition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="right" ref={ref} mountOnEnter unmountOnExit {...props} />
  );
});

// Slide Transition
export const LeftTransition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="left" ref={ref} mountOnEnter unmountOnExit {...props} />
  );
});

// Slide Transition
export const UpTransition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="up" ref={ref} mountOnEnter unmountOnExit {...props} />
  );
});

// Slide Transition
export const DownTransition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide direction="down" ref={ref} mountOnEnter unmountOnExit {...props} />
  );
});