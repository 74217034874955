/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BaseDialog from "../baseDialog";
import StyledDialogHeader from "../styledComponents/styledDialogHeader";

const StyledFlowDialog = ({
  open,
  title,
  color,
  isMobile,
  isTablet,
  onClose,
  backButton,
  handleClick,
  PaperProps,
  PaperSxProps,
  ariaLabelledBy,
  hasFullScreen,
  progress,
  children,
}) => {
  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      isMobile={isMobile}
      isTablet={isTablet}
      PaperProps={PaperProps}
      PaperSxProps={PaperSxProps}
      hasFullScreen={hasFullScreen}
      aria-labelledby={ariaLabelledBy}
    >
      <StyledDialogHeader
        title={title}
        color={color}
        isMobile={isMobile}
        handleClose={handleClick}
        backButton={backButton}
        progress={progress}
        hasButton
      />
      {children}
    </BaseDialog>
  );
};

StyledFlowDialog.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
  backButton: PropTypes.bool,
  onClose: PropTypes.func,
  handleClick: PropTypes.func,
  PaperProps: PropTypes.object,
  PaperSxProps: PropTypes.object,
  hasFullScreen: PropTypes.bool,
  ariaLabelledBy: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default StyledFlowDialog;
