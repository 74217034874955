/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  //Session Types
  LOADING_USER_SESSION,
  SET_USER_SESSION_ERRORS,
  CLEAR_USER_SESSION_ERRORS,
} from "../../../../actionTypes/sessionTypes";

// Action Handlers
import { seedNewCommunity } from "./seedNewCommunity";
import { joinCommunity } from "./joinCommunity";
import { getUserCore } from "../../../user/atomic/get/getUserCore";
import { getUserCommunity } from "../../../user/atomic/get/getUserCommunity";

// Auth Axios Instance
import unAuthAxios from "../../../../../util/axios/Instances/unauth.instance";

/**
 * This action function allows an existing user to switch between
 * their existing community and a new one. The new community will
 * either be a completely new community, or an existing community.
 * This chance is governed by the /seed-selection mechanism.
 *
 * @param {object} data - New User Data
 * @param {object} data - The user's location object
 * @param {string} data.locality - the user's city name
 * @param {string} data.political - the user's region/state
 * @param {string} data.geocode - the user's country
 * @param {string} data.placeId - the Google placeId
 * @param {string} data.description -  the full placeId, region, and locality string
 * @param {object} dispatch - The redux dispatch object
 */
export const switchCommunity = (data, navigate) => (dispatch) => {
  dispatch({ type: CLEAR_USER_SESSION_ERRORS });
  dispatch({ type: LOADING_USER_SESSION });

  return new Promise(async (resolve, reject) => {
    return await unAuthAxios
      .post("/seed-selection", data.placeId)
      .then(async (res) => {
        const isUserASeed = res.data.seed;
        return await assignCommunityDispatchHelper(data, isUserASeed, dispatch);
      })
      .then(async (res) => {
        await dispatch(getUserCore());
        await dispatch(getUserCommunity());
        navigate("/community");
        return resolve(true);
      })
      .catch((error) => {
        dispatch({
          type: SET_USER_SESSION_ERRORS,
          payload: error
            ? error.response
              ? error.response.data
              : error
            : null,
        });
      });
  });
};

/**
 * This helper function uses the isUserASeed condition to assign
 * the user to a new community. If they were chosen as a seed,
 * then they will create a new community. Otherwise they will 
 * join an existing community.
 *
 * @param {object} data - The user's location object
 * @param {string} isUserASeed - indicator for seed status
 * @param {object} dispatch - The redux dispatch object
 * @returns {func} the correct community action dispatch
 */
const assignCommunityDispatchHelper = async (data, isUserASeed, dispatch) => {
  const location = { location: { ...data } };
  if (isUserASeed) return await dispatch(seedNewCommunity("switch", location));
  return await dispatch(joinCommunity("switch", location));
};
