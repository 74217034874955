/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BaseList from "../baseList";
import BaseUserSearchElement from "../styledComponents/baseUserSearchElement";
import BaseLoadingUserSearchElement from "../styledComponents/baseLoadingUserSearchElement";

// Material UI
import Typography from "@mui/material/Typography";

const BaseUserSearchList = ({
  results,
  empty,
  loading,
  recents = {},
  isSearch = true,
  listProps = {},
  isDialog = false,
  handleSearchClick,
  recentHandles = [],
}) => {
  return (
    <BaseList
      sx={{
        borderRadius: 1,
        ...listProps,
      }}
    >
      {!loading && !empty ? (
        results.map((user) => (
          <React.Fragment key={`user-search-${user.handle}`}>
            <BaseUserSearchElement
              user={user}
              isDialog={isDialog}
              recents={recents}
              recentHandles={recentHandles}
              handleSearchClick={handleSearchClick}
              isSearch={isSearch}
              isRecent={false}
            />
          </React.Fragment>
        ))
      ) : !loading && empty ? (
        <Typography align="center" sx={{ my: 1 }}>
          No users found
        </Typography>
      ) : (
        [0, 1, 2].map((_, i) => (
          <React.Fragment key={`loop-loading-user-${i}`}>
            <BaseLoadingUserSearchElement />
          </React.Fragment>
        ))
      )}
    </BaseList>
  );
};

BaseUserSearchList.propTypes = {
  results: PropTypes.array.isRequired,
  empty: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  recents: PropTypes.object.isRequired,
  recentHandles: PropTypes.array.isRequired,
  isDialog: PropTypes.bool,
  handleSearchClick: PropTypes.func.isRequired,
  listProps: PropTypes.object,
};

export default BaseUserSearchList;
