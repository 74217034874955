/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import StyledIconButton from "../../../../Buttons/styledIconButton";
import StyledAppSearchDialog from "../../../../Dialogs/styledDialogs/styledAppSearchDialog";

// Material UI
import SearchIcon from "@mui/icons-material/Search";

const StyledSearchButton = ({ isTabletDown }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  return (
    <div>
      <StyledIconButton
        onClick={toggleOpen}
        color={isTabletDown ? "transparent" : "grey"}
        sxProps={{ p: 1.25 }}
        size="large"
      >
        <SearchIcon
          fontSize="medium"
          sx={{
            color: (theme) =>
              theme.palette.mode === "dark" && !isTabletDown
                ? theme.palette.background.paper
                : theme.palette.text.primary,
          }}
        />
      </StyledIconButton>
      {open && (
        <StyledAppSearchDialog
          open={open}
          isTabletDown={isTabletDown}
          handleClick={toggleOpen}
        />
      )}
    </div>
  );
};

StyledSearchButton.propTypes = {
  isTabletDown: PropTypes.bool,
};

export default StyledSearchButton;
