/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import Divider from "@mui/material/Divider";
import { alpha } from "@mui/system";

const StyledDivider = ({
  alphaAmt = 0.15,
  color = "grey",
  colorType = 50,
  sxProps = {},
  ...props
}) => {
  return (
    <Divider
      sx={{
        width: "100%",
        borderColor: (theme) =>
          theme.palette.mode === "dark"
            ? alpha(theme.palette.grey[50], alphaAmt)
            : theme.palette[color][colorType],
        ...sxProps,
      }}
      light
      {...props}
    />
  );
};

StyledDivider.propTypes = {
  alphaAmt: PropTypes.number,
  sxProps: PropTypes.object,
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "success",
    "text",
    "warning",
    "grey",
    "error",
  ]),
  colorTypes: PropTypes.oneOf([
    "main",
    "light",
    "dark",
    "secondary",
    "primary",
    50,
    100,
  ]),
};

export default StyledDivider;
