/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This function calculates the overlap between a users
 * characteristics relative to another person's
 * characteristics.
 *
 * @param {object} chars - the user characteristics
 * @param {array} activities - an array of activities
 * @param {array} lifestyles - an array of lifestyles
 * @returns {number} the percent overlap
 */
export const calculateCharacteristicsOverlap = (chars, activities, lifestyles) => {
  const combinedChars = [...chars.lifestyles, ...chars.activities];
  const userChars = [...activities, ...lifestyles];
  let overlap = 0;
  combinedChars.forEach((char) => {
    if (userChars.includes(char)) {
      overlap += 1;
    }
  });
  return Math.round((overlap * 100) / combinedChars.length);
};
