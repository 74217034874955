/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Components
import { StyledPrimary, StyledInfo } from "../../../Text/styledText";

export const UPLOAD_PHOTO_VARIANTS = [
  {
    name: "A",
    body: (
      <span>
        Pick your <StyledPrimary bold>Profile Photo</StyledPrimary>
      </span>
    ),
  },
  {
    name: "B",
    body: (
      <span>
        Set your <StyledPrimary bold>Loop Picture</StyledPrimary>
      </span>
    ),
  },
];

export const SET_CHARACTERISTICS_VARIANTS = [
  {
    name: "A",
    body: (
      <span>
        Tell everyone <StyledInfo bold>more about you</StyledInfo>
      </span>
    ),
  },
  {
    name: "B",
    body: (
      <span>
        What makes you <StyledInfo bold>different?</StyledInfo>
      </span>
    ),
  },
  {
    name: "C",
    body: (
      <span>
        What makes you <StyledInfo bold>unique?</StyledInfo>
      </span>
    ),
  },
];

export const INTRO_POST_VARIANTS = [];
