/**
 * Copyright 2022 Loop Technology, Inc.
 */

import { apiStatus } from "../../apiEnums";

/**
 * This reducer function returns the modified
 * success state object for the user's base data.
 *
 * @param {object} state - the unformatted state object
 * @param {string} [level1] - the shallow state object
 * @param {string} [level2] - the deeply nested state object
 * @param {object} [payload] - the payload object
 * @returns {object} the updated state object
 */
export const setBaseReducer = (
  state,
  level1 = null,
  level2 = null,
  payload = {}
) => {
  return baseReducer(state, "success", level1, level2, payload);
};

/**
 * This reducer function returns the modified
 * loading state object for the user's base data.
 *
 * @param {object} state - the unformatted state object
 * @param {string} [level1] - the shallow state object
 * @param {string} [level2] - the deeply nested state object
 * @param {object} [payload] - the payload object
 * @returns {object} the updated state object
 */
export const loadingBaseReducer = (
  state,
  level1 = null,
  level2 = null,
  payload = {}
) => {
  return baseReducer(state, "loading", level1, level2, payload);
};

/**
 * This reducer function returns the modified
 * set error state object for the base data.
 *
 * @param {object} state - the unformatted state object
 * @param {string} level1 - the shallow state object
 * @param {string} [level2] - the deeply nested state object
 * @param {object} [payload] - the payload object
 * @returns {object} the updated state object
 */
export const setErrorsBaseReducer = (
  state,
  level1 = null,
  level2 = null,
  payload = {}
) => {
  return baseReducer(state, "failed", level1, level2, payload);
};

/**
 * This reducer function returns the modified
 * clear error state object for the base data.
 *
 * @param {object} state - the unformatted state object
 * @param {string} level1 - the shallow state object
 * @param {string} [level2] - the deeply nested state object
 * @param {object} [payload] - the payload object
 * @returns {object} the updated state object
 */
export const clearErrorsBaseReducer = (
  state,
  level1 = null,
  level2 = null,
  payload = {}
) => {
  return baseReducer(state, "idle", level1, level2, payload);
};

/**
 * This reducer function returns the modified
 * loading state object for the base data.
 *
 * @param {object} state - the unformatted state object
 * @param {string} status - the api status
 * @param {string} [level1] - the shallow state object
 * @param {string} [level2] - the deeply nested state object
 * @param {object} [payload] - the payload object
 * @param {object} [payload.l0] - the unnessted payload object
 * @param {object} [payload.l1] - the nested 1 level payload object
 * @param {object} [payload.l2] - the nested 2 levels deep payload object
 * @param {object} [payload.error] - the error payload object
 * @returns {object} the updated state object
 */
const baseReducer = (state, status, level1, level2, payload) => {
  if (level1 === "core" && level2 === "details") {
    return setUserDetailsHelper(state, payload.l2);
  }
  payload = createPayloadHelper(payload);
  let error = createErrorHelper(state, status, level1, level2, payload.error);
  return level2 && level1
    ? {
        ...state,
        ...payload.l0,
        [level1]: {
          ...state[level1],
          ...payload.l1,
          [level2]: {
            ...state[level1][level2],
            api: {
              error: error,
              status: apiStatus(status),
            },
            ...payload.l2,
          },
        },
      }
    : level1 && !level2
    ? {
        ...state,
        ...payload.l0,
        [level1]: {
          ...state[level1],
          api: {
            error: error,
            status: apiStatus(status),
          },
          ...payload.l1,
        },
      }
    : {
        ...state,
        api: {
          error: error,
          status: apiStatus(status),
        },
        ...payload.l0,
      };
};

/**
 * This helper function creates the correct
 * paylod structure for the base reducer
 * function
 *
 * @param {object} payload - the payload
 * @param {object} payload.l0 - the unnessted payload object
 * @param {object} payload.l1 - the nested 1 level payload object
 * @param {object} payload.l2 - the nested 2 levels deep payload object
 * @param {object} payload.error - the error payload object
 * @returns {object} the formatted payload
 */
const createPayloadHelper = (payload) => {
  let basePayload = { l0: {}, l1: {}, l2: {}, error: {} };
  if (payload?.l0) basePayload.l0 = payload.l0;
  if (payload?.l1) basePayload.l1 = payload.l1;
  if (payload?.l2) basePayload.l2 = payload.l2;
  if (payload?.error) basePayload.error = payload.error;
  return basePayload;
};

/**
 * This helper function creates and returns the
 * correct error state.
 *
 * @param {object} state - the unformatted state object
 * @param {string} status - the api status
 * @param {string} [level1] - the shallow state object
 * @param {string} [level2] - the deeply nested state object
 * @param {object} error - the error payload object
 * @returns {object} the formatted error state
 */
const createErrorHelper = (state, status, level1, level2, error) => {
  return status === "idle"
    ? {}
    : status === "failed"
    ? error
    : getCorrectErrorLevelHelper(state, level1, level2);
};

/**
 * This helper function parses the nested error state
 * depending on the optional levels
 *
 * @param {object} state - the unformatted state object
 * @param {string} [level1] - the shallow state object
 * @param {string} [level2] - the deeply nested state object
 * @returns {object} the correct level for the error state
 */
const getCorrectErrorLevelHelper = (state, level1, level2) => {
  return level1 && level2
    ? state[level1][level2]?.api?.error
    : level1 && !level2
    ? state[level1]?.api?.error
    : state.api?.error ?? {};
};

/**
 * This helper function returns reducer for the user
 * core details section.
 *
 * @param {object} state - the unformatted state object
 * @param {object} payload - the payload
 * @returns {object} the updated state
 */
const setUserDetailsHelper = (state, payload) => {
  return {
    ...state,
    core: {
      ...state.core,
      details: {
        ...state.core.details,
        ...payload,
      },
    },
  };
};
