/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React from "react";
import { Navigate } from "react-router-dom";

// Components
import ProtectedComponent from "../RouteComponents/protectedComponent";

// Checks if user is authenticated
const getAuthStatus = () => localStorage.getItem("auth");

const PrivateRoute = ({
  component,
  isAuthenticated = Boolean(getAuthStatus()),
}) => {
  return !isAuthenticated ? (
    <Navigate to="/" />
  ) : (
    <ProtectedComponent component={component} />
  );
};

export default React.memo(PrivateRoute);
