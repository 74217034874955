/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Create Post
export const SET_NEW_POST = "SET_NEW_POST";
export const UPLOADING_NEW_POST = "UPLOADING_NEW_POST";
export const SET_NEW_POST_ERRORS = "SET_NEW_POST_ERRORS";
export const CLEAR_NEW_POST_ERRORS = "CLEAR_NEW_POST_ERRORS";

// Create Comment
export const SET_NEW_COMMENT = "SET_NEW_COMMENT";
export const UPLOADING_NEW_COMMENT = "UPLOADING_NEW_COMMENT";
export const SET_NEW_COMMENT_ERRORS = "SET_NEW_COMMENT_ERRORS";
export const CLEAR_NEW_COMMENT_ERRORS = "CLEAR_NEW_COMMENT_ERRORS";

// Create Post Vote
export const UPLOADING_NEW_VOTE = "UPLOADING_NEW_VOTE";
export const SET_VOTE_ERRORS = "SET_VOTE_ERRORS";
export const CLEAR_VOTE_ERRORS = "CLEAR_VOTE_ERRORS";

// Create Adventure
export const SET_NEW_ADVENTURE = "SET_NEW_ADVENTURE";
export const UPLOADING_NEW_ADVENTURE = "UPLOADING_NEW_ADVENTURE";
export const SET_NEW_ADVENTURE_ERRORS = "SET_NEW_ADVENTURE_ERRORS";
export const CLEAR_NEW_ADVENTURE_ERRORS = "CLEAR_NEW_ADVENTURE_ERRORS";