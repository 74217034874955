/* eslint-disable import/no-anonymous-default-export */
import {
  //Session
  LOADING_NEW_FACES_RECOMMENDATION,
  SET_NEW_FACES_RECOMMENDATION,
  SET_NEW_FACES_RECOMMENDATION_ERRORS,
  CLEAR_NEW_FACES_RECOMMENDATION_ERRORS,
} from "../actionTypes/discoverTypes";

import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

const initState = {
  api: {
    status: apiStatus("idle"),
    error: {},
  },
  adventures: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    byDay: {
      api: {
        status: apiStatus("idle"),
        error: {},
      },
    },
    byType: {
      api: {
        status: apiStatus("idle"),
        error: {},
      },
    },
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case LOADING_NEW_FACES_RECOMMENDATION:
      return {
        ...state,
        api: {
          ...state.api,
          status: apiStatus("loading"),
        },
      };

    // Success Types
    case SET_NEW_FACES_RECOMMENDATION:
      return {
        api: {
          ...state.api,
          status: apiStatus("success"),
        },
        ...action.payload,
      };

    // Failed Types
    case SET_NEW_FACES_RECOMMENDATION_ERRORS:
      return {
        ...state,
        authenticated: false,
        api: {
          status: apiStatus("failed"),
          error: action.payload,
        },
      };

    // Idle Types
    case CLEAR_NEW_FACES_RECOMMENDATION_ERRORS:
      return {
        ...state,
        tokenRefreshFailed: false,
        api: {
          status: apiStatus("idle"),
          error: {},
        },
      };
    default:
      return state;
  }
}
