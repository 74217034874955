/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";

// Components
import StyledListItem from "../../../styledComponents/styledListItem";
import {
  appDrawerLinks,
  appMenuLinks,
} from "../../../styledComponents/drawerLinks";

// Material UI
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";

// Lazy Loading Imports
const StyledMobileDrawer = lazy(() =>
  import("../../../styledComponents/styledMobileDrawer")
);
const StyledPlatformButton = lazy(() =>
  import("../Buttons/styledPlatformButton")
);
const StyledUserAvatar = lazy(() =>
  import("../../../../Avatars/styledAvatars/styledUserAvatar")
);

const loadingButton = (size, mr) => (
  <React.Fragment>
    <Skeleton variant="circular" sx={{ width: size, height: size, mr: mr }} />
  </React.Fragment>
);

const StyledNavLink = styled("div")(() => ({
  marginRight: "4px",
}));

// Component
const MobileDeviceAppLinks = ({
  open,
  isTabletDown,
  isLeader,
  toggleDrawer,
  handleDarkMode,
  anchorSettingsEl,
  handleUserLogout,
  handleDialogClick,
}) => {
  return (
    <Grid
      item
      xs={6}
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Suspense fallback={loadingButton(45, isTabletDown ? 0.5 : 1.5)}>
        <StyledNavLink>
          <StyledPlatformButton isTabletDown={isTabletDown} />
        </StyledNavLink>
      </Suspense>
      <Suspense fallback={loadingButton(48, 0)}>
        <StyledUserAvatar onClick={(e) => toggleDrawer(e, true)} />
      </Suspense>
      <Suspense fallback={<></>}>
        <StyledMobileDrawer
          open={open}
          toggleDrawer={toggleDrawer}
          handleDarkMode={handleDarkMode}
          handleUserLogout={handleUserLogout}
          anchorEl={anchorSettingsEl}
          isAppbar
        >
          <List>
            {appDrawerLinks(handleDialogClick, isLeader).map((link) => (
              <React.Fragment key={link.text}>
                <StyledListItem
                  type={link.type}
                  icon={link.icon}
                  text={link.text}
                  route={link.route}
                  handleClick={link.handler}
                />
              </React.Fragment>
            ))}
          </List>
          <Divider variant="middle" light />
          <List>
            {appMenuLinks(handleUserLogout, handleDialogClick).map((link) => (
              <React.Fragment key={link.text}>
                <StyledListItem
                  type={link.type}
                  icon={link.icon}
                  text={link.text}
                  route={link.route}
                  handleClick={link.handler}
                />
              </React.Fragment>
            ))}
          </List>
        </StyledMobileDrawer>
      </Suspense>
    </Grid>
  );
};

MobileDeviceAppLinks.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDarkMode: PropTypes.func.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  handleUserLogout: PropTypes.func.isRequired,
  isTabletDown: PropTypes.bool.isRequired,
  handleDialogClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default MobileDeviceAppLinks;
