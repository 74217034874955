/**
 * Copyright 2022 Loop Technology, Inc.
 */

import { styled } from "@mui/system";

//Icons
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import BugReportIcon from "@mui/icons-material/BugReport";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import AttachEmailRoundedIcon from "@mui/icons-material/AttachEmailRounded";

// SVG Icons
import { ReactComponent as Community } from "../../../../images/community.svg";
import { ReactComponent as Adventures } from "../../../../images/adventures___small.svg";
import { ReactComponent as Rules } from "../../../../images/rules___small.svg";
import { ReactComponent as LeaderHub } from "../../../../images/leaderHub___small.svg";
// import { ReactComponent as Coalitions } from "../../../../images/coalitions___small.svg";
import { ReactComponent as Leaderboard } from "../../../../images/leaderboard___small.svg";

const StyledCommunity = styled(Community)(() => ({
  verticalAlign: "bottom",
  display: "inline-block",
  marginRight: 1,
}));

const StyledAdventure = styled(Adventures)(() => ({
  verticalAlign: "bottom",
  display: "inline-block",
  marginRight: 1,
}));

const StyledLeaderboard = styled(Leaderboard)(() => ({
  verticalAlign: "bottom",
  display: "inline-block",
  marginRight: 1,
}));

const StyledLeaderHub = styled(LeaderHub)(() => ({
  verticalAlign: "bottom",
  display: "inline-block",
  marginRight: 1,
}));

const StyledRules = styled(Rules)(() => ({
  verticalAlign: "bottom",
  display: "inline-block",
  marginRight: 1.5,
}));

// const StyledCoalitions = styled(Coalitions)(() => ({
//   verticalAlign: "bottom",
//   display: "inline-block",
//   marginRight: 1,
// }));

export const appDrawerLinks = (handleDialogClick, isLeader) => {
  let base = [
    {
      text: "Community",
      icon: <StyledCommunity height={25} width={30} />,
      type: "button",
      handler: (e) => handleDialogClick(e, "Community"),
    },
    {
      text: "LeaderBoard",
      icon: <StyledLeaderboard height={25} width={30} />,
      route: "/discover?type=leaderboard",
      type: "nav",
    },
    {
      text: "Adventures",
      icon: <StyledAdventure height={25} width={30} />,
      route: "/discover?type=adventures",
      type: "nav",
    },
    {
      text: "Rules",
      icon: <StyledRules height={25} width={30} />,
      type: "button",
      handler: (e) => handleDialogClick(e, "Rules"),
    },
  ];

  return isLeader
    ? [
        ...base,
        {
          text: "LeaderHub",
          icon: <StyledLeaderHub height={25} width={30} />,
          route: "/leader-hub",
          type: "nav",
        },
      ]
    : base;
};

export const appMenuLinks = (handleUserLogout, handleDialogClick) => {
  return [
    {
      text: "Settings & Privacy",
      route: "/settings",
      icon: <SettingsRoundedIcon sx={{ mr: 1, verticalAlign: "bottom" }} />,
      type: "nav",
    },
    {
      text: "Invite to Loop",
      route: "/invites",
      icon: <AttachEmailRoundedIcon sx={{ mr: 1, verticalAlign: "bottom" }} />,
      type: "nav",
    },
    // {
    //   text: "Loop ID",
    //   route: "/community-id",
    //   icon: <QrCode2RoundedIcon sx={{ mr: 1, verticalAlign: "bottom" }} />,
    //   type: "nav",
    // },
    {
      text: "Report a Bug",
      icon: <BugReportIcon sx={{ mr: 1, verticalAlign: "bottom" }} />,
      type: "button",
      handler: (e) => handleDialogClick(e, "Report Bug"),
    },
    // {
    //   text: "Help",
    //   route: "/help",
    //   icon: <HelpOutlineRoundedIcon />,
    //   type: "nav",
    // },
    {
      text: "Log Out",
      icon: <ExitToAppRoundedIcon sx={{ mr: 1, verticalAlign: "bottom" }} />,
      type: "button",
      handler: handleUserLogout,
    },
  ];
};

export const navDrawerLinks = [
  {
    text: "Home",
    route: "/",
    type: "nav",
    icon: <></>,
  },
  {
    text: "About",
    route: "https://about.looop.cool",
    type: "a",
    icon: <></>,
  },
  {
    text: "Governance",
    route: "/governance",
    type: "nav",
    icon: <></>,
  },
  // {
  //   text: "Small Business",
  //   route: "/businesses",
  //   type: "nav",
  // },
  {
    text: "Policies",
    route: "/policies",
    type: "nav",
    icon: <></>,
  },
  {
    text: "Media Toolkit",
    route: "/media-kit",
    type: "nav",
    icon: <></>,
  },
  // {
  //   text: "Careers",
  //   route: "/careers",
  //   type: "nav",
  // },
];
