/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";

// Material UI
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

const BaseLoadingUserSearchElement = () => {
  return (
    <Stack direction="row" sx={{ pl: 1, py: 0.5 }}>
      <Skeleton variant="circular" width={47} height={47} />
      <Stack sx={{ ml: 1 }} justifyContent="center">
        <Skeleton variant="text" height={20} width={100} />
        <Skeleton variant="text" height={15} width={75} />
      </Stack>
    </Stack>
  );
};

export default BaseLoadingUserSearchElement;
