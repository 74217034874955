/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Axios 
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

// Functions
import { refreshAuthLogic } from "../../../redux/actions/auth/atomic/get/refreshAuthLogic";

// Obtain the fresh token each time the instance is invoked
const getAuthToken = () => localStorage.getItem("AuthToken");

const baseAxios = axios.create({
  baseURL: "https://api.looop.cool",
  withCredentials: true,
  credentials: "include",
});

// Set the default instance Auth header o
baseAxios.defaults.headers.common["Authorization"] = getAuthToken();

const authRefreshOptions = {
  statusCodes: [401, 403],
};

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(baseAxios, refreshAuthLogic, authRefreshOptions);

// Use interceptor to inject the token to requests
baseAxios.interceptors.request.use((request) => {
  request.headers["Authorization"] = getAuthToken();
  return request;
});

export default baseAxios;
