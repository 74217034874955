/**
 * Copyright 2022 Loop Technology, Inc.
 */

import { CLEAR_USER_FEED } from "../../../../actionTypes/feedTypes";

/**
 * This action function resets the user feed in 
 * scenarios when the user changes their community
 *
 * @param {object} dispatch - The redux dispatch object
 */
export const clearUserFeed = () => async (dispatch) => {
  return dispatch({ type: CLEAR_USER_FEED });
};
