/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */
import {
  setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";
import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

import {
  // Wallet Address
  SET_WALLET_ADDRESS,
  DELETE_WALLET_ADDRESS,
  LOADING_WALLET_ADDRESS,
  SET_WALLET_ADDRESS_ERRORS,
  CLEAR_WALLET_ADDRESS_ERRORS,

  // Wallet Balance
  SET_WALLET_BALANCE,
  LOADING_WALLET_BALANCE,
  SET_WALLET_BALANCE_ERRORS,
  CLEAR_WALLET_BALANCE_ERRORS,
} from "../actionTypes/walletTypes";

const initState = {
  api: {
    status: apiStatus("idle"),
    error: {},
  },
  address: null,
  balance: 0,
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case LOADING_WALLET_ADDRESS:
      return loadingBaseReducer(state, "wallet");
    case LOADING_WALLET_BALANCE:
      return loadingBaseReducer(state, "balance");

    // Success Types
    case SET_WALLET_ADDRESS:
      return setBaseReducer(state, null, null, {
        l0: { address: action.payload.address },
      });
    case DELETE_WALLET_ADDRESS:
      return setBaseReducer(state, null, null, {
        l0: { address: null },
      });
    case SET_WALLET_BALANCE:
      return setBaseReducer(state, null, null, {
        l0: { balance: action.payload.balance },
      });

    // Failed Types
    case SET_WALLET_ADDRESS_ERRORS:
      return setErrorsBaseReducer(state, null, null, {
        error: { ...action.payload },
      });
    case SET_WALLET_BALANCE_ERRORS:
      return setErrorsBaseReducer(state, null, null, {
        error: { ...action.payload },
      });

    // Idle Types
    case CLEAR_WALLET_ADDRESS_ERRORS:
    case CLEAR_WALLET_BALANCE_ERRORS:
      return clearErrorsBaseReducer(state);
    default:
      return state;
  }
}
