/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { styled, Box } from "@mui/system";

const StyledFormButton = ({
  isSubmitting,
  fullWidth = true,
  text = "Next",
  type = "submit",
  color = "secondary",
  colorType = "main",
  align = "center",
  ...props
}) => {
  const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette[color][colorType],
    color: theme.palette[color].contrastText,
    padding: theme.spacing(2.25, 5),
    fontSize: theme.spacing(2),
    "&:hover": {
      backgroundColor: theme.palette[color][colorType],
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3),
    },
  }));

  return (
    <Box textAlign={align}>
      <StyledButton
        {...props}
        variant="contained"
        type={type}
        disableElevation
        fullWidth={fullWidth}
      >
        {text}
        {isSubmitting && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              color: `${color}.${colorType}`,
            }}
          />
        )}
      </StyledButton>
    </Box>
  );
};

StyledFormButton.propTypes = {
  isSubmitting: PropTypes.bool,
  type: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  align: PropTypes.oneOf(["center", "left", "end"]),
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "success",
    "text",
    "error",
    "warning",
    "info",
  ]),
  colorTypes: PropTypes.oneOf([
    "main",
    "light",
    "dark",
    "secondary",
    "primary",
  ]),
};

export default StyledFormButton;
