/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";

// Component
import data from "../../../images/loading.json";
import LottieAnimation from "../Animation/LottieAnimation";

// Material UI
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { StyledPrimary } from "../Text/styledText";

const LayoutLoading = () => {
  return (
    <Container maxWidth="sm" sx={{ width: "100%", marginTop: "25vh" }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <React.Fragment>
            <Box textAlign="center" sx={{ height: "15vh" }}>
              <LottieAnimation
                animation={data}
                style={{
                  height: "15vh",
                  display: "inline-block",
                  marginBottom: 16,
                }}
                play
              />
            </Box>
            <Typography
              variant="h3"
              color="textPrimary"
              align="center"
              sx={{
                mt: "2.5vh",
                fontSize: "24px",
                lineHeight: "28px",
                fontWeight: 400,
                letterSpacing: "-0.5px",
              }}
            >
              Getting your <StyledPrimary bold>Community</StyledPrimary>
            </Typography>
          </React.Fragment>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(LayoutLoading);
