/**
 * Copyright 2022 Loop Technology, Inc.
 */

import * as yup from "yup";

const twoTruthsSchema = yup.object().shape({
  truthOne: yup.string().required("You must add a truth"),
  truthTwo: yup.string().required("You must add a second truth"),
  lie: yup.string().required("You must add a lie"),
});

export default twoTruthsSchema;
