/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

import { debounce } from "../functions/debounce";
import algoliasearch from "algoliasearch/lite";
import { useState, useCallback, useEffect } from "react";

// Test Aloglia Keys
// const APP_ID = "latency";
// const PUBLIC_API_KEY = "6be0576ff61c053d5f9a3225e2a90f76";
// const INDEX = "instant_search";

const APP_ID = "QBSF77QRNV";
const PUBLIC_API_KEY = "c8842e8c9ab2feb5a2d279f5bdfb6e24";
const INDEX = "prod_Users";
const HPP = 5; // Hits Per Page
const ATTR = [
  "handle",
  "firstName",
  "lastName",
  "imageUrl",
  "imageUrlThumbs",
  "imageUrlThumbs_webp",
  "community.communityHandle",
];

/**
 * This custom hook uses the search input query (query) to
 * search through the defined algolia index (indexName).
 * It returns an object with the search results.
 *
 * @param {string} query the search query
 * @param {number} delayLength - the number of chars before first query
 * @param {string} indexName the index name
 * @param {number} HPP - the number of hits per page
 * @param {number} ATTR - the attributes to be returned
 * @returns {object} an object with the search results
 */
const useAlgoliaSearch = (
  query = "",
  delayLength = 1,
  indexName = INDEX,
  hpp = HPP,
  attr = ATTR
) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const client = algoliasearch(APP_ID, PUBLIC_API_KEY);
  const index = client.initIndex(indexName);
  const validQuery = query.length > delayLength;
  const emptyResults = results.length === 0;

  /**
   * This function searchs the algolia index for
   * the given the query. It debounces the query
   * until a 500ms pause.
   */
  const searchFast = useCallback(
    debounce(async (query) => {
      const result = await index.search(query, {
        attributesToRetrieve: attr,
        hitsPerPage: hpp,
      });
      setLoading(false);
      setResults(result.hits);
    }, 250),
    []
  );

  useEffect(() => {
    if (validQuery) {
      setLoading(true);
      searchFast(query);
    }
    if (query.length < delayLength + 1 && results && results.length > 0)
      setResults([]);
    return () => setResults([]);
  }, [query]);

  return { results, loading, empty: validQuery && emptyResults };
};

export default useAlgoliaSearch;
