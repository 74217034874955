/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  SET_USER_PROFILE_IMAGE,
  SET_USER_CORE_ERRORS,
  CLEAR_USER_CORE_ERRORS,
  LOADING_USER_CORE,
} from "../../../../actionTypes/userTypes";

// Axios Instance
import baseAxios from "../../../../../util/axios/Instances/base.instance";
import { stringify } from "qs";

/**
 * This action function updates the authenticated user's
 * profile image.
 *
 * @param {object} form - Profile picture form data
 * @param {string} storage - the storage type
 * @param {object} imageCoordinates - the cropped images
 * @param {object} dispatch - The redux dispatch object
 */
export const changeProfileImage =
  (form, storage = "g", coordinates = {}) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_USER_CORE_ERRORS });
    dispatch({ type: LOADING_USER_CORE });
    const queryParams = stringify({ ...coordinates }, { allowDots: true });

    return await baseAxios
      .post(`/user/profile/image/${storage}?${queryParams}`, form)
      .then(async (res) => {
        dispatch({ type: CLEAR_USER_CORE_ERRORS });
        await dispatch({
          type: SET_USER_PROFILE_IMAGE,
          payload: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_USER_CORE_ERRORS,
          payload: error?.response?.data ?? null ? error : null,
        });
      });
  };
