/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React from "react";
import PropTypes from "prop-types";

// Components & Hooks
import { StyledSuccess } from "../../../Text/styledText";
import StyledRule from "../../../../FeatureSpecific/Rules/styledRule";
import StyledDialogFooter from "../../../Dialogs/styledComponents/styledDialogFooter";
import StyledDialogContent from "../../../Dialogs/styledComponents/styledDialogContent";
import StyledRuleLabel from "../../../../FeatureSpecific/Rules/styledRuleLabel";

// Material UI
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

const BaseRules = ({
  isMobile,
  handleDialogClick,
  reachedVotingThreshold = false,
  RULES = {},
}) => {
  return (
    <React.Fragment>
      <StyledDialogContent content={DIALOG_CONTENT.content}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{ my: 1 }}
        >
          {DIALOG_CONTENT.types.map((type, index) => (
            <Box sx={{ m: 0.5 }} key={index}>
              <StyledRuleLabel level={type} />
            </Box>
          ))}
        </Grid>
        {reachedVotingThreshold ? (
          Object.keys(RULES).length > 0 ? (
            <Box sx={{ m: 2 }}>
              {Object.keys(RULES).map((rule, index) => (
                <React.Fragment key={index}>
                  <StyledRule
                    rule={RULES[rule]}
                    isMobile={isMobile}
                    handleDialogClick={handleDialogClick}
                  />
                </React.Fragment>
              ))}
            </Box>
          ) : (
            <Box sx={{ mt: 2, mb: 1, mx: 1 }}>
              <Typography variant="body1" color="textSecondary" align="center">
                {DIALOG_CONTENT.emptyRulesText}
              </Typography>
            </Box>
          )
        ) : reachedVotingThreshold ? (
          <Box sx={{ mt: 2, mb: 1, mx: 1 }}>
            <Typography variant="body1" color="textSecondary" align="center">
              Loading community rules...
            </Typography>
          </Box>
        ) : (
          <Box sx={{ mt: 2, mb: 1, mx: 1 }}>
            <Typography variant="body1" color="textSecondary" align="center">
              {DIALOG_CONTENT.toSmallText}
            </Typography>
          </Box>
        )}
      </StyledDialogContent>
      <StyledDialogFooter
        footerText={
          !reachedVotingThreshold ? DIALOG_CONTENT.toSmallFooterText : null
        }
        text="Propose a New Rule"
        disabled={!reachedVotingThreshold}
        handleSubmit={(e) => handleDialogClick(e, "proposal", null)}
        // secondaryButton={{
        //   color: "success",
        //   colorType: "main",
        //   text: "Vote on Proposed Rules",
        //   handleSubmit: () => ({}),
        //   disabled: !reachedVotingThreshold,
        //   variant: "text",
        // }}
      />
    </React.Fragment>
  );
};

BaseRules.propTypes = {
  RULES: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  reachedVotingThreshold: PropTypes.bool,
  handleDialogClick: PropTypes.func.isRequired,
};

export default BaseRules;

/**
 * This object holds all of the content used
 * within the rules dialog.
 */
const DIALOG_CONTENT = {
  content: (
    <span>
      Loop gives you the power to keep the peace ✌️. Click the{" "}
      <SettingsRoundedIcon fontSize="small" sx={{ verticalAlign: "bottom" }} />{" "}
      to suggest a change or suggest the removal of any rule.
    </span>
  ),
  types: ["important", "moderate", "suggested"],
  emptyRulesText: (
    <span>
      It's the wild west 🤠 <br /> Your community has no rules
    </span>
  ),
  toSmallText: (
    <span>
      It's too soon for rules. <br />
      Your community is still growing 🪴{" "}
    </span>
  ),
  toSmallFooterText: (
    <span>
      Communities need <StyledSuccess bold>10+ people</StyledSuccess> to create
      rules
    </span>
  ),
};
