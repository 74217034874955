/**
 * This helper function creates query paramters for the end of
 * routes in the Redux Actions.
 *
 * @param {object} params - The query parameters
 * @returns {string} The formatted query strings
 */
export const reduceQueryParams = (params) => {
  let queryString;
  if (Object.keys(params).length > 0) {
    for (const [key, value] of Object.entries(params)) {
      if (key === Object.keys(params)[0]) {
        queryString = `?${key}=${value}`;
      } else {
        queryString = queryString + `&${key}=${value}`;
      }
    }
    return queryString;
  }
  return "";
};
