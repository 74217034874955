/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { Suspense, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { getCommunityMembers } from "../../../redux/actions/user/atomic/get/getCommunityMembers";
import { getActiveProfileCommunityMembers } from "../../../redux/actions/activeProfile/atomic/get/getActiveProfileCommunityMembers";

// Components & Functions
import StyledAvatarSet from "./styledAvatarSet";
import { getMemberSetDetailsHelper } from "./getMemberSetDetailsHelper";
import StyledMemberBoundary from "../../UI/ErrorBoundaries/StyledBoundaries/styledMemberBoundary";

// Material UI
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledMemberSet = ({
  type,
  status,
  isMobile,
  isTablet,
  lastId,
  lastDate,
  isDialog = false,
  userHandle,
  communityHandle,
  getCommunityMembers,
  getActiveProfileCommunityMembers,
  MEMBERS = {},
}) => {
  const isTiny = useMediaQuery("(max-width:340px)");
  const isMidMobile = useMediaQuery("(max-width:385px)");
  const isSmallTablet = useMediaQuery("(max-width:575px)");
  const isSmallDesktop = useMediaQuery(
    "(min-width:768px) and (max-width:930px)"
  );
  const isMidDesktop = useMediaQuery("(max-width:930px) and (max-width:1023px)");

  let { limit, spacing, avatarSize, skeletonArray } = getMemberSetDetailsHelper(
    isTiny,
    isMidMobile,
    isMobile,
    isSmallTablet,
    isTablet,
    isSmallDesktop,
    isMidDesktop,
    isDialog
  );

  const [cursors, setCursors] = useState([0, limit]);
  const [currentPage, setCurrentPage] = useState(1);
  const isEndOfPages = Object.keys(MEMBERS).length < cursors[1];
  let isStartOfPages = cursors[0] === 0;
  let blankMembers = getBlankMembersHelper(Object.keys(MEMBERS), limit);

  useEffect(() => {
    if (type === "user" && !lastId && !lastDate && !isDialog)
      fetchMembersHelper();
    if (type !== "user" && communityHandle) fetchMembersHelper();
  }, [type, communityHandle, isDialog]);

  useEffect(() => {
    setCursors([0, limit]);
    setCurrentPage(1);
  }, [isMobile, isSmallTablet, isTablet, isSmallDesktop]);

  /**
   * This helper function fetches more members and adds
   * them to the redux feed.
   *
   * @param {string} type - the profile type
   * @param {string} communityHandle - the community handle
   * @param {number} limit - the number of members to return
   * @returns {function} - the action function with the correct parameters
   */
  const fetchMembersHelper = useCallback(
    (limit = 10) =>
      type === "user"
        ? getCommunityMembers(lastId, lastDate, limit)
        : getActiveProfileCommunityMembers(
            communityHandle,
            lastId,
            lastDate,
            limit
          ),
    [type, communityHandle, lastId, lastDate, limit]
  );

  /**
   * This helper function paginates through the members
   * in the redux feed. If the direction is forward it also
   * dispatches the query for the next page of members.
   *
   * @param {number} limit - the number of members to return
   * @param {boolean} isForward - if the direction of pagination is forward
   * @returns {null}
   */
  const memberPaginationHelper = async (limit, isForward) => {
    let startIndex, endIndex, newPage;
    if (isForward) {
      newPage = currentPage + 1;
      startIndex = limit * currentPage;
      endIndex = startIndex + limit;
      if (lastDate && lastId) fetchMembersHelper(type, communityHandle);
      setCurrentPage(newPage);
      return setCursors([startIndex, endIndex]);
    }
    newPage = currentPage === 1 ? 1 : currentPage - 1;
    let baseIndex = limit * newPage;
    startIndex = newPage === 1 ? 0 : baseIndex - limit;
    endIndex = newPage === 1 ? limit : baseIndex;
    setCurrentPage(newPage);
    return setCursors([startIndex, endIndex]);
  };

  /**
   * This handler call back function dispatches the
   * pagination helper.
   */
  let handlePagination = useCallback(
    (e, limit, isForward) => memberPaginationHelper(limit, isForward),
    [currentPage, fetchMembersHelper]
  );

  const loadingSet = (
    <React.Fragment>
      {skeletonArray.map((_, index) => (
        <Skeleton
          key={`community-member-skeleton-${index}`}
          variant="circular"
          width={avatarSize * 9.5}
          height={avatarSize * 9.5}
          sx={{ my: 1, mx: spacing }}
        />
      ))}
    </React.Fragment>
  );

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      wrap="nowrap"
      sx={{ mt: isMobile ? 0.5 : 0, maxWidth: "600px" }}
    >
      {status !== "loading" ? (
        <Suspense fallback={loadingSet}>
          <StyledMemberBoundary fetchMembersHelper={fetchMembersHelper}>
            <StyledAvatarSet
              status={status}
              limit={limit}
              spacing={spacing}
              cursors={cursors}
              avatarSize={avatarSize}
              userHandle={userHandle}
              blankMembers={blankMembers}
              isEndOfPages={isEndOfPages}
              isStartOfPages={isStartOfPages}
              handlePagination={handlePagination}
              MEMBERS={MEMBERS}
            />
          </StyledMemberBoundary>
        </Suspense>
      ) : (
        loadingSet
      )}
    </Grid>
  );
};

StyledMemberSet.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool,
  communityHandle: PropTypes.string,
  MEMBERS: PropTypes.object,
  lastId: PropTypes.string,
  lastDate: PropTypes.string,
  isDialog: PropTypes.bool,
  userHandle: PropTypes.string,
  getCommunityMembers: PropTypes.func,
  getActiveProfileCommunityMembers: PropTypes.func,
  type: PropTypes.oneOf(["user", "people", "community"]).isRequired,
  status: PropTypes.oneOf(["idle", "success", "failed", "loading"]).isRequired,
};

const mapStateToProps = (state, props) => {
  let type = props.type === "user" ? "user" : "activeProfile";
  return {
    status: state[type].community.members.api.status,
    userHandle: type === "user" ? state[type].core.handle : null,
    communityHandle: state[type].community.communityHandle,
    MEMBERS: state[type].community.members.members,
    lastId: state[type].community.members.lastId,
    lastDate: state[type].community.members.lastDate,
  };
};

const mapActionsToProps = {
  getCommunityMembers,
  getActiveProfileCommunityMembers,
};

export default connect(mapStateToProps, mapActionsToProps)(StyledMemberSet);

/**
 * This helper function returns the number of
 * blank spaces need to build the full member set.
 *
 * @param {array} arr - the member array
 * @param {number} limit - the current limit
 * @returns {array} array with the number of blanks
 */
const getBlankMembersHelper = (arr, limit) => {
  let modArr = [];
  let mod = limit - (arr.length % limit);
  for (let i = 0; i < mod; i++) {
    modArr.push(i);
  }
  return modArr;
};
