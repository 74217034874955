/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Do not Remove (IE Support)
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// React
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

// Components and Styles
import "@fontsource/dm-sans";
import "@fontsource/dm-sans/500.css";
import "@fontsource/poppins";
import "@fontsource/poppins/500.css";
import "./styles/App.css";
import App from "./App";
import { message } from "./components/SEO/consoleMessage";

// Material UI
import { StyledEngineProvider } from "@mui/material/styles";

// 
// import { Web3ReactProvider } from "@web3-react/core";
// import { Web3Provider } from "@ethersproject/providers";

console.log(message); // Do not remove

// const getLibrary = (provider) => new Web3Provider(provider); 

ReactDOM.render(
  <Router>
    <StyledEngineProvider injectFirst>
      {/* <Web3ReactProvider getLibrary={getLibrary}> */}
        <App />
      {/* </Web3ReactProvider> */}
    </StyledEngineProvider>
  </Router>,
  document.getElementById("root")
);

