/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledIconButton from "../../UI/Buttons/styledIconButton";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/CloseRounded";

// Component
const StyledUserDetailsMenuHeader = ({
  handle,
  firstName,
  lastName,
  pronouns,
  communityHandle,
  handleMenuClose,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ px: 0.5, py: 0.5 }}
    >
      <Grid
        item
        xs={9}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="textPrimary">
            {firstName} {lastName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2" color="textSecondary">
            @{handle} • @{communityHandle} {pronouns && `• ${pronouns}`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <StyledIconButton size="small" onClick={handleMenuClose} align="end">
          <CloseIcon fontSize="small" />
        </StyledIconButton>
      </Grid>
    </Grid>
  );
};

StyledUserDetailsMenuHeader.propTypes = {
  handle: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  pronouns: PropTypes.string,
  handleMenuClose: PropTypes.func,
  communityHandle: PropTypes.string,
};

export default StyledUserDetailsMenuHeader;
