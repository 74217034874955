/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React from "react";
import PropTypes from "prop-types";

// Components
import BaseBoundary from "../baseBoundary";
import StyledAppError from "../StyledFallbacks/styledAppError";

const StyledAppBoundary = ({ children }) => {
  return <BaseBoundary fallback={StyledAppError}>{children}</BaseBoundary>;
};

StyledAppBoundary.propTypes = {
  children: PropTypes.node,
};

export default StyledAppBoundary;
