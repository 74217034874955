/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { createRuleRemoval } from "../../../../../redux/actions/user/atomic/post/createRuleRemoval";

// Components
import StyledDialogContent from "../../../Dialogs/styledComponents/styledDialogContent";
import StyledDialogFooter from "../../../Dialogs/styledComponents/styledDialogFooter";
import StyledRuleDetailTemplate from "../../../../FeatureSpecific/Rules/styledRuleDetailTemplate";

/**
 * This object holds all of the content used
 * within the report bug dialog.
 */
const DIALOG_CONTENT = {
  content: (
    <span>
      Think this rule is not helpful or unnecessary? Send a rule removal
      suggestion to your community.
    </span>
  ),
};

// Component
const StyledRuleRemoval = ({ rule, handleClose, createRuleRemoval }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * This submit handler function is used
   * to update the core details.
   *
   * @param {object} data
   */
  const handleSubmit = async () => {
    setIsSubmitting(true);
    return await createRuleRemoval(rule.ruleId)
      .then(() => {
        setIsSubmitting(false);
        return handleClose();
      })
      .catch(() => {
        setIsSubmitting(false);
        return handleClose();
      });
  };

  return (
    <React.Fragment>
      <StyledDialogContent content={DIALOG_CONTENT.content}>
        <StyledRuleDetailTemplate rule={rule} />
      </StyledDialogContent>
      <StyledDialogFooter
        text="Propose Rule Removal"
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
        color="warning"
        colorType="main"
      />
    </React.Fragment>
  );
};

StyledRuleRemoval.propTypes = {
  rule: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  createRuleRemoval: PropTypes.func,
};

const mapActionsToProps = {
  createRuleRemoval,
};

export default connect(null, mapActionsToProps)(StyledRuleRemoval);
