/**
 * * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import Button from "@mui/material/Button";
import { alpha, styled, Box } from "@mui/system";

const StyledBaseButton = ({
  text,
  fullWidth = true,
  color = "secondary",
  colorType = "main",
  align = "center",
  variant = "contained",
  fade = false,
  ...props
}) => {
  const StyledButton = styled(Button)(({ theme }) => ({
    fontSize: 16,
    backgroundColor:
      theme.palette.mode === "dark" && color === "grey"
        ? alpha(theme.palette.grey[100], 0.7)
        : alpha(theme.palette[color][colorType], fade ? 0.1 : 1),
    color: fade
      ? theme.palette[color][colorType]
      : color === "grey"
      ? theme.palette.mode === "dark"
        ? theme.palette.background.default
        : theme.palette.text.primary
      : theme.palette[color].contrastText,
    padding: theme.spacing(2.25, 5),
    "&:hover": {
      backgroundColor:
        theme.palette.mode === "dark" && color === "grey"
          ? alpha(theme.palette.grey[100], 0.6)
          : alpha(theme.palette[color][colorType], fade ? 0.1 : 1),
    },
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 3),
    },
  }));

  return (
    <React.Fragment>
      {align === false ? (
        <StyledButton
          {...props}
          disableElevation
          fullWidth={fullWidth}
          variant={variant}
        >
          {text}
        </StyledButton>
      ) : (
        <Box textAlign={align}>
          <StyledButton
            {...props}
            disableElevation
            fullWidth={fullWidth}
            variant={variant}
          >
            {text}
          </StyledButton>
        </Box>
      )}
    </React.Fragment>
  );
};

StyledBaseButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  align: PropTypes.oneOf(["center", "left", "end", false]),
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "success",
    "text",
    "warning",
    "grey",
    "error",
  ]),
  colorTypes: PropTypes.oneOf([
    "main",
    "light",
    "dark",
    "secondary",
    "primary",
  ]),
};

export default StyledBaseButton;
