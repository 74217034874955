/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This helper function returns the correct details for the
 * member set based on the current size of the window.
 *
 * @param {boolean} isTiny - indicator if tiny screen
 * @param {boolean} isMidMobile - indicator if midsize mobile screen
 * @param {boolean} isMobile - indicator if mobile screen
 * @param {boolean} isSmallTablet - indicator if small tablet screen
 * @param {boolean} isTablet - indicator if tablet screen
 * @param {boolean} isSmallDesktop - indicator if small desktop screen
 * @param {boolean} [isDialog] - indicator if dialog
 * @returns
 */
export const getMemberSetDetailsHelper = (
  isTiny,
  isMidMobile,
  isMobile,
  isSmallTablet,
  isTablet,
  isSmallDesktop,
  isMidDesktop,
  isDialog
) => {
  if (isTiny) {
    return {
      limit: 4,
      spacing: 0.25,
      avatarSize: 6,
      skeletonArray: [0, 1, 2, 3],
    };
  } else if (isMidMobile) {
    return {
      limit: 4,
      spacing: 0.45,
      avatarSize: 6.4,
      skeletonArray: [0, 1, 2, 3],
    };
  } else if (isMobile || isDialog) {
    return {
      limit: 5,
      spacing: 0.35,
      avatarSize: 6.4,
      skeletonArray: [0, 1, 2, 3, 4],
    };
  } else if (isSmallTablet) {
    return {
      limit: 5,
      spacing: 0.45,
      avatarSize: 7,
      skeletonArray: [0, 1, 2, 3, 4],
    };
  } else if (isTablet) {
    return {
      limit: 6,
      spacing: 0.5,
      avatarSize: 7.25,
      skeletonArray: [0, 1, 2, 3, 4, 5],
    };
  } else if (isSmallDesktop) {
    return {
      limit: 6,
      spacing: 0.5,
      avatarSize: 7.25,
      skeletonArray: [0, 1, 2, 3, 4, 5],
    };
  } else if (isMidDesktop) {
    return {
      limit: 6,
      spacing: 0.5,
      avatarSize: 7.45,
      skeletonArray: [0, 1, 2, 3, 4, 5],
    };
  }
  return {
    limit: 6,
    spacing: 0.5,
    avatarSize: 8,
    skeletonArray: [0, 1, 2, 3, 4, 5],
  };
};
