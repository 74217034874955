/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// Forms
import HangmanForm from "../../../Forms/styledForms/IntroductionPosts/hangmanForm";
import TwoTruthsForm from "../../../Forms/styledForms/IntroductionPosts/twoTruthsForm";

// Components
import StyledDialogFooter from "../../../Dialogs/styledComponents/styledDialogFooter";
import StyledDialogContent from "../../../Dialogs/styledComponents/styledDialogContent";

// Component
const StyledIntroPostDialog = ({ handleClick }) => {
  let formRef = useRef();
  const [type, setType] = useState(null);
  const [valid, setValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * This helper function dispatches the
   * form to submit outside of the formik
   * baseForm component.
   */
  const onSubmit = () => {
    formRef.current.submitForm();
  };

  /**
   * This useEffect function sets the introduction
   * game for the new user when the component mounts
   */
  useEffect(() => {
    if (Math.random() > 0.5) return setType("hangman");
    return setType("twoTruths");
  }, []);

  const handleValidate = (values, error, submitting, type) => {
    if (submitting !== isSubmitting) setIsSubmitting(submitting);
    switch (type) {
      case "hangman":
        return setValid(true);
      default:
        if (Object.keys(error).length > 0) return setValid(false);
        if (!values.truthOne) return setValid(false);
        if (!values.truthTwo) return setValid(false);
        if (!values.lie) return setValid(false);
        return setValid(true);
    }
  };

  return (
    <React.Fragment>
      <StyledDialogContent content="">
        {type === "twoTruths" ? (
          <TwoTruthsForm
            formRef={formRef}
            handleClick={handleClick}
            handleValidate={handleValidate}
          />
        ) : (
          <HangmanForm
            formRef={formRef}
            handleClick={handleClick}
            handleValidate={handleValidate}
          />
        )}
      </StyledDialogContent>
      <StyledDialogFooter
        text="Finish"
        color="primary"
        colorType="main"
        handleSubmit={onSubmit}
        disabled={!valid || isSubmitting}
        isSubmitting={isSubmitting}
      />
    </React.Fragment>
  );
};

StyledIntroPostDialog.propTypes = {
  handleClick: PropTypes.func,
};

export default StyledIntroPostDialog;
