/**
 * Copyright 2022 Loop Technology, Inc.
 */

export const apiStatus = (status) => {
  switch (status) {
    case "loading":
      return "loading";
    case "success":
      return "success";
    case "failed":
      return "failed";
    case "idle":
      return "idle";
    default:
      throw new Error("API Status is not defined.");
  }
};
