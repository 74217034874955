/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */

import {
  setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";

import {
  LOADING_REVIEWS,
  SET_REVIEWS_ERRORS,
  CLEAR_REVIEWS_ERRORS,
  LOADING_NOT_REVIEWED,
  SET_NOT_REVIEWED,
  SET_NOT_REVIEWED_ERRORS,
  CLEAR_NOT_REVIEWED_ERRORS,
  LOADING_IN_REVIEW,
  SET_IN_REVIEW,
  SET_IN_REVIEW_ERRORS,
  CLEAR_IN_REVIEW_ERRORS,
  LOADING_PAST_DECISIONS,
  SET_PAST_DECISIONS,
  SET_PAST_DECISIONS_ERRORS,
  CLEAR_PAST_DECISIONS_ERRORS,
  LOADING_LEADER_DECISION,
  SET_LEADER_DECISION,
  SET_LEADER_DECISION_ERRORS,
  CLEAR_LEADER_DECISION_ERRORS,
  ASSIGN_LEADER_TO_REVIEW,
} from "../actionTypes/leaderHubTypes";

import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

const initState = {
  reviews: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    active: {
      api: {
        status: apiStatus("idle"),
        error: {},
      },
      reviews: {},
      lastDate: null,
      lastId: null,
      lastUpdated: null,
      isEndOfReviews: null,
    },
    pending: {
      api: {
        status: apiStatus("idle"),
        error: {},
      },
      reviews: {},
      lastDate: null,
      lastId: null,
      lastUpdated: null,
      isEndOfReviews: null,
    },
    completed: {
      api: {
        status: apiStatus("idle"),
        error: {},
      },
      reviews: {},
      lastDate: null,
      lastId: null,
      lastUpdated: null,
      isEndOfReviews: null,
    },
  },
  // Federation
  // BlockedCommunities
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case LOADING_REVIEWS:
      return loadingBaseReducer(state, "reviews");
    case LOADING_NOT_REVIEWED:
      return loadingBaseReducer(state, "reviews", "active");
    case LOADING_IN_REVIEW:
      return loadingBaseReducer(state, "reviews", "pending");
    case LOADING_PAST_DECISIONS:
      return loadingBaseReducer(state, "reviews", "completed");
    case LOADING_LEADER_DECISION:
      return loadingBaseReducer(state, "reviews", "completed");

    // Success Types
    case SET_NOT_REVIEWED:
      return setBaseReducer(state, "reviews", "active", {
        l2: {
          reviews: { ...state.reviews.active.reviews, ...action.payload.data },
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate,
          isEndOfReviews: action.payload.isEndOfDocs,
          lastUpdated: new Date().toISOString(),
        },
      });
    case SET_IN_REVIEW:
      return setBaseReducer(state, "reviews", "pending", {
        l2: {
          reviews: { ...state.reviews.pending.reviews, ...action.payload.data },
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate,
          isEndOfReviews: action.payload.isEndOfDocs,
          lastUpdated: new Date().toISOString(),
        },
      });
    case SET_PAST_DECISIONS:
      return setBaseReducer(state, "reviews", "completed", {
        l2: {
          reviews: {
            ...state.reviews.completed.reviews,
            ...action.payload.data,
          },
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate,
          isEndOfReviews: action.payload.isEndOfDocs,
          lastUpdated: new Date().toISOString(),
        },
      });
    case ASSIGN_LEADER_TO_REVIEW:
      const { [action.payload.reviewId]: review, ...remainingNotStarted } =
        state.reviews.active.reviews;
      return setBaseReducer(state, "reviews", null, {
        l1: {
          active: {
            ...state.reviews.active,
            reviews: { ...remainingNotStarted },
            lastUpdated: new Date().toISOString(),
          },
          pending: {
            ...state.reviews.pending,
            reviews: {
              ...state.reviews.pending.reviews,
              [action.payload.reviewId]: { ...action.payload },
            },
            lastUpdated: new Date().toISOString(),
          },
        },
      });
    case SET_LEADER_DECISION:
      const { [action.payload.reviewId]: rvw, ...remainingInReview } =
        state.reviews.pending.reviews;
      return setBaseReducer(state, "reviews", null, {
        l1: {
          pending: {
            ...state.reviews.pending,
            reviews: { ...remainingInReview },
            lastUpdated: new Date().toISOString(),
          },
          completed: {
            ...state.reviews.completed,
            reviews: {
              ...state.reviews.completed.reviews,
              [action.payload.reviewId]: { ...action.payload },
            },
            lastUpdated: new Date().toISOString(),
          },
        },
      });

    // Failed Types
    case SET_REVIEWS_ERRORS:
      return setErrorsBaseReducer(state, "reviews", null, {
        error: action.payload,
      });
    case SET_NOT_REVIEWED_ERRORS:
      return setErrorsBaseReducer(state, "reviews", "active", {
        error: action.payload,
      });
    case SET_IN_REVIEW_ERRORS:
      return setErrorsBaseReducer(state, "reviews", "pending", {
        error: action.payload,
      });
    case SET_PAST_DECISIONS_ERRORS:
      return setErrorsBaseReducer(state, "reviews", "completed", {
        error: action.payload,
      });
    case SET_LEADER_DECISION_ERRORS:
      return setErrorsBaseReducer(state, "reviews", "completed", {
        error: action.payload,
      });

    // Idle Types
    case CLEAR_REVIEWS_ERRORS:
      return clearErrorsBaseReducer(state, "reviews");
    case CLEAR_NOT_REVIEWED_ERRORS:
      return clearErrorsBaseReducer(state, "reviews", "active");
    case CLEAR_IN_REVIEW_ERRORS:
      return clearErrorsBaseReducer(state, "reviews", "pending");
    case CLEAR_PAST_DECISIONS_ERRORS:
      return clearErrorsBaseReducer(state, "reviews", "completed");
    case CLEAR_LEADER_DECISION_ERRORS:
      return clearErrorsBaseReducer(state, "reviews", "completed");

    default:
      return state;
  }
}
