/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  LOADING_ACTIVE_PROFILE_COMMUNITY_MEMBERS,
  SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS,
  SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS,
  CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS,
  CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS,
} from "../../../../actionTypes/activeProfileTypes";

import { reduceQueryParams } from "../../../../../util/validators/reducers";

// Axios Instance
import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function queries firestore and gets
 * the active profiles community members  for the
 * active profiles members feed.
 *
 * @param {string} handle - the active profile community handle
 * @param {number} [limit] - the number of documents requested
 * @param {string} [lastDate] - The last document's date created
 * @param {string} [lastId] - The last document's id
 * @param {object} dispatch - The redux dispatch object
 */
export const getActiveProfileCommunityMembers =
  (handle = "", lastId = null, lastDate = null, limit = 6) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS });
    dispatch({ type: LOADING_ACTIVE_PROFILE_COMMUNITY_MEMBERS });
    if (!lastDate || !lastId)
      dispatch({ type: CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS });

    let params = { limit, handle };
    if (lastDate && lastId) {
      params.lastDate = lastDate;
      params.lastId = lastId;
    }
    const queryParams = reduceQueryParams(params);

    return await baseAxios
      .get(`/entity/community/members/${queryParams}`)
      .then(async (res) => {
        const { message, ...reduxData } = res.data;
        return await dispatch({
          type: SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS,
          payload: reduxData,
        });
      })
      .catch((error) => {
        return dispatch({
          type: SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS,
          payload: error ? (error.response ? error.response : error) : null,
        });
      });
  };
