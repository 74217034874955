/**
 * Copyright 2022 Loop Technology, Inc.
 */

// User Action Types
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";

//Core
export const SET_USER_CORE = "SET_USER_CORE";
export const SET_USER_CORE_DETAILS = "SET_USER_CORE_DETAILS";
export const SET_USER_PROFILE_IMAGE = "SET_USER_PROFILE_IMAGE";
export const SET_USER_BACKGROUND_IMAGE = "SET_USER_BACKGROUND_IMAGE";
export const SET_USER_CHARACTERISTICS = "SET_USER_CHARACTERISTICS";
export const SET_USER_HOMETOWN = "SET_USER_HOMETOWN";
export const SET_USER_RELATIONSHIP = "SET_USER_RELATIONSHIP";
export const SET_USER_WORKPLACE = "SET_USER_WORKPLACE";
export const SET_USER_HIGH_SCHOOL = "SET_USER_HIGH_SCHOOL";
export const SET_USER_COLLEGE = "SET_USER_COLLEGE";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_NEW_LAST_VOTE_DATE = "SET_NEW_LAST_VOTE_DATE";
export const LOADING_USER_CORE = "LOADING_USER_CORE";
export const SET_PHONE_DETAILS = "SET_PHONE_DETAILS";
export const SET_EMAIL_DETAILS = "SET_EMAIL_DETAILS";
export const SET_USER_CORE_ERRORS = "SET_USER_CORE_ERRORS";
export const CLEAR_USER_CORE_ERRORS = "CLEAR_USER_CORE_ERRORS";
export const SET_PROFILE_IMAGE_POSITION = "SET_PROFILE_IMAGE_POSITION";
export const SET_BACKGROUND_IMAGE_POSITION = "SET_BACKGROUND_IMAGE_POSITION";

//Community
export const CLEAR_USER_COMMUNITY = "CLEAR_USER_COMMUNITY";
export const SET_USER_COMMUNITY = "SET_USER_COMMUNITY";
export const LOADING_USER_COMMUNITY = "LOADING_USER_COMMUNITY";
export const SET_USER_COMMUNITY_ERRORS = "SET_USER_COMMUNITY_ERRORS";
export const CLEAR_USER_COMMUNITY_ERRORS = "CLEAR_USER_COMMUNITY_ERRORS";
//Notifications
export const SET_NEW_LAST_FRIEND_REQUEST_CLICK_DATE =
  "SET_NEW_LAST_FRIEND_REQUEST_CLICK_DATE";
export const SET_NEW_LAST_NOTIFICATION_CLICK_DATE =
  "SET_NEW_LAST_NOTIFICATION_CLICK_DATE";
//Friends
export const SET_USER_FRIENDS = "SET_USER_FRIENDS";
export const REMOVE_USER_FRIEND = "REMOVE_USER_FRIEND";
export const LOADING_USER_FRIENDS = "LOADING_USER_FRIENDS";
export const SET_USER_FRIENDS_ERRORS = "SET_USER_FRIENDS_ERRORS";
export const CLEAR_USER_FRIENDS_ERRORS = "CLEAR_USER_FRIENDS_ERRORS";
//Members
export const SET_COMMUNITY_MEMBERS = "SET_COMMUNITY_MEMBERS";
export const LOADING_COMMUNITY_MEMBERS = "LOADING_COMMUNITY_MEMBERS";
export const CLEAR_COMMUNITY_MEMBERS = "CLEAR_COMMUNITY_MEMBERS";
export const SET_COMMUNITY_MEMBERS_ERRORS = "SET_COMMUNITY_MEMBERS_ERRORS";
export const CLEAR_COMMUNITY_MEMBERS_ERRORS = "CLEAR_COMMUNITY_MEMBERS_ERRORS";
//Leaders
export const SET_COMMUNITY_LEADERS = "SET_COMMUNITY_LEADERS";
export const LOADING_COMMUNITY_LEADERS = "LOADING_COMMUNITY_LEADERS";
export const CLEAR_COMMUNITY_LEADERS = "CLEAR_COMMUNITY_LEADERS";
export const SET_COMMUNITY_LEADERS_ERRORS = "SET_COMMUNITY_LEADERS_ERRORS";
export const CLEAR_COMMUNITY_LEADERS_ERRORS = "CLEAR_COMMUNITY_LEADERS_ERRORS";
//Rules
export const SET_COMMUNITY_RULES = "SET_COMMUNITY_RULES";
export const LOADING_COMMUNITY_RULES = "LOADING_COMMUNITY_RULES";
export const SET_COMMUNITY_RULES_ERRORS = "SET_COMMUNITY_RULES_ERRORS";
export const CLEAR_COMMUNITY_RULES_ERRORS = "CLEAR_COMMUNITY_RULES_ERRORS";
export const SET_COMMUNITY_PROPOSED_RULES = "SET_COMMUNITY_PROPOSED_RULES";
//Coalitions
export const SET_USER_COALITIONS = "SET_USER_COALITIONS";
export const LOADING_USER_COALITIONS = "LOADING_USER_COALITIONS";
export const SET_USER_COALITIONS_ERRORS = "SET_USER_COALITIONS_ERRORS";
export const CLEAR_USER_COALITIONS_ERRORS = "CLEAR_USER_COALITIONS_ERRORS";
//Blocked
export const SET_UNBLOCK_USER = "SET_UNBLOCK_USER";
export const SET_BLOCK_USER = "SET_BLOCK_USER";
export const LOADING_BLOCKED_USERS = "LOADING_BLOCKED_USERS";
export const SET_BLOCKED_USERS_ERRORS = "SET_BLOCKED_USERS_ERRORS";
export const CLEAR_BLOCKED_USERS_ERRORS = "CLEAR_BLOCKED_USERS_ERRORS";
export const SET_BLOCKED_USERS = "SET_BLOCKED_USERS";

// Leader Actions
export const SET_LEADER_RESIGNATION = "SET_LEADER_RESIGNATION";
export const SET_LEADER_SUPERPOWER = "SET_LEADER_SUPERPOWER";
