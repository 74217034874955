/* eslint-disable import/no-anonymous-default-export */
import { SET_UI_ERRORS, CLEAR_UI_ERRORS, LOADING_UI, SET_UI } from "../actionTypes/uiTypes";

import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

const initState = {
  status: apiStatus("idle"),
  error: {},
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    case LOADING_UI:
      return {
        ...state,
        status: apiStatus("loading"),
      };
    case SET_UI:
      return {
        ...state,
        status: apiStatus("success"),
      };
    case SET_UI_ERRORS:
      return {
        status: apiStatus("failed"),
        error: action.payload,
      };
    case CLEAR_UI_ERRORS:
      return {
        status: apiStatus("idle"),
        error: {},
      };

    default:
      return state;
  }
}
