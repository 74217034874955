/**
 * Copyright 2022 Loop Technology, Inc.
 */

import * as yup from "yup";

const charactersiticsSchema = yup.object().shape({
  personality: yup.string().required("Must choose a personality"),
  identities: yup.array().min(1, "Must choose at least one identity"),
  lifestyles: yup
    .array()
    .min(1, "Must choose at least one lifestyle")
    .required(),
  activities: yup.array().min(1, "Must choose at least one activity"),
});

export default charactersiticsSchema;
