/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This helper function returns the analytics
 * event and the props for the given step
 *
 * @param {number} step the current step
 * @returns {object} the current step data
 */
export const getAnalyticsEventNameForStep = (step) => {
  switch (step) {
    case 0:
      return { eventName: "community-match__onboarding" };
    case 1:
      return { eventName: "upload-profile-photo__onboarding" };
    case 2:
      return { eventName: "set-characteristics__onboarding" };
    case 3:
      return { eventName: "introduction-post__onboarding" };
    default:
      return "";
  }
};
