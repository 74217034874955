/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components & Functions
import {
  PERSONALITIES,
  IDENTITIES,
  ACTIVITIES,
  LIFESTYLE,
} from "./getCharacteristicChips";
import CharactersticSection from "./charactersiticSection";

// Material UI
import { styled, Box } from "@mui/system";
import Grid from "@mui/material/Grid";

const StyledDiv = styled("div")(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

/**
 * This array holds the different types of
 * sections to be included in the vibes form
 */
const SECTIONS = [
  {
    ARRAY: PERSONALITIES,
    title: "Personality",
    subtitle: "Which best matches your personality?",
    arrayType: "personality",
    color: "secondary",
    colorType: "main",
  },
  {
    ARRAY: IDENTITIES,
    title: "Identities",
    subtitle: "Which identities define you?",
    arrayType: "identities",
    color: "primary",
    colorType: "main",
  },
  {
    ARRAY: LIFESTYLE,
    title: "Lifestyle",
    subtitle: "Which lifestyles do you fit into?",
    color: "error",
    arrayType: "lifestyles",
    colorType: "main",
  },
  {
    ARRAY: ACTIVITIES,
    title: "Hang Out",
    subtitle: "What activities do you like to do?",
    arrayType: "activities",
    color: "success",
    colorType: "main",
  },
];

const CharacteristicsFormSections = ({ values }) => {
  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      {SECTIONS.map((section, index) => (
        <Box textAlign="center" key={index}>
          <StyledDiv>
            <CharactersticSection
              values={values}
              array={section.ARRAY}
              arrayType={section.arrayType}
              title={section.title}
              subtitle={section.subtitle}
              color={section.color}
              colorType={section.colorType}
            />
          </StyledDiv>
        </Box>
      ))}
    </Grid>
  );
};

CharacteristicsFormSections.propTypes = {
  values: PropTypes.object.isRequired,
};

export default CharacteristicsFormSections;
