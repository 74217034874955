/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledDialogContent from "./styledDialogContent";
import StyledFormButton from "../../../UI/Forms/styledComponents/styledFormButton";
import {
  StyledDialogFooterDiv,
  StyledDialogFooterSection,
} from "./styledDialogFooterComponents";

// Material UI
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { alpha, Box } from "@mui/system";
import CircularProgress from "@mui/material/CircularProgress";

const StyledDialogFooter = ({
  text,
  handleSubmit,
  disabled,
  footerText = null,
  color = "success",
  colorType = "main",
  isSubmitting = false,
  secondaryButton = null,
  hasDivider = true,
  noBottomMargin = false,
}) => {
  return (
    <StyledDialogFooterSection>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        sx={{ py: 0.5 }}
        alignItems="center"
      >
        <StyledDialogFooterDiv noBottomMargin={noBottomMargin}>
          {hasDivider && (
            <Divider sx={{ width: "100%", borderColor: "grey.50" }} light />
          )}
          {footerText && <StyledDialogContent content={footerText} center />}
          <Grid item xs={12} sx={!footerText ? { mt: 1.5 } : {}}>
            <StyledFormButton
              color={color}
              colorType={colorType}
              onClick={handleSubmit ? handleSubmit : null}
              isSubmitting={isSubmitting}
              text={text}
              disabled={disabled || isSubmitting}
              type="submit"
            />
          </Grid>
          {secondaryButton && (
            <Grid item xs={12} sx={{ mt: 1, mb: 0.25 }}>
              <Box textAlign="center">
                <Button
                  sx={{
                    py: 1.5,
                    px: 3,
                    fontSize: 16,
                    color: `${secondaryButton.color}.${secondaryButton.colorType}`,
                    "&:hover": {
                      backgroundColor: (theme) =>
                        alpha(
                          theme.palette[secondaryButton.color][
                            secondaryButton.colorType
                          ],
                          0.06
                        ),
                    },
                  }}
                  disabled={
                    secondaryButton.disabled || secondaryButton.isSubmitting
                  }
                  onClick={secondaryButton?.handleSubmit ?? null}
                >
                  {secondaryButton.text}
                  {secondaryButton.isSubmitting && (
                    <CircularProgress
                      size={20}
                      sx={{
                        position: "absolute",
                        color: `${secondaryButton.color}.${secondaryButton.colorType}`,
                      }}
                    />
                  )}
                </Button>
              </Box>
            </Grid>
          )}
        </StyledDialogFooterDiv>
      </Grid>
    </StyledDialogFooterSection>
  );
};

StyledDialogFooter.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  handleSubmit: PropTypes.func,
  footerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  colorType: PropTypes.oneOf(["main", "dark", "light", "primary", "secondary"]),
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  secondaryButton: PropTypes.object,
  hasDivider: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  color: PropTypes.oneOf([
    "success",
    "primary",
    "secondary",
    "info",
    "warning",
    "error",
    "text",
  ]),
};

export default StyledDialogFooter;
