/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";

// Redux
import { connect } from "react-redux";
import { logoutUser } from "../../../../redux/actions/auth/atomic/post/logout";

// Components
import { StyledPrimary } from "../../Text/styledText";
import StyledBaseButton from "../../Buttons/styledBaseButton";

// Material UI
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/system";

const StyledAppError = ({ session: { authenticated = false }, logoutUser }) => {
  const theme = useTheme();
  document.body.style.backgroundColor = theme.palette.background.default;

  /**
   * This handler function either re-directs the user
   * if they are logged in or logs them out to clear
   * any uncaught auth issues.
   */
  const handleClearError = () => {
    authenticated ? (window.location.href = "/community") : logoutUser();
  };

  return (
    <Container maxWidth="lg" sx={{ mt: "25vh" }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h4" color="textPrimary" align="center">
            Something <StyledPrimary bold>broke</StyledPrimary>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="textPrimary"
            align="center"
            sx={{ my: 1.5 }}
          >
            No biggie 🤙 Let's help you find your way back home!
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ my: 3 }}>
          <StyledBaseButton
            text="Back to Loop"
            color="primary"
            fullWidth={false}
            onClick={handleClearError}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            color="textSecondary"
            align="center"
            sx={{ mx: 1.5 }}
          >
            Loop is not perfect, yet. We really appreciate you sticking with us
            as we work to make it awesome!
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapActionsToProps = {
  logoutUser,
};

export default connect(mapStateToProps, mapActionsToProps)(StyledAppError);
