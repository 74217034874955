/**
 * Copyright 2022 Loop Technology, Inc.
 */

import { logoutUser } from "../post/logout";
import { CLEAR_UI_ERRORS } from "../../../../actionTypes/uiTypes";
import { SET_USER_SESSION } from "../../../../actionTypes/sessionTypes";

import combinedStore from "../../../../store";
import { setAuthorizationHeaderHelper } from "../../../../../util/axios/setAuthHeader";

// Refresh Axios Instance
import refreshAxios from "../../../../../util/axios/Instances/refresh.instance";

const getAuthStatus = () => localStorage.getItem("auth");
const getBearerToken = () => localStorage.getItem("AuthToken");

/**
 * This action function goes through the process of refreshing the
 * user's authorization token using the refresh token cookie.
 *
 * @param {object} failedRequest - failed request object
 */
export const refreshAuthLogic = async (failedRequest) => {
  return await refreshAxios
    .get("/refresh")
    .then((res) => {
      setAuthorizationHeaderHelper(res.data.token);
      combinedStore.store.dispatch({
        type: SET_USER_SESSION,
        payload: res.data,
      });
      combinedStore.store.dispatch({ type: CLEAR_UI_ERRORS });
      failedRequest.response.config.headers[
        "Authorization"
      ] = `Bearer ${res.data.token}`;
    })
    .then(() => {
      return Promise.resolve();
    })
    .catch(() => {
      // log error
      const hasToken = getBearerToken() ? true : false;
      if (Boolean(getAuthStatus()) || hasToken) {
        sessionStorage.setItem("--slo", "true");
        combinedStore.store.dispatch(logoutUser(false, null, true));
      }
      return Promise.resolve();
    });
};
