const getRandomLogoHelper = () => {
  let rand = Math.random();
  if (rand < 0.33) return loop1;
  else if (rand >= 0.33 && rand < 0.67) return loop2;
  return loop3;
};

const loop1 = String.raw`
Loop Technology, Inc. ⓒ ${new Date().getFullYear()}
    __
   / /   ____  ____  ____
  / /   / __ \/ __ \/ __ \
 / /___/ /_/ / /_/ / /_/ /
/_____/\____/\____/ .___/
                 /_/    
                 
============================                
/   Find your Community    /
============================`;

const loop2 = String.raw`
    Loop Technology, Inc. ⓒ ${new Date().getFullYear()}

 ___       ________  ________  ________       
|\  \     |\   __  \|\   __  \|\   __  \      
\ \  \    \ \  \|\  \ \  \|\  \ \  \|\  \     
 \ \  \    \ \  \\\  \ \  \\\  \ \   ____\    
  \ \  \____\ \  \\\  \ \  \\\  \ \  \___|    
   \ \_______\ \_______\ \_______\ \__\       
    \|_______|\|_______|\|_______|\|__|
 
        ============================              
        /  Discover new adventures /
        ============================  
`;

const loop3 = String.raw`
Loop Technology, Inc. ⓒ ${new Date().getFullYear()}

██╗      ██████╗  ██████╗ ██████╗ 
██║     ██╔═══██╗██╔═══██╗██╔══██╗
██║     ██║   ██║██║   ██║██████╔╝
██║     ██║   ██║██║   ██║██╔═══╝ 
███████╗╚██████╔╝╚██████╔╝██║     
╚══════╝ ╚═════╝  ╚═════╝ ╚═╝  

  ============================                
  / Explore new Possiblities /
  ============================
`;

export const message = `${getRandomLogoHelper()}

Loop is made by small, but mighty team. We will
soon be looking for more curious minds like you 
to join our team. Up for a new adventure? 
`;
