/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Component
import StyledMemberSet from "../../../../FeatureSpecific/Members/styledMemberSet";
import StyledDialogFooter from "../../../Dialogs/styledComponents/styledDialogFooter";
import StyledDialogContent from "../../../Dialogs/styledComponents/styledDialogContent";
import { StyledSecondary, StyledSuccess } from "../../../Text/styledText";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const StyledCommunityMatch = ({
  isMobile,
  handleClick,
  DIALOG_CONTENT,
  userCount = null,
  communityName = "The Moon: 1",
  authUserIsSeed = true,
  leaderCount = null,
}) => {
  return (
    <React.Fragment>
      <StyledDialogContent content={DIALOG_CONTENT.content}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} sx={{ my: 3 }}>
            <Typography variant="h4" align="center">
              {communityName.split(":")[0]}:{"  "}
              {authUserIsSeed ? (
                <StyledSuccess bold>
                  {communityName.split(":")[1]}
                </StyledSuccess>
              ) : (
                <StyledSecondary bold>
                  {communityName.split(":")[1]}
                </StyledSecondary>
              )}
            </Typography>
          </Grid>
          {!authUserIsSeed ? (
            <React.Fragment>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 1 }}
              >
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    align="center"
                    sx={{ fontWeight: 400 }}
                  >
                    {userCount} members
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    align="center"
                    sx={{ fontWeight: 400 }}
                  >
                    {leaderCount === 0 ? "No" : leaderCount} {"  "} leaders
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ my: 1 }}
              >
                <StyledMemberSet
                  isMobile={isMobile}
                  isDialog={true}
                  type="user"
                  wrap={false}
                  centered={false}
                />
              </Grid>
            </React.Fragment>
          ) : (
            <Grid item xs={11}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                align="center"
              ></Typography>
            </Grid>
          )}
        </Grid>
      </StyledDialogContent>
      <StyledDialogFooter
        text="Next"
        color={DIALOG_CONTENT.buttonColor}
        colorType={DIALOG_CONTENT.buttonColorType}
        handleSubmit={handleClick}
      />
    </React.Fragment>
  );
};

StyledCommunityMatch.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  communityName: PropTypes.string,
  authUserIsSeed: PropTypes.bool,
  userCount: PropTypes.number,
  leaderCount: PropTypes.number,
  handleClick: PropTypes.func,
  DIALOG_CONTENT: PropTypes.object,
};

const mapStateToProps = (state) => ({
  communityName: state.user.core.community.communityName,
  authUserIsSeed: state.user.core.community.isSeed,
  userCount: state.user.community.userCount,
  leaderCount: state.user.community.leaderCount,
});

export default connect(mapStateToProps)(StyledCommunityMatch);
