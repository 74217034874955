/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BaseCharacterTraitChip from "../../UI/Chip/baseCharacterTraitChip";

// Material UI
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Component
const StyledUserDetailsMenuDescription = ({
  type,
  characteristics,
  activities = [],
  lifestyles = [],
  isLeader = false,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ mt: 0.5 }}
    >
      <Grid item xs={12} sx={{ mb: 0.5 }}>
        <Box textAlign="start">
          {activities.map((activity, index) => (
            <React.Fragment key={index}>
              <BaseCharacterTraitChip
                label={activity}
                active={
                  type !== "user" &&
                  characteristics.activities.includes(activity)
                }
                isSmall
              />
            </React.Fragment>
          ))}
          {lifestyles.map((lifestyle, index) => (
            <React.Fragment key={index}>
              <BaseCharacterTraitChip
                label={lifestyle}
                active={
                  type !== "user" &&
                  characteristics.lifestyles.includes(lifestyle)
                }
                isSmall
              />
            </React.Fragment>
          ))}
        </Box>
      </Grid>
      {isLeader && (
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            align="center"
            sx={{ mt: 0.5 }}
          >
            🦸 Community Leader
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

StyledUserDetailsMenuDescription.propTypes = {
  isLeader: PropTypes.bool,
  activities: PropTypes.array,
  lifestyles: PropTypes.array,
  characteristics: PropTypes.object,
  type: PropTypes.oneOf(["user", "people"]),
};

export default StyledUserDetailsMenuDescription;
