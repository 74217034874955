/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from 'react';

// Material UI
import Fade from "@mui/material/Fade";

export const FadeTransition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} mountOnEnter unmountOnExit />;
});
