/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

// Form
import BaseForm from "../../Forms/baseForm";
import searchSchema from "./validationSchema/search.schema";
import { useFormikContext, getIn } from "formik";
import StyledAppSearchField from "../../Forms/styledComponents/styledAppSearchField";

// Components & hooks
import BaseDialog from "../baseDialog";
import useAlgoliaSearch from "../../../../hooks/useAlgoliaSearch";
import StyledDialogContent from "../styledComponents/styledDialogContent";
import BaseUserSearchList from "../../Lists/styledLists/baseUserSearchList";
import BaseRecentSearchesList from "../../Lists/styledLists/baseRecentSearchesList";
import StyledDialogTextFooter from "../styledComponents/styledDialogTextFooter";
import { getRecentlySearched } from "../../../../functions/getRecentlySearched";
import { StyledSecondary } from "../../Text/styledText";

// Material UI
import Typography from "@mui/material/Typography";

const StyledAppSearchDialog = ({ open, isTabletDown, handleClick }) => {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const handleClose = () => handleClick();
  let { results, loading, empty } = useAlgoliaSearch(query, 0);
  const { recents, recentHandles } = getRecentlySearched();

  /**
   * This helper component auto-detects if there is
   * a match between the values in the form and toggles
   * the accepted State
   * @returns
   */
  const AutoDetectMatch = () => {
    const { values } = useFormikContext();
    const value = getIn(values, "query");
    useEffect(() => {
      if (value && value.length > 0) setQuery(value);
      else setQuery("");
    }, [value]);
    return null;
  };

  /**
   * This helper function handles the navigation
   * from the search or tag menu to the profile
   * onClick.
   *
   * @param {object} e - the event object
   * @param {string} handle - the profile handle
   */
  const handleNavigate = (e, handle) => navigate(`/profile/${handle}`);

  // Base dialog styles
  const baseStyles = { width: 550, maxWidth: 550, minHeight: 400 };

  return (
    <BaseDialog
      open={open}
      isMobile={isTabletDown}
      isTablet={isTabletDown}
      onClose={handleClose}
      PaperSxProps={
        isTabletDown ? { ...baseStyles } : { ...baseStyles, height: 400 }
      }
      aria-labelledby="Loop Platform - App Search Dialog"
    >
      <BaseForm
        data={{ query: "" }}
        handleSubmit={() => ({})}
        validationSchema={searchSchema}
        formRef={formRef}
        noMargin
      >
        <StyledAppSearchField
          color="secondary"
          name="query"
          placeholder="Search for someone..."
          handleClose={handleClick}
        />
        <AutoDetectMatch />
      </BaseForm>
      <StyledDialogContent content="">
        {!query && query.length === 0 && (
          <BaseRecentSearchesList
            recents={recents}
            recentHandles={recentHandles}
            handleSearchClick={handleNavigate}
            isSearch
            isDialog
          />
        )}
        {query && query.length > 0 && (
          <React.Fragment>
            <Typography>Results</Typography>
            <BaseUserSearchList
              empty={empty}
              isDialog={true}
              results={results}
              loading={loading}
              recents={recents}
              recentHandles={recentHandles}
              handleSearchClick={handleNavigate}
              isSearch
            />
          </React.Fragment>
        )}
      </StyledDialogContent>
      <StyledDialogTextFooter
        footerText={
          <span>
            Loop Search is in <StyledSecondary>Beta</StyledSecondary>
          </span>
        }
      />
    </BaseDialog>
  );
};

StyledAppSearchDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  isTabletDown: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default React.memo(StyledAppSearchDialog);
