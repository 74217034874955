/**
 * Copyright 2022 Loop Technology, Inc.
 */


export const PERSONALITIES = [
  {
    label: "Strategically Imaginative",
    icon: "👷‍♂️",
  },
  {
    label: "Courageously Bold",
    icon: "🪖",
  },
  {
    label: "Unboundedly Free",
    icon: "🎒",
  },
  {
    label: "Mystically Quiet",
    icon: "🧙‍♂️",
  },
  {
    label: "Eagerly Social",
    icon: "👋",
  },
  {
    label: "Charismatically Inspiring",
    icon: "🦸‍♀️",
  },
  {
    label: "Insistently Precise",
    icon: "🔬",
  },
  {
    label: "Dutifully Virtious",
    icon: "👩‍✈️",
  },
  {
    label: "Warmingly Protective",
    icon: "👨‍🍼",
  },
  {
    label: "Poeticly Kind",
    icon: "😌",
  },
  {
    label: "Extraordinarily Caring",
    icon: "⛑",
  },
  {
    label: "Creatively Curious",
    icon: "🧑‍🚀",
  },
  {
    label: "Charmingly Artistic",
    icon: "🎨",
  },
  {
    label: "Laid back & Chill",
    icon: "🤙",
  },
  {
    label: "Enthusiastically Energetic",
    icon: "💁‍♀️",
  },
  {
    label: "Dangerously Adventerous",
    icon: "🧗‍♀️",
  },
];

// export const PERSONALITIES_SHORT = [
//   {
//     label: "Imaginative",
//     icon: "👷‍♂️",
//   },
//   {
//     label: "Bold",
//     icon: "🪖",
//   },
//   {
//     label: "Free",
//     icon: "🎒",
//   },
//   {
//     label: "Quiet",
//     icon: "🧙‍♂️",
//   },
//   {
//     label: "Social",
//     icon: "👋",
//   },
//   {
//     label: "Inspiring",
//     icon: "🦸‍♀️",
//   },
//   {
//     label: "Precise",
//     icon: "🔬",
//   },
//   {
//     label: "Virtious",
//     icon: "👩‍✈️",
//   },
//   {
//     label: "Protective",
//     icon: "👨‍🍼",
//   },
//   {
//     label: "Kind",
//     icon: "😌",
//   },
//   {
//     label: "Caring",
//     icon: "⛑",
//   },
//   {
//     label: "Curious",
//     icon: "🧑‍🚀",
//   },
//   {
//     label: "Artistic",
//     icon: "🎨",
//   },
//   {
//     label: "Laid Back",
//     icon: "🤙",
//   },
//   {
//     label: "Energetic",
//     icon: "💁‍♀️",
//   },
//   {
//     label: "Adventerous",
//     icon: "🧗‍♀️",
//   },
// ];

export const IDENTITIES = [
  {
    label: "From The Country",
    icon: "🌽",
  },
  {
    label: "LGBTQIA+",
    icon: "🏳️‍🌈",
  },
  {
    label: "City Slicker",
    icon: "🌆",
  },
  {
    label: "Suburban Dweller",
    icon: "🏡",
  },
  {
    label: "Wanderer",
    icon: "🎪",
  },
  {
    label: "Townie",
    icon: "🏘",
  },
  {
    label: "Millennial",
    icon: "👻",
  },
  {
    label: "Gen X",
    icon: "👖",
  },
  {
    label: "Gen Z",
    icon: "🥸",
  },
  {
    label: "Baby Boomer",
    icon: "🧨",
  },
];

export const LIFESTYLE = [
  {
    label: "Just Moved Here",
    icon: "🚚",
  },
  {
    label: "Artsy",
    icon: "🎭",
  },
  {
    label: "Postive Vibes Only",
    icon: "✌️",
  },
  {
    label: "Parenting",
    icon: "🍼",
  },
  {
    label: "Fashion",
    icon: "👖",
  },
  {
    label: "Relationships",
    icon: "❤️",
  },
  {
    label: "Tech",
    icon: "📱",
  },
  {
    label: "Friendships",
    icon: "🤝",
  },
  {
    label: "Sustainability",
    icon: "🌱",
  },
  {
    label: "Support",
    icon: "👍",
  },
  {
    label: "Fitness",
    icon: "💪",
  },
  {
    label: "Traveling",
    icon: "✈️",
  },
  {
    label: "Science",
    icon: "🔬",
  },
  {
    label: "Personal Growth",
    icon: "🙂",
  },
  {
    label: "Community Activism",
    icon: "🌲",
  },
  {
    label: "Faith",
    icon: "🕌",
  },
];

export const ACTIVITIES = [
  {
    label: "Game Nights",
    icon: "🃏",
  },
  {
    label: "Deep Discussions",
    icon: "🤔",
  },
  {
    label: "Painting",
    icon: "🎨",
  },
  {
    label: "Yoga",
    icon: "🧘",
  },
  {
    label: "Learning new things",
    icon: "📚",
  },
  {
    label: "Exercise",
    icon: "👟",
  },
  {
    label: "Bars & Drinks",
    icon: "🍻",
  },
  {
    label: "Volunteering",
    icon: "🧹",
  },
  {
    label: "Outdoors & Nature",
    icon: "🏕",
  },
  {
    label: "Gaming",
    icon: "🎮",
  },
  {
    label: "Shopping",
    icon: "👗",
  },
  {
    label: "Sports",
    icon: "⚽️",
  },
  {
    label: "Exploring & Travel",
    icon: "🔭",
  },
  {
    label: "Beaches & Boating",
    icon: "🏝",
  },
  {
    label: "Food & Cook Outs",
    icon: "🍱",
  },
  {
    label: "Music",
    icon: "🎸",
  },
  {
    label: "Comics & Anime",
    icon: "💥",
  },
];
