/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { getCommunityMembers } from "../../../redux/actions/user/atomic/get/getCommunityMembers";
import { getActiveProfileCommunityMembers } from "../../../redux/actions/activeProfile/atomic/get/getActiveProfileCommunityMembers";

// Components & Functions
import StyledUserAvatar from "../../UI/Avatars/styledAvatars/styledUserAvatar";
import StyledPersonAvatar from "../../UI/Avatars/styledAvatars/styledPersonAvatar";
import { getMemberSetDetailsHelper } from "./getMemberSetDetailsHelper";

// Material UI
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";

const StyledMemberList = ({
  type,
  status,
  isMobile,
  isTablet,
  lastId,
  lastDate,
  userHandle,
  communityHandle,
  getCommunityMembers,
  userCommunityHandle,
  getActiveProfileCommunityMembers,
  MEMBERS = {},
}) => {
  const isTiny = useMediaQuery("(max-width:340px)");
  const isMidMobile = useMediaQuery("(max-width:385px)");
  const isSmallTablet = useMediaQuery("(max-width:575px)");
  const isSmallDesktop = useMediaQuery(
    "(min-width:768px) and (max-width:930px)"
  );
  let skeletonArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  let { limit, spacing, avatarSize } = getMemberSetDetailsHelper(
    isTiny,
    isMidMobile,
    isMobile,
    isSmallTablet,
    isTablet,
    isSmallDesktop
  );

  useEffect(() => {
    if (type !== "user") {
      if (
        communityHandle === userCommunityHandle &&
        Object.keys(MEMBERS).length === 0
      )
        fetchMembersHelper(type, communityHandle);
      else if (type !== "user" && communityHandle !== userCommunityHandle)
        fetchMembersHelper(type, communityHandle);
    }
  }, [type, communityHandle]);

  /**
   * This helper function fetches more members and adds
   * them to the redux feed.
   *
   * @param {string} type - the profile type
   * @param {string} communityHandle - the community handle
   * @param {number} limit - the number of members to return
   * @returns {function} - the action function with the correct parameters
   */
  const fetchMembersHelper = useCallback(
    (type, communityHandle, lastId, lastDate, limit = 10) =>
      type === "user"
        ? getCommunityMembers(lastId, lastDate, limit)
        : getActiveProfileCommunityMembers(
            communityHandle,
            lastId,
            lastDate,
            limit
          ),
    [type, communityHandle, lastId, lastDate, limit]
  );

  /**
   * This handler call back function dispatches the
   * pagination helper.
   */
  let handlePagination = useCallback(
    (e, type, communityHandle, lastId, lastDate, limit) =>
      lastId && lastDate
        ? fetchMembersHelper(type, communityHandle, lastId, lastDate, limit)
        : null,
    [fetchMembersHelper]
  );

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ mt: isMobile ? 0.5 : 0, maxWidth: "600px" }}
    >
      {status === "loading" && Object.keys(MEMBERS).length === 0 ? (
        <React.Fragment>
          {skeletonArray.map((_, index) => (
            <Skeleton
              key={`community-member-skeleton-${index}`}
              variant="circular"
              width={avatarSize * 9.5}
              height={avatarSize * 9.5}
              sx={{ my: 1, mx: spacing }}
            />
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {Object.keys(MEMBERS).map((member) => (
            <Grid item sx={{ my: 1, mx: spacing }} key={MEMBERS[member].handle}>
              {member === userHandle ? (
                <StyledUserAvatar
                  height={avatarSize}
                  width={avatarSize}
                  isMenuButton
                />
              ) : (
                <StyledPersonAvatar
                  height={avatarSize}
                  width={avatarSize}
                  image={MEMBERS[member].userImage}
                  imageSet={MEMBERS[member]?.imageUrlThumbs ?? {}}
                  firstName={MEMBERS[member].firstName}
                  lastName={MEMBERS[member].lastName}
                  handle={MEMBERS[member].handle}
                  communityHandle={MEMBERS[member].communityHandle}
                  isLeader={MEMBERS[member].isLeader}
                  pronouns={MEMBERS[member]?.pronouns ?? ""}
                  lifestyles={
                    MEMBERS[member]?.characteristics?.lifestyles ?? []
                  }
                  activities={
                    MEMBERS[member]?.characteristics?.activities ?? []
                  }
                  isMember={communityHandle === userCommunityHandle}
                  isMenuButton
                />
              )}
            </Grid>
          ))}
          {status === "loading" && Object.keys(MEMBERS).length > 0 && (
            <React.Fragment>
              {skeletonArray.map((_, index) => (
                <Skeleton
                  key={`community-member-skeleton-${index}`}
                  variant="circular"
                  width={avatarSize * 9.5}
                  height={avatarSize * 9.5}
                  sx={{ my: 1, mx: spacing }}
                />
              ))}
            </React.Fragment>
          )}
          {lastId && lastDate && (
            <Grid xs={12} item sx={{ my: 1 }}>
              <Box textAlign="center">
                <Button
                  onClick={(e) =>
                    handlePagination(
                      e,
                      type,
                      communityHandle,
                      lastId,
                      lastDate,
                      limit
                    )
                  }
                  color="secondary"
                  sx={{ py: 1.5, px: 3 }}
                >
                  Load More Members
                </Button>
              </Box>
            </Grid>
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};

StyledMemberList.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool,
  communityHandle: PropTypes.string,
  MEMBERS: PropTypes.object,
  lastId: PropTypes.string,
  lastDate: PropTypes.string,
  isDialog: PropTypes.bool,
  userHandle: PropTypes.string,
  getCommunityMembers: PropTypes.func,
  getActiveProfileCommunityMembers: PropTypes.func,
  type: PropTypes.oneOf(["user", "people", "community"]).isRequired,
  status: PropTypes.oneOf(["idle", "success", "failed", "loading"]).isRequired,
};

const mapStateToProps = (state, props) => {
  let type = props.type === "user" ? "user" : "activeProfile";
  return {
    status: state[type].community.members.api.status,
    userHandle: type === "user" ? state[type].core.handle : null,
    communityHandle: state[type].core.community.communityHandle,
    userCommunityHandle: state.user.community.communityHandle,
    MEMBERS: state[type].community.members.members,
    lastId: state[type].community.members.lastId,
    lastDate: state[type].community.members.lastDate,
  };
};

const mapActionsToProps = {
  getCommunityMembers,
  getActiveProfileCommunityMembers,
};

export default connect(mapStateToProps, mapActionsToProps)(StyledMemberList);


