/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// Material UI
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const StyledListItem = ({ type, text, icon, route, handleClick }) => {
  return (
    <ListItem
      sx={{ borderRadius: 1, width: "auto", mx: 1, my: 0.5 }}
      component={type === "nav" ? NavLink : type}
      to={type === "nav" ? route : null}
      href={type === "a" ? route : null}
      onClick={type === "button" ? handleClick : null}
      target="_self"
      referrerPolicy="strict-origin-when-cross-origin"
      button
    >
      <ListItemText
        primary={
          <span>
            {icon} {text}
          </span>
        }
        primaryTypographyProps={{
          color: "textSecondary",
          variant: "h6",
          sx: { fontWeight: 400 },
        }}
      />
    </ListItem>
  );
};

StyledListItem.propTypes = {
  type: PropTypes.oneOf(["nav", "button", "a"]),
  text: PropTypes.string.isRequired,
  icon: PropTypes.node,
  route: PropTypes.string,
  handleClick: PropTypes.func,
};

export default StyledListItem;
