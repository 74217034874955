/**
 * * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";

// Formik
import { useField } from "formik";

//Components
import StyledRadio from "./styledRadio";

// Material UI
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// Component
const StyledRadioGroup = ({
  title,
  formLabels = [],
  required = false,
  color = "secondary",
  isStack,
  isMobile,
  ...props
}) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : null;

  return (
    <FormControl component="fieldset" fullWidth sx={{ mt: 1.5, width: "100%" }}>
      <FormLabel component="label" focused={false}>
        {title}
      </FormLabel>
      <RadioGroup
        {...field}
        sx={{ mt: 0.5, mb: 2 }}
        error={errorText ? true : undefined}
        row={!isMobile ? true : false}
      >
        {formLabels.map((label, index) => (
          <React.Fragment key={index}>
            <FormControlLabel
              value={label.value}
              control={
                <StyledRadio color={label.color} colorType={label.colorType} />
              }
              label={label.label}
              sx={{
                width:
                  isMobile || isStack
                    ? "100%"
                    : "auto",
                color: "text.secondary",
              }}
            />
          </React.Fragment>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default StyledRadioGroup;
