/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */

import {
  setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";
import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

import {
  // Create Post
  SET_NEW_POST,
  SET_NEW_POST_ERRORS,
  UPLOADING_NEW_POST,
  CLEAR_NEW_POST_ERRORS,
  // Create Comment
  SET_NEW_COMMENT,
  SET_NEW_COMMENT_ERRORS,
  UPLOADING_NEW_COMMENT,
  CLEAR_NEW_COMMENT_ERRORS,
} from "../actionTypes/contentTypes";

const initState = {
  newPost: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    postId: null,
  },
  newComment: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    postId: null,
    parentCommentId: null,
  },
  newAdventure: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    adventureId: null,
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;

    // Loading Types
    case UPLOADING_NEW_POST:
      return loadingBaseReducer(state, "newPost");
    case UPLOADING_NEW_COMMENT:
      return loadingBaseReducer(state, "newComment");
    // Success Types
    case SET_NEW_POST:
      return setBaseReducer(state, "newPost", null, {
        l1: { postId: action.payload.postId },
      });
    case SET_NEW_COMMENT:
      return setBaseReducer(state, "newComment", null, {
        l1: {
          postId: action.payload.postId,
          parentCommentId: action.payload.parentCommentId,
        },
      });

    // Failed Types
    case SET_NEW_POST_ERRORS:
      return setErrorsBaseReducer(state, "newPost", null, {
        error: action.payload,
      });
    case SET_NEW_COMMENT_ERRORS:
      return setErrorsBaseReducer(state, "newComment", null, {
        error: action.payload,
      });

    // Idle Types
    case CLEAR_NEW_POST_ERRORS:
      return clearErrorsBaseReducer(state, "newPost");
    case CLEAR_NEW_COMMENT_ERRORS:
      return clearErrorsBaseReducer(state, "newComment");
    default:
      return state;
  }
}
