/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";

const StyledDialogTextFooter = ({
  color = "text",
  colorType = "secondary",
  footerText = null,
}) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      sx={{ width: "100%", my: 1 }}
      alignItems="center"
    >
      <Divider sx={{ width: "100%", borderColor: "grey.50" }} />
      <Box
        sx={{
          py: 0.5,
          mt: 1,
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          align="center"
          sx={{ color: (theme) => theme.palette[color][colorType] }}
        >
          {footerText}
        </Typography>
      </Box>
    </Grid>
  );
};

StyledDialogTextFooter.propTypes = {
  footerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default StyledDialogTextFooter;
