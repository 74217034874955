/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Material UI
import { styled } from "@mui/system";

export const StyledDialogFooterSection = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1.5),
}));

export const StyledDialogFooterDiv = styled("div", {
  shouldForwardProp: (prop) => prop !== "noBottomMargin",
})(({ theme, noBottomMargin }) => ({
  marginBottom: noBottomMargin ? 0 : theme.spacing(1),
  width: "100%",
}));
