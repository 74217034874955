/**
 * Copyright 2022 Loop Technology, Inc.
 */

import axios from "axios";

const unAuthAxios = axios.create({
  baseURL: "https://api.looop.cool",
});

export default unAuthAxios;
