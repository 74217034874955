/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React from "react";
import PropTypes from "prop-types";

// Components
import BaseBoundary from "../baseBoundary";
import StyledMemberSetError from "../StyledFallbacks/styledMemberSetError";

const StyledMemberBoundary = ({ fetchMembersHelper, children }) => {
  return (
    <BaseBoundary
      fallback={StyledMemberSetError}
      onReset={() => fetchMembersHelper()}
    >
      {children}
    </BaseBoundary>
  );
};

StyledMemberBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  fetchMembersHelper: PropTypes.func.isRequired,
};

export default StyledMemberBoundary;
