/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";

// Material UI
import LinearProgress from "@mui/material/LinearProgress";

const StyledLinearProgress = ({ color = "secondary", ...props }) => {
  return (
    <LinearProgress
      {...props}
      sx={{
        mt: 1,
        height: 3,
        borderRadius: 1.5,
        width: "100%",
        backgroundColor: (theme) =>
          theme.palette.grey[theme.palette.mode === "light" ? 100 : 800],
        "& .MuiLinearProgress-bar": {
          backgroundColor: (theme) => theme.palette[color].main,
        },
      }}
    />
  );
};

export default StyledLinearProgress;
