/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BaseDialog from "../baseDialog";

// Material UI
import StyledDialogHeader from "../styledComponents/styledDialogHeader";

const StyledOnboardingDialog = ({
  title,
  isMobile,
  isTablet,
  progress,
  color,
  ariaLabelledby,
  PaperProps = {},
  PaperSxProps = {},
  children,
}) => {
  /**
   * This handler function prevents close on
   * backdrop click.
   *
   * @param {object} e - the event object
   * @param {string} reason - the reason code
   */
  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      handleClose(e, reason);
    }
  };

  return (
    <BaseDialog
      open={true}
      isMobile={isMobile}
      isTablet={isTablet}
      onClose={handleClose}
      aria-labelledby={ariaLabelledby}
      PaperProps={{ ...PaperProps }}
      PaperSxProps={{ ...PaperSxProps }}
      disableEscapeKeyDown
    >
      <StyledDialogHeader
        title={title}
        color={color}
        hasButton={false}
        progress={progress}
      />
      {children}
    </BaseDialog>
  );
};

StyledOnboardingDialog.propTypes = {
  children: PropTypes.node,
  progress: PropTypes.number,
  PaperProps: PropTypes.object,
  PaperSxProps: PropTypes.object,
  ariaLabelledby: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default StyledOnboardingDialog;
