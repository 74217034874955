/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React from "react";
import { Navigate, useParams } from "react-router-dom";

// Components
import NotFound from "../../404";
import ProtectedComponent from "../RouteComponents/protectedComponent";

// Checks if user is authenticated
const getAuthStatus = () => localStorage.getItem("auth");

const PostRoute = ({
  component,
  isAuthenticated = Boolean(getAuthStatus()),
}) => {
  const params = useParams();
  return !isAuthenticated ? (
    <Navigate to="/" />
  ) : getValidPostIdHelper(params.postId) ? (
    <ProtectedComponent component={component} postId={params.postId} />
  ) : (
    <NotFound />
  );
};

export default React.memo(PostRoute);

/**
 * This helper function checks if the postId is
 * likely a real post id.
 *
 * @param {string} postId
 * @returns either null or the possible postId
 */
const getValidPostIdHelper = (postId) => {
  let re = /^[a-z0-9]+$/i;
  if (postId.length !== 20) return null;
  if (!re.test(postId)) return null;
  return postId;
};
