/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// Components
import BaseAvatar from "./baseAvatar";
import StyledUserDetailsMenu from "../../Menus/StyledMenus/styledUserDetailsMenu";

// Component
const StyledPersonAvatar = ({
  image,
  imageSet,
  width = 5,
  height = 5,
  handle = "",
  lastName = "",
  pronouns = "",
  firstName = "",
  lifestyles = [],
  activities = [],
  isLeader = false,
  isMember = false,
  isFriend = false,
  communityHandle = null,
  isMenuButton = false,
  isNavButton = false,
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * This handler function opens the
   * user profile menu
   *
   * @param {object} event the event object
   */
  const handleClick = useCallback(
    (event) => {
      return isMenuButton
        ? setAnchorEl(event.currentTarget)
        : isNavButton
        ? navigate(`/profile/${handle}`)
        : null;
    },
    [navigate, handle, isMenuButton, isNavButton]
  );

  /**
   * This handler function closes the
   * user profile menu
   */
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <React.Fragment>
      <BaseAvatar
        type="people"
        image={image}
        height={height}
        width={width}
        isMember={isMember}
        isFriend={isFriend}
        imageSet={imageSet}
        onClick={handleClick}
        button={(isMenuButton || isNavButton)}
      />
      {anchorEl && isMenuButton && (
        <StyledUserDetailsMenu
          handle={handle}
          anchorEl={anchorEl}
          firstName={firstName}
          lastName={lastName}
          pronouns={pronouns}
          isLeader={isLeader}
          lifestyles={lifestyles}
          activities={activities}
          communityHandle={communityHandle}
          handleMenuClose={handleMenuClose}
        />
      )}
    </React.Fragment>
  );
};

StyledPersonAvatar.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  image: PropTypes.string,
  imageSet: PropTypes.object,
  handle: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  pronouns: PropTypes.string,
  communityHandle: PropTypes.string,
  lifestyles: PropTypes.array,
  activities: PropTypes.array,
  isLeader: PropTypes.bool,
  button: PropTypes.bool,
};

export default StyledPersonAvatar;
