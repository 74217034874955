/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/system";

const BaseCharacterTraitChip = ({
  active,
  label,
  icon,
  isSmall,
  noMargins,
}) => {
  const avatar = icon && (
    <Avatar
      sx={{
        "&.MuiAvatar-colorDefault": {
          backgroundColor: "transparent",
        },
      }}
    >
      {icon}
    </Avatar>
  );

  return (
    <Chip
      size={isSmall ? "small" : "medium"}
      variant="outlined"
      sx={{
        borderSize: "1px",
        borderStyle: "solid",
        borderColor: active
          ? "secondary.main"
          : (theme) =>
              theme.palette.mode === "dark"
                ? alpha(theme.palette.grey[100], 0)
                : theme.palette.grey[50],
        backgroundColor: active
          ? "secondary.main"
          : (theme) =>
              theme.palette.mode === "dark"
                ? alpha(theme.palette.grey[100], 0.65)
                : theme.palette.grey[50],
        my: noMargins ? 0 : 0.5,
        mx: noMargins ? 0 : 0.25,
      }}
      avatar={avatar}
      label={
        <Typography
          color="textPrimary"
          variant="body2"
          sx={{
            py: 0.5,
            fontSize: 13.5,
            fontWeight: 400,
            color: active
              ? "white"
              : (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.background.default
                    : theme.palette.text.primary,
          }}
        >
          {label}
        </Typography>
      }
    />
  );
};

BaseCharacterTraitChip.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  isSmall: PropTypes.bool,
  active: PropTypes.bool,
  noMargins: PropTypes.bool,
};

export default BaseCharacterTraitChip;
