/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { lazy, useEffect } from "react";
import PropTypes from "prop-types";

// Material UI
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

// Lazy Loaded Imports
const StyledIconButton = lazy(() =>
  import("../../UI/Buttons/styledIconButton")
);
const StyledUserAvatar = lazy(() =>
  import("../../UI/Avatars/styledAvatars/styledUserAvatar")
);
const StyledPersonAvatar = lazy(() =>
  import("../../UI/Avatars/styledAvatars/styledPersonAvatar")
);

const StyledAvatarSet = ({
  status,
  limit,
  spacing,
  cursors,
  avatarSize,
  userHandle,
  blankMembers,
  isEndOfPages,
  isStartOfPages,
  handlePagination,
  MEMBERS = {},
}) => {
  useEffect(() => {
    if (status === "failed") throw new Error();
  }, [status]);

  return (
    <React.Fragment>
      {!isStartOfPages ? (
        <StyledIconButton
          size="small"
          color="transparent"
          onClick={(e) => handlePagination(e, limit, false)}
          sxProps={{ p: 0 }}
        >
          <ChevronLeftRoundedIcon color="secondary" />
        </StyledIconButton>
      ) : (
        <Box sx={{ minWidth: "24px", height: "24px" }} />
      )}
      {Object.keys(MEMBERS)
        .slice(cursors[0], cursors[1])
        .map((member) => (
          <Grid
            item
            sx={{ my: 1, mx: spacing }}
            key={`community-member-${MEMBERS[member].handle}`}
          >
            {member === userHandle ? (
              <StyledUserAvatar
                height={avatarSize}
                width={avatarSize}
                isMenuButton
              />
            ) : (
              <StyledPersonAvatar
                height={avatarSize}
                width={avatarSize}
                image={MEMBERS[member].userImage}
                imageSet={MEMBERS[member]?.imageUrlThumbs ?? {}}
                firstName={MEMBERS[member].firstName}
                lastName={MEMBERS[member].lastName}
                handle={MEMBERS[member].handle}
                communityHandle={MEMBERS[member].communityHandle}
                isLeader={MEMBERS[member].isLeader}
                pronouns={MEMBERS[member]?.pronouns ?? ""}
                lifestyles={MEMBERS[member]?.characteristics?.lifestyles ?? []}
                activities={MEMBERS[member]?.characteristics?.activities ?? []}
                isMenuButton
                isMember
              />
            )}
          </Grid>
        ))}
      {isEndOfPages &&
        blankMembers.length > 0 &&
        blankMembers.map((_, index) => (
          <Box
            key={`community-member-spaceholder-${index}`}
            sx={{
              my: 1,
              mx: spacing,
              minWidth: avatarSize * 8 + 8,
              height: avatarSize * 8 + 8,
            }}
          />
        ))}
      {!isEndOfPages ? (
        <StyledIconButton
          color="transparent"
          size="small"
          onClick={(e) => handlePagination(e, limit, true)}
          sxProps={{ p: 0 }}
        >
          <ChevronRightRoundedIcon color="secondary" />
        </StyledIconButton>
      ) : (
        <Box sx={{ minWidth: "24px", height: "24px" }} />
      )}
    </React.Fragment>
  );
};

StyledAvatarSet.propTypes = {
  limit: PropTypes.number,
  spacing: PropTypes.number,
  cursors: PropTypes.array,
  avatarSize: PropTypes.number,
  userHandle: PropTypes.string,
  blankMembers: PropTypes.array,
  isEndOfPages: PropTypes.bool,
  isStartOfPages: PropTypes.bool,
  handlePagination: PropTypes.func,
  MEMBERS: PropTypes.object,
  status: PropTypes.oneOf(["idle", "success", "failed", "loading"]).isRequired,
};

export default StyledAvatarSet;
