/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  LOADING_COMMUNITY_RULES,
  SET_COMMUNITY_RULES,
  SET_COMMUNITY_RULES_ERRORS,
  CLEAR_COMMUNITY_RULES_ERRORS,
} from "../../../../actionTypes/userTypes";

import { reduceQueryParams } from "../../../../../util/validators/reducers";

// Axios Instance
import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function queries firestore and gets
 * the community rules for the authenticated users
 * community.
 *
 * @param {string} [lastId] - The last document's id
 * @param {number} [limit] - the number of documents requested
 * @param {string} [lastLevel] - The last document's importance level
 * @param {object} dispatch - The redux dispatch object
 */
export const getCommunityRules =
  (lastId = null, lastLevel = null, limit = 6) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_COMMUNITY_RULES_ERRORS });
    dispatch({ type: LOADING_COMMUNITY_RULES });

    let params = { limit, subset: "adopted" };
    if (lastLevel && lastId) {
      params.lastId = lastId;
      params.lastLevel = lastLevel;
    }
    const queryParams = reduceQueryParams(params);

    return await baseAxios
      .get(`/entity/community/rules/${queryParams}`)
      .then(async (res) => {
        const { message, ...reduxData } = res.data;
        return await dispatch({
          type: SET_COMMUNITY_RULES,
          payload: reduxData,
        });
      })
      .catch((error) => {
        return dispatch({
          type: SET_COMMUNITY_RULES_ERRORS,
          payload: error ? (error.response ? error.response : error) : null,
        });
      });
  };
