/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React, { useEffect } from "react";
import { useLocation } from "react-router";

// Material UI
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

const PublicRoute = ({ component: Component }) => {
  const qParams = useLocation().search;
  const referral = new URLSearchParams(qParams).get("ref");
  const referralEntity = new URLSearchParams(qParams).get("ref_com");

  useEffect(() => {
    if (referralEntity) localStorage.setItem("referral_entity", referralEntity);
    if (referral) localStorage.setItem("referral_handle", referral);
  }, [referral, referralEntity]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const isLargeTablet = useMediaQuery(theme.breakpoints.only("md"));
  const isComputer = useMediaQuery(theme.breakpoints.only("lg"));

  return (
    <Component
      isMobile={isMobile}
      isTablet={isTablet}
      isComputer={isComputer}
      isLargeTablet={isLargeTablet}
    />
  );
};

export default React.memo(PublicRoute);
