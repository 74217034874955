/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Components
import {
  StyledSuccess,
  StyledPrimary,
  StyledSecondary,
} from "../../../Text/styledText";
import StyledIntroPost from "../Content/styledIntroPost";
import StyledUploadPhoto from "../Content/styledUploadPhoto";
import StyledCommunityMatch from "../Content/styledCommunityMatch";
import StyledCharacteristics from "../Content/styledCharacteristics";
import ABExperiment from "../../../Experiments/abExperiment";
import StyledWave from "../../../Animation/styledWave";

// Experiment Variants
import {
  // UPLOAD_PHOTO_VARIANTS,
  SET_CHARACTERISTICS_VARIANTS,
} from "../Experiments/onboardingExperiments";

/**
 * This helper function toggles between the different
 * steps and associated components.
 *
 * @param {number} stepIndex the index of the current step
 * @param {function} handleNext - the next dialog handler
 * @param {boolean} authUserIsSeed - if the auth user is a community seed
 * @param {boolean} isMobile - if the screen size is mobile
 * @returns the component and content associated with the current step
 */
export const onboardingContent = (
  stepIndex,
  handleNext,
  authUserIsSeed,
  isMobile,
  experimentRef,
  handle
) => {
  const matchContent = getCommunityMatchContent(authUserIsSeed);
  switch (stepIndex) {
    case 1:
      return {
        component: <StyledCharacteristics handleClick={handleNext} />,
        message: "You are so unique! Your community is going to love you 🥳",
        content: {
          title: (
            <ABExperiment
              experimentRef={experimentRef}
              name="set-characteristics__onboarding__experiment_000001"
              variants={SET_CHARACTERISTICS_VARIANTS}
            />
          ),
          color: "info",
          paperSxProps: { maxWidth: 650 },
          ariaLabelledby: "Set Characteristics Dialog - Loop Onboarding",
        },
      };
    case 2:
      return {
        component: <StyledUploadPhoto handleClick={handleNext} />,
        message: "Hey there good looking 😉",
        content: {
          title: (
            <span>
              Set your <StyledPrimary bold>Loop Picture</StyledPrimary>
            </span>
            // <ABExperiment
            //   experimentRef={experimentRef}
            //   name="upload-profile-photo__onboarding_experiment__000002"
            //   variants={UPLOAD_PHOTO_VARIANTS}
            // />
          ),
          paperSxProps: { width: 500, maxWidth: 500 },
          color: "primary",
          ariaLabelledby: "Upload Profile Photo - Loop Onboarding",
        },
      };
    case 3:
      return {
        component: <StyledIntroPost handleClick={handleNext} />,
        message: "Welcome to your community 🎉",
        content: {
          title: (
            <span>
              Play a game to <StyledPrimary bold>Say hello</StyledPrimary>{" "}
              <StyledWave sx={{ fontSize: 20 }}>👋</StyledWave>
            </span>
          ),
          color: "primary",
          paperSxProps: { width: 550, maxWidth: 550 },
          ariaLabelledby: "Loop Onboarding - Introduction Post Dialog",
        },
      };
    default:
      return {
        message:
          "One small step for you & one giant leap towards your community.",
        content: matchContent,
        component: (
          <StyledCommunityMatch
            handleClick={handleNext}
            isMobile={isMobile}
            DIALOG_CONTENT={matchContent}
          />
        ),
      };
  }
};

/**
 * This helper function returns the message text on
 * dialog transition.
 *
 * @param {number} step the step index
 * @returns {string} the message string
 */
export const getMessage = (step) => {
  switch (step) {
    case 0:
      return "One small step for you & one giant leap toward your community 🧑‍🚀";
    case 1:
      return "You are so unique! You will fit perfectly into your community 🥳  Now, onto the final step.";
    case 2:
      return "Woah, we almost fell over. You are so good-looking 😉";
    case 3:
      return "All set! Welcome to your community 🎉";
    default:
      return null;
  }
};

/**
 * This function gets the content to populate
 * the dialog.
 *
 * @param {boolean} authUserIsSeed - if the auth user is a community seed
 * @returns {object} the dialog content
 */
const getCommunityMatchContent = (authUserIsSeed) =>
  authUserIsSeed
    ? {
        color: "success",
        buttonColor: "success",
        buttonColorType: "main",
        ariaLabelledby: "Community Dialog - Loop Onboarding",
        disabled: false,
        title: (
          <span>
            You're a <StyledSuccess bold>Community Seed</StyledSuccess>
          </span>
        ),
        content:
          "Woohoo! You were chosen to be a new community seed. This means you are the first person matched with this community. Your community name is listed below 👇",
      }
    : {
        color: "secondary",
        buttonColor: "secondary",
        buttonColorType: "main",
        disabled: false,
        ariaLabelledby: "Community Dialog - Loop Onboarding",
        title: (
          <span>
            Meet your new <StyledSecondary bold>Community</StyledSecondary> 🎉
          </span>
        ),
        content:
          "Welcome to Loop! You just matched with an awesome new community. Learn more about your community below.",
      };
