/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Detector } from "react-detect-offline";

// Product Analytics
import { Mixpanel } from "../../Analytics/mixpanel";
import { mixpanelHelper } from "@marvelapp/react-ab-test";

// Redux
import { connect } from "react-redux";

// Components
import LayoutMain from "./layoutMain";
import LayoutFooter from "./layoutFooter";
import LayoutAppbar from "./layoutAppbar";
import LayoutLoading from "./layoutLoading";
import LayoutOffline from "./layoutOffline";

// Material UI
import { styled, useTheme } from "@mui/system";

const CENTERED_ROUTES = ["/"];
const NO_TOP_ROUTES = [];

const StyledApp = ({ location, isAppRoute, appLoading, handleDarkMode }) => {
  const theme = useTheme();
  let isCentered = CENTERED_ROUTES.includes(location.pathname);
  let disableTopMargin = NO_TOP_ROUTES.includes(location.pathname);

  const StyledMain = styled("main")(({ theme }) => ({
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.default,
  }));

  document.body.style.backgroundColor = theme.palette.background.default;
  
  useEffect(() => {
    mixpanelHelper.enable();
  }, []);

  useEffect(() => {
    if (location?.pathname ?? false) trackPageChange();
  }, [location]);

  const trackPageChange = useCallback(() => {
    Mixpanel.track("Page View", { page: location.pathname });
  }, [location]);

  return (
    <Detector
      render={({ online }) => (
        <React.Fragment>
          {!online ? (
            <LayoutOffline />
          ) : (
            <React.Fragment>
              {!appLoading ? (
                <StyledMain>
                  <LayoutAppbar
                    isAppRoute={isAppRoute}
                    handleDarkMode={handleDarkMode}
                  />
                  <LayoutMain
                    isCentered={isCentered}
                    isAppRoute={isAppRoute}
                    disableTopMargin={disableTopMargin}
                  />
                  <LayoutFooter location={location} isAppRoute={isAppRoute} />
                </StyledMain>
              ) : (
                <StyledMain>
                  <LayoutLoading />
                </StyledMain>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    />
  );
};

StyledApp.propTypes = {
  isAppRoute: PropTypes.bool,
  location: PropTypes.object,
  appLoading: PropTypes.bool,
  handleDarkMode: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  appLoading: state.session.appLoading,
});

export default connect(mapStateToProps, null)(React.memo(StyledApp));
