/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Components
import { extractImagesFromStorage } from "../../../../functions/extractImagesFromStorage";
import BaseAvatar from "./baseAvatar";
import StyledUserDetailsMenu from "../../Menus/StyledMenus/styledUserDetailsMenu";

const getSupportsWebp = () => localStorage.getItem("supportsWebp");

// Component
const StyledUserAvatar = ({
  handle,
  imageUrl,
  firstName,
  lastName,
  pronouns,
  isLeader,
  height = 5,
  width = 5,
  lifestyles,
  activities,
  communityHandle,
  imageUrlThumbs,
  onClick = null,
  profileImage = {},
  whiteBorder = false,
  isDummyPost = false,
  isMenuButton = false,
  isNavButton = false,
  supportsWebp = Boolean(getSupportsWebp()),
}) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const { thumbs, image } = extractImagesFromStorage(
    profileImage,
    imageUrl,
    imageUrlThumbs,
    supportsWebp
  );

  /**
   * This handler function opens the
   * user profile menu
   *
   * @param {object} event the event object
   */
  const handleClick = useCallback(
    (event) => {
      return onClick
        ? onClick()
        : isMenuButton
        ? setAnchorEl(event.currentTarget)
        : isNavButton
        ? navigate("/profile")
        : null;
    },
    [navigate, isMenuButton, isNavButton, onClick]
  );

  /**
   * This handler function closes the
   * user profile menu
   */
  const handleMenuClose = useCallback(() => setAnchorEl(null), []);

  return (
    <React.Fragment>
      <BaseAvatar
        type="user"
        height={height}
        width={width}
        image={image}
        whiteBorder={whiteBorder}
        onClick={handleClick}
        imageSet={thumbs}
        button={(isMenuButton || isNavButton || onClick) && !isDummyPost}
      />
      {anchorEl && isMenuButton && (
        <StyledUserDetailsMenu
          anchorEl={anchorEl}
          handleMenuClose={handleMenuClose}
          handle={handle}
          firstName={firstName}
          lastName={lastName}
          pronouns={pronouns}
          isLeader={isLeader}
          lifestyles={lifestyles}
          activities={activities}
          communityHandle={communityHandle}
        />
      )}
    </React.Fragment>
  );
};

StyledUserAvatar.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  isMenuButton: PropTypes.bool,
  isNavButton: PropTypes.bool,
  isDummyPost: PropTypes.bool,
  handle: PropTypes.string,
  imageUrl: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  pronouns: PropTypes.string,
  isLeader: PropTypes.bool,
  lifestyles: PropTypes.array,
  activities: PropTypes.array,
  communityHandle: PropTypes.string,
  imageUrlThumbs: PropTypes.object,
};

const mapStateToProps = (state) => ({
  communityHandle: state.user.core.community.communityHandle,
  handle: state.user.core.handle,
  firstName: state.user.core.firstName,
  lastName: state.user.core.lastName,
  pronouns: state.user.core?.pronouns ?? null,
  isLeader: state.user.core.community.isLeader,
  lifestyles: state.user.core.details?.characteristics?.lifestyles ?? [],
  activities: state.user.core.details?.characteristics?.activities ?? [],
  profileImage: state.user.core?.profileImage ?? {},
  imageUrl: state.user.core?.imageUrl ?? null,
  imageUrlThumbs: {
    avatar: state.user.core?.imageUrlThumbs?.avatar ?? null,
    xSmall: state.user.core?.imageUrlThumbs?.xSmall ?? null,
    small: state.user.core?.imageUrlThumbs?.small ?? null,
  },
});

export default connect(mapStateToProps, null)(StyledUserAvatar);
