/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import { useState, useCallback, useEffect } from "react";

/**
 * This custon hook validates that the browser running on
 * the users device supports Webp-formatted photos.
 *
 * @returns {boolean} - indicator if it does support
 */
const useWebp = () => {
  const [webp, setWebp] = useState(true);

  const checkWebp = useCallback(async () => {
    if (await supportsWebp()) {
      localStorage.setItem("supportsWebP", "true");
      return setWebp(true);
    }
    localStorage.setItem("supportsWebP", "false");
    return setWebp(false);
  }, []);

  useEffect(() => {
    checkWebp();
  }, []);

  return webp;
};

export default useWebp;

/**
 * This helper function uses a fake webp
 * image to validate whether the browser
 * supports webp-formats.
 */
const supportsWebp = async () => {
  if (!createImageBitmap) return false;
  const webpData =
    "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=";
  const blob = await fetch(webpData).then((r) => r.blob());
  return createImageBitmap(blob).then(
    () => true,
    () => false
  );
};
