/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { logoutUser } from "../../../../../redux/actions/auth/atomic/post/logout";

// Components & SVGs
import InfoAppbar from "../infoAppbar";
import StyledSearchButton from "./Buttons/styledSearchButton";
import LargeDeviceAppLinks from "./styledLinks/LargeDeviceAppLinks";
import MobileDeviceAppLinks from "./styledLinks/MobileDeviceAppLinks";
import StyledReportBugDialog from "../../../Dialogs/styledDialogs/styledReportBugDialog";
import StyledCommunityDialog from "../../../Dialogs/styledDialogs/styledCommunityDialog";
import StyledAlgoliaSearch from "../../../../FeatureSpecific/Search/Platform/styledAlgoliaSearch";
import RulesFlow from "../../../Flows/Rules/rulesFlow";
import StyledTooltip from "../../../Tooltip/styledTooltip";
import { ReactComponent as SelectedLogo } from "../../../../../images/loop_logo.svg";
import useResizeObserver from "use-resize-observer";

// Material UI
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";

// Check for message

const StyledAppLinks = ({
  logoutUser,
  isTabletDown,
  isLeader,
  infoText,
  handleDarkMode,
}) => {
  const navigate = useNavigate();
  const [infoBarOpen, setInfoBarOpen] = useState(true);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);
  const [rulesOpen, setRulesOpen] = useState(false);
  const [communityOpen, setCommunityOpen] = useState(false);
  const [reportBugOpen, setReportBugOpen] = useState(false);
  const [anchorSettingsEl, setAnchorSettingsEl] = useState(null);
  const [anchorNotifEl, setAnchorNotifEl] = useState(null);
  const [anchorReferralEl, setAnchorReferralEl] = useState(null);
  const [anchorWalletEl, setAnchorWalletEl] = useState(null);
  const [anchorFriendRequestEl, setAnchorFriendRequestEl] = useState(null);

  // Listen to changes in appbar height
  const observerSettings = { box: "border-box" };
  const info = useResizeObserver(observerSettings);
  const app = useResizeObserver(observerSettings);

  /**
   * This handler function updates the top margin.
   *
   * @param {number} top
   * @returns update margin top
   */
  const updateMarginTop = (top) =>
    (document.getElementById("_loop_app_").style.marginTop = `${top}px`);

  useEffect(() => {
    if (infoBarOpen) updateMarginTop(app.height + info.height);
    else updateMarginTop(app.height);
  }, [infoBarOpen, info, app]);

  /**
   * This handler function toggles the
   * mobile search bar.
   */
  const handleMobileSearchOpen = () => {
    setMobileSearchOpen(!mobileSearchOpen);
  };

  /**
   * This handler function toggles the
   * mobile search bar.
   */
  const toggleInfoBarOpen = () => {
    setInfoBarOpen(!infoBarOpen);
  };

  /**
   * This handler function toggles the menu
   * open on larger devices.
   *
   * @param {object} event the event object
   */
  const handleMenuClick = (event, type) => {
    let target = event.currentTarget;
    switch (type) {
      case "notifications":
        return setAnchorNotifEl(target);
      case "wallet":
        return setAnchorWalletEl(target);
      case "referrals":
        return setAnchorReferralEl(target);
      case "friendRequests":
        return setAnchorFriendRequestEl(target);
      default:
        return setAnchorSettingsEl(target);
    }
  };

  /**
   * This handler function initiates the logout
   * action handler.
   */
  const handleUserLogout = () => {
    logoutUser(false, navigate);
  };

  /**
   * This handler function toggles the menu closed
   * on larger devices.
   *
   * @returns {null}
   */
  const handleMenuClose = () => {
    setAnchorNotifEl(null);
    setAnchorFriendRequestEl(null);
    setAnchorSettingsEl(null);
    setAnchorReferralEl(null);
    setAnchorWalletEl(null);
  };

  /**
   * This helper function toggles the open
   * state mobile nav drawer.
   *
   * @param {object} e - the event object
   * @returns {null}
   */
  const toggleDrawer = (e, open) => {
    if (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift"))
      return;
    setMobileDrawerOpen(open);
  };

  /**
   * This handler function toggles the open state of the
   * selected dialog. In this scenario it toggles between
   * the three available dialogs: LeaderHub, Rules, and
   * Community.
   *
   * @param {object} e - the event object
   * @param {string} type - the type of dialog open
   * @returns {function} toggles the open state of the select dialog
   */
  const handleClick = (e, type) => {
    switch (type) {
      case "Community":
        setRulesOpen(false);
        setReportBugOpen(false);
        return setCommunityOpen(!communityOpen);
      case "Rules":
        setCommunityOpen(false);
        setReportBugOpen(false);
        return setRulesOpen(!rulesOpen);
      case "Report Bug":
        setRulesOpen(false);
        setCommunityOpen(false);
        return setReportBugOpen(!reportBugOpen);
      default:
        setRulesOpen(false);
        setCommunityOpen(false);
        return setReportBugOpen(false);
    }
  };

  const loadingButton = (
    <React.Fragment>
      <Skeleton
        variant="circular"
        sx={{ width: 45, height: 45, ml: isTabletDown ? 0.5 : 1.5 }}
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {infoBarOpen && infoText && (
        <InfoAppbar
          isTabletDown={isTabletDown}
          toggleInfoBarOpen={toggleInfoBarOpen}
          infoBarOpen={infoBarOpen}
          infoBarRef={info.ref}
          infoText={infoText}
        />
      )}
      <Toolbar
        ref={app.ref}
        sx={
          isTabletDown
            ? { px: "8px !important", py: 0.5 }
            : { pr: "8px !important", py: 0.5 }
        }
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={6}
            md={4}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <StyledTooltip title="You are awesome 🎉" placement="bottom">
                <IconButton
                  sx={{ mr: 1 }}
                  size="small"
                  component={NavLink}
                  to="/community"
                >
                  <SelectedLogo height={45} width={45} />
                </IconButton>
              </StyledTooltip>
            </Grid>
            <Grid item>
              <Suspense fallback={loadingButton}>
                <StyledSearchButton isTabletDown={isTabletDown} />
              </Suspense>
            </Grid>
          </Grid>
          {!isTabletDown ? (
            <LargeDeviceAppLinks
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              handleDialogClick={handleClick}
              handleUserLogout={handleUserLogout}
              anchorSettingsEl={anchorSettingsEl}
              anchorNotifEl={anchorNotifEl}
              anchorWalletEl={anchorWalletEl}
              anchorFriendRequestEl={anchorFriendRequestEl}
              anchorReferralEl={anchorReferralEl}
              isTabletDown={isTabletDown}
              handleDarkMode={handleDarkMode}
            />
          ) : (
            <MobileDeviceAppLinks
              open={mobileDrawerOpen}
              toggleDrawer={toggleDrawer}
              handleDialogClick={handleClick}
              handleDarkMode={handleDarkMode}
              handleMobileSearchOpen={handleMobileSearchOpen}
              handleUserLogout={handleUserLogout}
              anchorWalletEl={anchorWalletEl}
              anchorSettingsEl={anchorSettingsEl}
              isTabletDown={isTabletDown}
              isLeader={isLeader}
            />
          )}
          {isTabletDown && mobileSearchOpen && (
            <Grid item xs={12} sx={{ mt: 0.5, mb: 1 }}>
              <StyledAlgoliaSearch handleClose={handleMobileSearchOpen} />
            </Grid>
          )}
        </Grid>
      </Toolbar>
      {isTabletDown && (
        <RulesFlow
          open={rulesOpen}
          handleClose={() => setRulesOpen(false)}
          isMobile={isTabletDown}
          isTablet={isTabletDown}
        />
      )}
      {reportBugOpen && (
        <StyledReportBugDialog
          isMobile={isTabletDown}
          isTablet={isTabletDown}
          open={reportBugOpen}
          handleClick={(e) => handleClick(e, "Report Bug")}
        />
      )}
      {communityOpen && isTabletDown && (
        <StyledCommunityDialog
          isMobile={isTabletDown}
          isTablet={isTabletDown}
          open={communityOpen}
          handleClick={(e) => handleClick(e, "Community")}
        />
      )}
    </React.Fragment>
  );
};

StyledAppLinks.propTypes = {
  logoutUser: PropTypes.func,
  isTabletDown: PropTypes.bool,
  isLeader: PropTypes.bool,
  handleDarkMode: PropTypes.func,
  infoText: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
};

const mapStateToProps = (state) => ({
  isLeader: state.user.core.community.isLeader,
});

const mapActionsToProps = {
  logoutUser,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(React.memo(StyledAppLinks));
