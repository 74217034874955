/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import Radio from "@mui/material/Radio";

const StyledRadio = ({ color = "primary", colorType = "main", ...props }) => {
  return (
    <Radio
      sx={{
        color: `${color}.${colorType}`,
        "&.Mui-checked": {
          color: `${color}.${colorType}`,
        },
      }}
      {...props}
    />
  );
};

StyledRadio.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "success",
    "secondary",
    "info",
    "text",
    "warning",
    "error",
  ]).isRequired,
  colorType: PropTypes.oneOf(["light", "main", "dark", "primary", "secondary"])
    .isRequired,
};

export default StyledRadio;
