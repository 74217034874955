/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";

// Material UI
import { styled } from "@mui/system";
import Typography from "@mui/material/Typography";

const StyledWave = styled(({ ...props }) => <Typography {...props}>👋</Typography>)(
  ({ theme }) => ({
    animationName: "wave-animation",
    animationDuration: "4s",
    animationIterationCount: "infinite",
    transformOrigin: "70% 70%",
    display: "inline-block",
    "@keyframes wave-animation": {
      "0%": { transform: "rotate( 0.0deg)" },
      "10%": {
        transform: "rotate(14.0deg)",
      } /* The following five values can be played with to make the waving more or less extreme */,
      "20%": { transform: "rotate(-8.0deg)" },
      "30%": { transform: "rotate(14.0deg)" },
      "40%": { transform: "rotate(-4.0deg)" },
      "50%": { transform: "rotate(10.0deg)" },
      "60%": {
        transform: "rotate( 0.0deg)",
      } /* Reset for the last half to pause */,
      "100%": { transform: "rotate( 0.0deg)" },
    },
  })
);

export default StyledWave;