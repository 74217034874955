/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Formik
import { FastField } from "formik";

// Material UI
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const StyledAutoCompleteField = ({
  label,
  array,
  renderOption,
  getOptionLabel,
  required = false,
  fullWidth = true,
  getOptionSelected,
  color = "secondary",
  ...props
}) => {
  return (
    <FastField name={props.name}>
      {({ field, form, meta }) => {
        const errorText = meta.error && meta.touched ? meta.error : null;
        const handleChange = (value) => {
          if (typeof value === "string")
            form.setFieldValue(field.name, value.trim());
          else form.setFieldValue(field.name, value);
        };

        return (
          <Autocomplete
            {...props}
            options={array}
            autoHighlight
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            onChange={(e, value) => handleChange(value)}
            isOptionEqualToValue={getOptionSelected}
            value={field.value}
            renderInput={(params) => (
              <TextField
                {...params}
                required={required}
                label={label}
                variant="outlined"
                color={color}
                error={errorText ? true : false}
                helperText={errorText}
                sx={{
                  my: 1,
                  width: fullWidth ? "100%" : "auto",
                  "&.MuiInputBase-root": { fontSize: 16 },
                  "& .MuiOutlinedInput-root": {
                    fontSize: 16,
                    "& fieldset": {
                      borderColor: (theme) => theme.palette.grey[400],
                    },
                    "& .MuiOutlinedInput-input:-webkit-autofill": {
                      WebkitBoxShadow: (theme) =>
                        `0 0 0 100px ${theme.palette.background.paper} inset !important`,
                      backgroundColor: (theme) =>
                        `${theme.palette.background.paper} !important`,
                      backgroundClip: "content-box !important",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: (theme) => theme.palette[color].main,
                    },
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: 16,
                    "&.Mui-focused": {
                      color: (theme) =>
                        `${theme.palette[color].main} !important`,
                    },
                  },
                }}
                inputProps={{
                  sx: { fontSize: 16 },
                  ...params.inputProps,
                }}
              />
            )}
          />
        );
      }}
    </FastField>
  );
};

StyledAutoCompleteField.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  array: PropTypes.array.isRequired,
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func,
  getOptionSelected: PropTypes.func,
};

export default StyledAutoCompleteField;
