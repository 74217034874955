/**
 * * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BaseAvatar from "../../../UI/Avatars/styledAvatars/baseAvatar";
//import BaseElementText from "../../Notifications/BaseElements/baseElementText";

// Material UI
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import makeStyles from '@mui/styles/makeStyles';

// Styles
const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(5),
  },
}));

const BaseSearchElement = ({ handle, imageUrl, name }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItemIcon className={classes.avatar}>
        <BaseAvatar
          handle={handle}
          image={imageUrl}
          type="people"
          height={4}
          width={4}
          button={true}
        />
      </ListItemIcon>
      <ListItemText
        // primary={
        //   <BaseElementText primaryText={name} fullWidth={true} height={4.5} />
        // }
        disableTypography
      />
    </React.Fragment>
  );
};

BaseSearchElement.propTypes = {
  handle: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
};

export default BaseSearchElement;
