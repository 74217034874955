/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Product Analytics
import { Experiment, Variant } from "@marvelapp/react-ab-test";

const ABExperiment = ({ variants, name, handle, experimentRef }) => {
  // emitter.defineVariants(
  //   name,
  //   variants.map((v) => v.name),
  //   variants.lengths > 2 ? [33, 33, 33] : [50, 50]
  // );
  return (
    <Experiment ref={experimentRef} userIdentifier={handle} name={name}>
      {variants.map((variant, i) => (
        <Variant key={i} name={variant.name}>
          {variant.body}
        </Variant>
      ))}
    </Experiment>
  );
};

ABExperiment.propTypes = {
  variants: PropTypes.array,
  handle: PropTypes.string,
  name: PropTypes.string,
};

const mapStateToProps = (state) => ({
  handle: state?.core?.user?.handle ?? null,
});

export default connect(mapStateToProps, null)(ABExperiment);
