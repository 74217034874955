/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This helper function creates an image srcSet
 * string using the various profile image sizes.
 *
 * @param {object} imgSet - the image set object
 * @param {boolean} isLarge - if the image type is large
 * @param {string} [fallbackImageUrl] - the fallback url
 * @returns {string} - the formatted image set string
 */
export const createSrcSetHelper = (
  imgSet = [],
  fallbackImageUrl = null,
  isLarge = false
) => {
  if (!imgSet) return "";
  let reducedSet = reduceImageSetHelper(imgSet, isLarge);
  let fullSrcSet = Array(Object.keys(reducedSet).length - 1);
  Object.keys(reducedSet).forEach((img) => {
    let imgSrc = isLarge
      ? getLargeSrcSizeHelper(img)
      : getAvatarSrcSizeHelper(img);
    fullSrcSet[imgSrc.idx] = `${reducedSet[img]} ${imgSrc.srcSet}`;
  });
  if (fallbackImageUrl !== null) {
    fullSrcSet = [...fullSrcSet, `${fallbackImageUrl} 1920w`];
  }
  return fullSrcSet.filter((a) => a !== undefined).join();
};

/**
 * This helper function returns the correct image
 * set for the type of images.
 *
 * @param {object} imgSet - the different image sizes
 * @param {boolean} isLarge - if the image type is large
 * @returns {object} object with the relevant image sizes
 */
const reduceImageSetHelper = (imgSet, isLarge) => {
  if (isLarge) return getLargeImageSetHelper(imgSet);
  return getAvatarImageSetHelper(imgSet);
};

/**
 * This helper function returns the correct
 * src set label for the large images.
 *
 * @param {string} img - the image key
 * @returns {string} - the image size
 */
const getLargeSrcSizeHelper = (img) => {
  switch (img) {
    case "small":
      return {
        srcSet: "640w",
        idx: 0,
      };
    case "medium":
      return {
        srcSet: "768w",
        idx: 1,
      };
    case "large":
      return {
        srcSet: "1024w",
        idx: 2,
      };
    case "xLarge":
      return {
        srcSet: "1366w",
        idx: 3,
      };
    case "max":
      return {
        srcSet: "1600w",
        idx: 4,
      };
    default:
      return {
        srcSet: "1920w",
        idx: 5,
      };
  }
};

/**
 * This helper function returns the correct
 * src set label for the avatar images.
 *
 * @param {string} img - the image key
 * @returns {string} - the image size
 */
const getAvatarSrcSizeHelper = (img) => {
  switch (img) {
    case "avatar":
      return {
        srcSet: "256w",
        idx: 0,
      };
    case "xSmall":
      return {
        srcSet: "455w",
        idx: 1,
      };
    case "small":
      return {
        srcSet: "640w",
        idx: 2,
      };
    case "medium":
      return {
        srcSet: "768w",
        idx: 3,
      };
    default:
      return {
        srcSet: "1920w",
        idx: 4,
      };
  }
};

/**
 * This helper function reduces the list of available
 * image src sizes for the large images.
 *
 * @param {object} imgSet - the different image sizes
 * @returns {object} object with the relevant image sizes
 */
const getLargeImageSetHelper = (imgSet) => {
  return Object.fromEntries(
    Object.entries(imgSet).filter(
      ([key]) => ["small", "medium", "large", "xLarge", "max"].includes(key)
    )
  );
};

/**
 * This helper function reduces the list of available
 * image src sizes for the avatar image.
 *
 * @param {object} imgSet - the different image sizes
 * @returns {object} the relevant image sizes
 */
const getAvatarImageSetHelper = (imgSet) => {
  return Object.fromEntries(
    Object.entries(imgSet).filter(([key]) =>
      ["avatar", "xSmall", "small", "medium"].includes(key)
    )
  );
};