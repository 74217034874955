/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  UPLOADING_NEW_RULE,
  CLEAR_NEW_RULE_ERRORS,
  SET_NEW_RULE_ERRORS,
} from "../../../../actionTypes/ruleTypes";

import { SET_NEW_POST } from "../../../../actionTypes/feedTypes";

// Axios
import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function creates a new rule proposal
 * for the users community.
 *
 * @param {object} newRule - The newRule object
 * @param {string} newRule.title - The newRule title
 * @param {string} newRule.description - the newRule description
 * @param {string} newRule.importanceLevel - the newRule importance level
 * @param {string} entityType - The type of entity (community, coalition)
 * @param {object} dispatch - The redux dispatch object
 */
export const createRuleProposal =
  (newRule, entityType = "community") =>
  async (dispatch) => {
    dispatch({ type: CLEAR_NEW_RULE_ERRORS });
    dispatch({ type: UPLOADING_NEW_RULE });

    return await baseAxios
      .post(`/${entityType}/rule`, newRule)
      .then(async (res) => {
        let data = res.data.data;
        dispatch({ type: CLEAR_NEW_RULE_ERRORS });
        dispatch({
          type: SET_NEW_POST,
          payload: data,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_NEW_RULE_ERRORS,
          payload: error
            ? error.response
              ? error.response.data
              : error
            : null,
        });
      });
  };
