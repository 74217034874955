/**
 * * Copyright 2022 Loop Technology, Inc.
 */

import {
  // Create Post
  SET_NEW_POST_ERRORS,
  UPLOADING_NEW_POST,
  CLEAR_NEW_POST_ERRORS,
} from "../../../../actionTypes/contentTypes";

import {
  // Feed
  SET_NEW_POST,
} from "../../../../actionTypes/feedTypes";

import { apiStatus } from "../../../../apiEnums";
import { getUserCore } from "../../../user/atomic/get/getUserCore";

// Axios Instance
import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function gives the authenticated user
 * the ability to create a new hangman introduction
 * post.
 *
 * @param {object} introPost - the new post object
 * @param {string} introPost.keyword - The keyword
 * @param {string} introPost.catagory - The catagory
 * @param {array}  introPost.season - The season
 * @param {object} dispatch - the dispatch object
 */
export const createHangmanPost = (introPost) => async (dispatch) => {
  dispatch({ type: CLEAR_NEW_POST_ERRORS });
  dispatch({ type: UPLOADING_NEW_POST });

  await baseAxios
    .post("/post/intro/hangman", introPost)
    .then(async (res) => {
      await dispatch(getUserCore());
      return res.data.data;
    })
    .then((data) => {
      return dispatch({
        type: SET_NEW_POST,
        payload: {
          ...data,
          comments: {
            api: { status: apiStatus("idle"), error: {} },
            comments: {},
            lastComment: null,
            isEndOfComments: false,
            lastUpdated: null,
          },
          userVotes: { up: false, down: false },
        },
      });
    })
    .catch((error) => {
      dispatch({
        type: SET_NEW_POST_ERRORS,
        payload: new Error(
          error ? (error.response ? error.response.data : error) : null
        ),
      });
    });
};
