/**
 * Copyright 2022 Loop Technology, Inc.
 */

import axios from "axios";

// Create Auth Axios Instance
const authAxios = axios.create({
  baseURL: "https://api.looop.cool",
  withCredentials: true,
  credentials: "include",
});

export default authAxios;
