/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

// Components
import StyledAppLinks from "./styledComponents/styledAppLinks";
import { getBaseRoute } from "../../../../functions/getBaseRoute";

//Material UI
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";

// Component
const Appbar = ({ infoText, isTabletDown, handleDarkMode }) => {
  const location = useLocation();
  const path = getBaseRoute(location.pathname);
  const appbar = (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        width: "100vw",
        padding: "0 !important",
        boxShadow:
          path === "/profile"
            ? (theme) => theme.shadows[0]
            : "0px 5px 5px -3px rgb(0 0 0 / 3%), 0px 8px 10px 1px rgb(0 0 0 / 1%), 0px 3px 14px 2px rgb(0 0 0 / 1%)",
      }}
      position="fixed"
      color="inherit"
      elevation={1}
    >
      <StyledAppLinks
        infoText={infoText}
        isTabletDown={isTabletDown}
        handleDarkMode={handleDarkMode}
      />
    </AppBar>
  );

  return (
    <React.Fragment>
      <CssBaseline />
      <div id="back-to-top-anchor" />
      {appbar}
    </React.Fragment>
  );
};

Appbar.propTypes = {
  infoText: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
  isTabletDown: PropTypes.bool.isRequired,
  handleDarkMode: PropTypes.func.isRequired,
};

export default Appbar;
