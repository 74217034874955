/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */

import {
  setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";

import {
  //Session
  LOADING_USER_SESSION,
  SET_USER_SESSION,
  SET_APP_LOADING,
  SET_USER_SESSION_LOGOUT,
  SET_USER_SESSION_ERRORS,
  SET_USER_SESSION_REFRESH_ERRORS,
  CLEAR_USER_SESSION_ERRORS,
  SET_EMAIL_VERIFIED,
  //ReportABug
  SET_PLATFORM_BUG,
  SET_PLATFORM_BUG_ERRORS,
  CLEAR_PLATFORM_BUG_ERRORS,
  LOADING_PLATFORM_BUG,
} from "../actionTypes/sessionTypes";

import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

const initState = {
  api: {
    status: apiStatus("idle"),
    error: {},
  },
  token: "",
  uid: "",
  tokenRefreshFailed: false,
  authenticated: false,
  isLoggedOut: true,
  appLoading: false,
  emailVerified: null,
  reportABug: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case LOADING_USER_SESSION:
      return loadingBaseReducer(state);
    case LOADING_PLATFORM_BUG:
      return loadingBaseReducer(state, "reportABug");

    // Success Types
    case SET_APP_LOADING:
      return setBaseReducer(state, null, null, {
        l0: {
          appLoading: true,
        },
      });
    case SET_EMAIL_VERIFIED:
      return setBaseReducer(state, null, null, {
        l0: { emailVerified: action?.payload ?? true },
      });
    case SET_USER_SESSION:
      return setBaseReducer(state, null, null, {
        l0: {
          tokenRefreshFailed: false,
          authenticated: true,
          appLoading: false,
          isLoggedOut: false,
          ...action.payload,
        },
      });
    case SET_PLATFORM_BUG:
      return setBaseReducer(state, "reportABug");
    case SET_USER_SESSION_LOGOUT:
      return {
        api: {
          ...state.api,
          status: apiStatus("idle"),
        },
        token: "",
        uid: "",
        appLoading: false,
        tokenRefreshFailed: false,
        authenticated: false,
        isLoggedOut: true,
      };

    // Failed Types
    case SET_USER_SESSION_REFRESH_ERRORS:
      return setErrorsBaseReducer(state, null, null, {
        l0: { tokenRefreshFailed: true },
        error: action.payload,
      });
    case SET_USER_SESSION_ERRORS:
      return setErrorsBaseReducer(state, null, null, {
        l0: { authenticated: false, appLoading: false },
        error: action.payload,
      });
    case SET_PLATFORM_BUG_ERRORS:
      return setErrorsBaseReducer(state, "reportABug", null, {
        error: action.payload,
      });

    // Idle Types
    case CLEAR_USER_SESSION_ERRORS:
      return clearErrorsBaseReducer(state, null, null, {
        l0: { tokenRefreshFailed: false },
      });
    case CLEAR_PLATFORM_BUG_ERRORS:
      return clearErrorsBaseReducer(state, "reportABug");
    default:
      return state;
  }
}
