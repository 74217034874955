/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { createRuleProposal } from "../../../../../redux/actions/user/atomic/post/createRuleProposal";

// Form Components
import BaseForm from "../../../Forms/baseForm";
import ruleProposalSchema from "../../../Dialogs/styledDialogs/validationSchema/ruleProposalSchema";

// Components
import StyledDialogContent from "../../../Dialogs/styledComponents/styledDialogContent";
import StyledDialogFooter from "../../../Dialogs/styledComponents/styledDialogFooter";
import StyledLongInputField from "../../../Forms/styledComponents/styledLongInputField";
import StyledRadioGroup from "../../../Forms/styledComponents/styledRadioGroup";

// Material UI
import Grid from "@mui/material/Grid";
// import Popover from "@mui/material/Popover";
// import Typography from "@mui/material/Typography";
// import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

// Component
const StyledRuleProposal = ({ isMobile, handleClose, createRuleProposal }) => {
  let formRef = useRef(null);
  // const [anchorEl, setAnchorEl] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const handlePopoverOpen = (event) => {
  //   console.log(event.currentTarget);
  //   setAnchorEl(event.currentTarget);
  // };

  // const handlePopoverClose = () => {
  //   setAnchorEl(null);
  // };

  /**
   * This helper function dispatches the
   * form to submit outside of the formik
   * baseForm component.
   */
  const onSubmit = () => formRef.current.submitForm();

  /**
   * This submit handler function is used
   * to update the core details.
   *
   * @param {object} data
   */
  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    return await createRuleProposal(data)
      .then(() => {
        setIsSubmitting(false);
        handleClose();
      })
      .catch(() => {
        setIsSubmitting(false);
        handleClose();
      });
  };

  return (
    <React.Fragment>
      <StyledDialogContent content={DIALOG_CONTENT.content}>
        <BaseForm
          data={DIALOG_CONTENT.initialFormState}
          handleSubmit={handleSubmit}
          validationSchema={ruleProposalSchema}
          formRef={formRef}
          noMargin
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <StyledLongInputField
              name="title"
              placeholder="Ex: Racism is not tolerated"
              required
            />
            <StyledLongInputField
              name="description"
              placeholder="Ex: Racist content of any kind is not allowed in our community."
              rows={6}
              rowsMax={10}
              required
            />
            <StyledRadioGroup
              name="importanceLevel"
              title={
                <span>
                  Choose the importance of this rule.
                  {/* <Box
                    aria-owns={
                      Boolean(anchorEl) ? "mouse-over-popover" : undefined
                    }
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                  >
                    <HelpOutlineRoundedIcon fontSize="small" />
                  </Box>
                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1 }}>I use Popover.</Typography>
                  </Popover> */}
                </span>
              }
              formLabels={DIALOG_CONTENT.labels}
              isMobile={isMobile}
              required
            />
          </Grid>
        </BaseForm>
      </StyledDialogContent>
      <StyledDialogFooter
        text="Propose New Rule"
        isSubmitting={isSubmitting}
        handleSubmit={onSubmit}
        color="success"
        colorType="main"
      />
    </React.Fragment>
  );
};

StyledRuleProposal.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  createRuleProposal: PropTypes.func.isRequired,
};

const mapActionsToProps = {
  createRuleProposal,
};

export default connect(null, mapActionsToProps)(StyledRuleProposal);

/**
 * This object holds all of the content used
 * within the report bug dialog.
 */
const DIALOG_CONTENT = {
  initialFormState: {
    title: "",
    description: "",
    importanceLevel: "suggested",
  },
  content: (
    <span>
      Have an idea how to fix an issue within your community? Send a new rule
      proposal to your community.
    </span>
  ),
  labels: [
    {
      value: "suggested",
      label: "Suggested",
      colorType: "main",
      color: "success",
    },
    {
      value: "moderate",
      label: "Moderate",
      color: "warning",
      colorType: "main",
    },
    {
      value: "important",
      label: "Important",
      color: "error",
      colorType: "main",
    },
  ],
};
