/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const StyledInput = styled("input")(() => ({
  display: "none",
}));

// Component
const BaseImageUploadButton = ({
  text,
  handleImageChange,
  id = "upload-image",
  ariaLabel = "upload-image",
  isSubmitting = false,
  ...ButtonProps
}) => {
  /**
   * This onChange function handles changes to the
   * image state.
   *
   * @param {object} e - event object
   * @param {object} e.target - event object target
   * @param {number} e.target.files- the new image file
   */
  const onChange = (e) => {
    handleImageChange(e);
  };

  return (
    <div>
      <StyledInput onChange={onChange} accept="image/*" id={id} type="file" />
      <label htmlFor={id}>
        <Button
          sx={{ py: 1, px: 2 }}
          aria-label={ariaLabel}
          component="span"
          {...ButtonProps}
        >
          {text}
          {isSubmitting && (
            <CircularProgress
              size={20}
              color={ButtonProps.color}
              sx={{ position: "absolute" }}
            />
          )}
        </Button>
      </label>
    </div>
  );
};

BaseImageUploadButton.propTypes = {
  handleImageChange: PropTypes.func.isRequired,
  text: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

export default BaseImageUploadButton;
