/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledSimpleMenu from "../styledMenuElements/styledSimpleMenu";
import ruleActions from "./actions/ruleActions";

const StyledRuleMenu = ({
  anchorEl,
  handleMenuClose,
  handleSuggestChange,
  handleSuggestRemove,
  handleViewDetails,
}) => {
  return (
    <StyledSimpleMenu
      name="Loop Community - Rule Actions Menu"
      anchorEl={anchorEl}
      handleMenuClose={handleMenuClose}
      elements={ruleActions(
        handleSuggestChange,
        handleSuggestRemove,
        handleViewDetails
      )}
    />
  );
};

StyledRuleMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleMenuClose: PropTypes.func,
  handleSuggestChange: PropTypes.func,
  handleSuggestRemove: PropTypes.func,
  handleViewDetails: PropTypes.func,
};

export default StyledRuleMenu;
