/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { switchCommunity } from "../../../../redux/actions/auth/atomic/post/switchCommunity";

// Component
import BaseDialog from "../baseDialog";
import { StyledSecondary } from "../../Text/styledText";
import StyledMemberList from "../../../FeatureSpecific/Members/styledMemberList";
import StyledLeaderSet from "../../../FeatureSpecific/Members/styledLeaderSet";
import StyledClickableTooltip from "../../Tooltip/styledClickableTooltip";

// Material UI
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import StyledDialogHeader from "../styledComponents/styledDialogHeader";
import StyledDialogContent from "../styledComponents/styledDialogContent";
import StyledDialogFooter from "../styledComponents/styledDialogFooter";
import Typography from "@mui/material/Typography";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

const StyledSection = styled("span")(({ theme }) => ({
  margin: theme.spacing(1, 0, 2, 0),
}));

const StyledCommunityDialog = ({
  open,
  place,
  isMobile,
  isTablet,
  handleClick,
  type = "user",
  switchCommunity,
  userCommunityName,
  communityName = "",
  reachedSwitchingThreshold = false,
  ...props
}) => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * The content used to populate the leader
   * hub dialog
   */
  const DIALOG_CONTENT = {
    title: (
      <span>
        {communityName.split(":")[0]}:
        <StyledSecondary bold>{communityName.split(":")[1]}</StyledSecondary>
      </span>
    ),
    footerText: (
      <span>
        Communities need <StyledSecondary bold>10+ people</StyledSecondary>{" "}
        before you can switch
      </span>
    ),
  };

  /**
   * This handler function lets user switch to a
   * different community.
   * @returns
   */
  const handleSwitch = async () => {
    setIsSubmitting(true);
    await switchCommunity(place, navigate);
    return setIsSubmitting(false);
  };

  return (
    <BaseDialog
      open={open}
      isMobile={isMobile}
      isTablet={isTablet}
      onClose={handleClick}
      aria-labelledby="Loop Network - Community Details Dialog"
      PaperSxProps={!isMobile && !isTablet ? { width: 625, maxWidth: 625 } : {}}
    >
      <StyledDialogHeader
        title={DIALOG_CONTENT.title}
        handleClose={handleClick}
      />
      <StyledDialogContent content="">
        <Typography variant="h6" color="secondary">
          Members
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ mt: 1, mb: 2 }}
        >
          <StyledMemberList
            isMobile={isMobile}
            isTablet={isTablet}
            isDialog={true}
            type={type}
            wrap={false}
          />
        </Grid>
        {userCommunityName === communityName && (
          <React.Fragment>
            <Typography
              variant="h6"
              sx={{ color: "success.main", verticalAlign: "middle" }}
            >
              Elections{" "}
              <StyledClickableTooltip
                divSxProps={{ display: "inline" }}
                title="Loop communities hold democratic elections to pick their leaders. Elections are held when a new leader position opens up or when an existing leader's term ends."
              >
                <HelpOutlineRoundedIcon
                  fontSize="small"
                  sx={{ color: "text.secondary", verticalAlign: "text-top" }}
                />
              </StyledClickableTooltip>
            </Typography>
            <StyledSection>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ mt: 0.5, mb: 2 }}
              ></Typography>
            </StyledSection>
          </React.Fragment>
        )}
        <Typography
          variant="h6"
          sx={{ color: "primary.main", verticalAlign: "middle" }}
        >
          Leaders{" "}
          <StyledClickableTooltip
            divSxProps={{ display: "inline" }}
            title="Leaders are moderators that get elected by you. A new leader position gets enabled every 10 members who join."
          >
            <HelpOutlineRoundedIcon
              fontSize="small"
              sx={{ color: "text.secondary", verticalAlign: "text-top" }}
            />
          </StyledClickableTooltip>
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ mt: 1, mb: 2 }}
        >
          <StyledLeaderSet
            isMobile={isMobile}
            isTablet={isTablet}
            type={type}
            wrap={false}
            centered={false}
          />
        </Grid>
      </StyledDialogContent>
      {userCommunityName === communityName && (
        <StyledDialogFooter
          handleSubmit={handleSwitch}
          text="Switch to a New Community"
          color="secondary"
          colorType="main"
          isSubmitting={isSubmitting}
          footerText={!reachedSwitchingThreshold && DIALOG_CONTENT.footerText}
          disabled={!reachedSwitchingThreshold}
        />
      )}
    </BaseDialog>
  );
};

StyledCommunityDialog.propTypes = {
  isTablet: PropTypes.bool,
  joinCommunity: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  userCommunityName: PropTypes.string,
  communityName: PropTypes.string,
  reachedSwitchingThreshold: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["user", "people"]),
};

const mapStateToProps = (state, props) => {
  const type = props.type === "people" ? "activeProfile" : "user";
  return {
    communityName: state[type].core.community.communityName,
    userCommunityName: state.user.core.community.communityName,
    reachedSwitchingThreshold: state[type].community.reachedSwitchingThreshold,
    place:
      type === "user" ? state[type].core.details.location.primaryCity : null,
  };
};

const mapActionsToProps = {
  switchCommunity,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(StyledCommunityDialog);
