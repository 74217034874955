/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React from "react";
import PropTypes from "prop-types";
import { ErrorBoundary } from "react-error-boundary";

const BaseBoundary = ({ fallback, children, ...props }) => {
  return (
    <ErrorBoundary FallbackComponent={fallback} {...props}>
      {children}
    </ErrorBoundary>
  );
};

BaseBoundary.propTypes = {
  fallback: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.node,
};

export default BaseBoundary;
