/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  LOADING_COMMUNITY_LEADERS,
  SET_COMMUNITY_LEADERS,
  SET_COMMUNITY_LEADERS_ERRORS,
  CLEAR_COMMUNITY_LEADERS,
  CLEAR_COMMUNITY_LEADERS_ERRORS,
} from "../../../../actionTypes/userTypes";

import { reduceQueryParams } from "../../../../../util/validators/reducers";

// Axios Instance
import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function queries firestore and gets
 * the community leaders for the authenticated users
 * community.
 *
 * @param {number} [limit] - the number of documents requested
 * @param {string} [lastDate] - The last document's date created
 * @param {string} [lastId] - The last document's id
 * @param {object} dispatch - The redux dispatch object
 */
export const getCommunityLeaders =
  (lastId = null, lastDate = null, limit = 6) =>
  async (dispatch) => {
    dispatch({ type: CLEAR_COMMUNITY_LEADERS_ERRORS });
    dispatch({ type: LOADING_COMMUNITY_LEADERS });
    if (!lastDate || !lastId) dispatch({ type: CLEAR_COMMUNITY_LEADERS });

    let params = { limit, leadersOnly: "true" };
    if (lastDate && lastId) {
      params.lastDate = lastDate;
      params.lastId = lastId;
    }
    const queryParams = reduceQueryParams(params);

    return await baseAxios
      .get(`/entity/community/members/${queryParams}`)
      .then(async (res) => {
        const { message, ...reduxData } = res.data;
        return await dispatch({
          type: SET_COMMUNITY_LEADERS,
          payload: reduxData,
        });
      })
      .catch((error) => {
        return dispatch({
          type: SET_COMMUNITY_LEADERS_ERRORS,
          payload: error ? (error.response ? error.response : error) : null,
        });
      });
  };
