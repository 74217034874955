/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  LOADING_GENERAL_NOTIFICATIONS,
  SET_NEW_GENERAL_NOTIFICATIONS,
  SET_GENERAL_NOTIFICATIONS_ERRORS,
  CLEAR_GENERAL_NOTIFICATIONS_ERRORS,
  LOADING_FRIEND_REQUESTS,
  SET_NEW_FRIEND_REQUESTS,
  SET_FRIEND_REQUESTS_ERRORS,
  CLEAR_FRIEND_REQUESTS_ERRORS,
} from "../../actionTypes/notificationTypes";

/**
 * This action function hooks up to the notification
 * snapshot and adds any returned data to the redux
 * store.
 *
 * @param {number} data - the notification data
 * @param {string} error - if the snapshot errors
 * @param {string} type - the notification type
 * @param {object} dispatch - The redux dispatch object
 */
export const setSnapshotNotifications =
  (data, type = "general", error = null) =>
  async (dispatch) => {
    const dispatchActions = getDispatchActionsHelper(type);

    if (data && Object.keys(data).length > 0) {
      dispatch({ type: dispatchActions.clear });
      dispatch({ type: dispatchActions.loading });
      return await dispatch({
        type: dispatchActions.set,
        payload: data,
      });
    }

    if (error && Object.keys(error).length > 0) {
      return dispatch({
        type: dispatchActions.error,
        payload: error,
      });
    }
  };

const getDispatchActionsHelper = (type) => ({
  clear:
    type === "general"
      ? CLEAR_GENERAL_NOTIFICATIONS_ERRORS
      : CLEAR_FRIEND_REQUESTS_ERRORS,
  loading:
    type === "general"
      ? LOADING_GENERAL_NOTIFICATIONS
      : LOADING_FRIEND_REQUESTS,
  set:
    type === "general"
      ? SET_NEW_GENERAL_NOTIFICATIONS
      : SET_NEW_FRIEND_REQUESTS,
  error:
    type === "general"
      ? SET_GENERAL_NOTIFICATIONS_ERRORS
      : SET_FRIEND_REQUESTS_ERRORS,
});
