/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Formik
import { FastField } from "formik";

// Material UI
import TextField from "@mui/material/TextField";

const StyledInputField = ({
  label,
  autoComplete,
  placeholder = "",
  required = false,
  fullWidth = true,
  color = "secondary",
  colorType = "main",
  ...props
}) => {
  return (
    <FastField name={props.name}>
      {({ field, meta }) => {
        const errorText = meta.error && meta.touched ? meta.error : null;
        return (
          <TextField
            {...field}
            {...props}
            sx={{
              my: 1,
              width: fullWidth ? "100%" : "auto",
              "& .MuiOutlinedInput-root": {
                fontSize: 16,
                "& fieldset": {
                  borderColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? theme.palette.text.primary
                      : theme.palette.grey[400],
                },
                "& .MuiOutlinedInput-input:-webkit-autofill": {
                  WebkitBoxShadow: (theme) =>
                    `0 0 0 100px ${theme.palette.background.paper} inset !important`,
                  backgroundColor: (theme) =>
                    `${theme.palette.background.paper} !important`,
                  backgroundClip: "content-box !important",
                },
                "&.Mui-focused fieldset": {
                  borderColor: (theme) => theme.palette[color][colorType],
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: 16,
                "&.Mui-focused": {
                  color: (theme) =>
                    `${theme.palette[color][colorType]} !important`,
                },
              },
            }}
            inputProps={{
              "aria-label": label,
              "aria-required": required,
            }}
            type="input"
            margin="normal"
            variant="outlined"
            label={label}
            required={required}
            placeholder={placeholder}
            helperText={errorText}
            autoComplete={autoComplete}
            error={errorText ? true : false}
            fullWidth
          />
        );
      }}
    </FastField>
  );
};

StyledInputField.propTypes = {
  label: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "success",
    "text",
    "warning",
    "error",
  ]),
  colorTypes: PropTypes.oneOf([
    "main",
    "light",
    "dark",
    "secondary",
    "primary",
  ]),
};

export default StyledInputField;
