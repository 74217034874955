/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import { getRuleLevelStylesHelper } from "../../../functions/getRuleLevelStylesHelper";

// Material UI
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/system";

// Component
const StyledRuleLabel = ({ level }) => {
  const { title, sxColor, color } = getRuleLevelStylesHelper(level);
  return (
    <Typography
      sx={{
        color: sxColor,
        backgroundColor: (theme) => alpha(theme.palette[color].light, 0.1),
        py: 0.5,
        px: 1.5,
        borderRadius: 1,
      }}
    >
      {title}
    </Typography>
  );
};

StyledRuleLabel.propTypes = {
  level: PropTypes.oneOf(["suggested", "important", "moderate"]),
};

export default StyledRuleLabel;
