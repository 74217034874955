/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { createRuleChange } from "../../../../../redux/actions/user/atomic/post/createRuleChange";

// Form Components
import BaseForm from "../../../Forms/baseForm";
import ruleChangeSchema from "../../../Dialogs/styledDialogs/validationSchema/ruleChangeSchema";

// Components
import StyledDialogContent from "../../../Dialogs/styledComponents/styledDialogContent";
import StyledDialogFooter from "../../../Dialogs/styledComponents/styledDialogFooter";
import StyledLongInputField from "../../../Forms/styledComponents/styledLongInputField";
import StyledRadioGroup from "../../../Forms/styledComponents/styledRadioGroup";

// Material UI
import Grid from "@mui/material/Grid";

// Component
const StyledRuleChange = ({
  rule,
  isMobile,
  handleClose,
  createRuleChange,
}) => {
  let formRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const DIALOG_CONTENT = getDialogContent(rule);

  /**
   * This helper function dispatches the
   * form to submit outside of the formik
   * baseForm component.
   */
  const onSubmit = () => formRef.current.submitForm();

  /**
   * This submit handler function is used
   * to update the core details.
   *
   * @param {object} data
   */
  const handleSubmit = async (data) => {
    setIsSubmitting(true);
    return await createRuleChange(data, rule.ruleId)
      .then(() => {
        setIsSubmitting(false);
        return handleClose();
      })
      .catch(() => {
        setIsSubmitting(false);
        return handleClose();
      });
  };

  return (
    <React.Fragment>
      <StyledDialogContent content={DIALOG_CONTENT.content}>
        <BaseForm
          data={DIALOG_CONTENT.initialFormState}
          handleSubmit={handleSubmit}
          validationSchema={ruleChangeSchema}
          formRef={formRef}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <StyledLongInputField name="title" required />
            <StyledLongInputField
              name="description"
              rows={6}
              rowsMax={10}
              required
            />
            <StyledRadioGroup
              name="importanceLevel"
              title="Choose the importance of this rule."
              formLabels={DIALOG_CONTENT.labels}
              isMobile={isMobile}
              required
            />
          </Grid>
        </BaseForm>
      </StyledDialogContent>
      <StyledDialogFooter
        text="Propose Rule Change"
        isSubmitting={isSubmitting}
        handleSubmit={onSubmit}
        color="success"
        colorType="main"
      />
    </React.Fragment>
  );
};

StyledRuleChange.propTypes = {
  rule: PropTypes.object,
  isMobile: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  createRuleChange: PropTypes.func,
};

const mapActionsToProps = {
  createRuleChange,
};

export default connect(null, mapActionsToProps)(StyledRuleChange);

/**
 * This object holds all of the content used
 * within the report bug dialog.
 */
const getDialogContent = (rule) => ({
  initialFormState: {
    title: rule.ruleDetails.content.title,
    description: rule.ruleDetails.content.description,
    importanceLevel: rule.ruleDetails.content.importanceLevel,
  },
  content: (
    <span>
      Have an idea how to fix an issue with the rules? Send a rule change
      suggestion to your community.
    </span>
  ),
  labels: [
    {
      value: "suggested",
      label: "Suggested",
      colorType: "main",
      color: "success",
    },
    {
      value: "moderate",
      label: "Moderate",
      color: "warning",
      colorType: "main",
    },
    {
      value: "important",
      label: "Important",
      color: "error",
      colorType: "main",
    },
  ],
});
