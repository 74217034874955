/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */

import {
  setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";
import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

import {
  //Community Leaderboard
  CLEAR_COMMUNITY_LEADERBOARD,
  LOADING_COMMUNITY_LEADERBOARD,
  SET_COMMUNITY_LEADERBOARD,
  // SET_NEW_COMMUNITY_LEADERBOARD,
  CLEAR_COMMUNITY_LEADERBOARD_ERRORS,
  SET_COMMUNITY_LEADERBOARD_ERRORS,
  CLEAR_USER_LEADERBOARD,
  LOADING_USER_LEADERBOARD,
  SET_USER_LEADERBOARD,
  // SET_NEW_USER_LEADERBOARD,
  CLEAR_USER_LEADERBOARD_ERRORS,
  SET_USER_LEADERBOARD_ERRORS,
} from "../actionTypes/leaderboardTypes";

const initState = {
  communities: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    leaderboard: {},
    lastUpdated: null,
  },
  users: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    leaderboard: {},
    lastUpdated: null,
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case LOADING_COMMUNITY_LEADERBOARD:
      return loadingBaseReducer(state, "communities");
    case LOADING_USER_LEADERBOARD:
      return loadingBaseReducer(state, "users");

    // Success Types
    case CLEAR_COMMUNITY_LEADERBOARD:
      return setBaseReducer(state, "communities", null, {
        l1: { ...initState.communities },
      });
    case CLEAR_USER_LEADERBOARD:
      return setBaseReducer(state, "users", null, {
        l1: { ...initState.users },
      });
    case SET_COMMUNITY_LEADERBOARD:
      return setBaseReducer(state, "communities", null, {
        l1: {
          lastUpdated: action.payload.data.lastUpdated,
          leaderboard: {
            ...state.communities.leaderboard,
            ...action.payload.data.leaderboard,
          },
        },
      });
    case SET_USER_LEADERBOARD:
      return setBaseReducer(state, "users", null, {
        l1: {
          lastUpdated: action.payload.data.lastUpdated,
          leaderboard: {
            ...state.users.leaderboard,
            ...action.payload.data.leaderboard,
          },
        },
      });
    // case SET_NEW_COMMUNITY_LEADERBOARD:
    //   return setBaseReducer(state, "communities", null, {
    //     l1: {
    //       lastUpdated: action.payload.lastUpdated,
    //       leaderboard: {
    //         ...action.payload,
    //         ...state.communities.leaderboard,
    //       },
    //     },
    //   });
    // case SET_NEW_USER_LEADERBOARD:
    //   return setBaseReducer(state, "users", null, {
    //     l1: {
    //       lastUpdated: action.payload.lastUpdated,
    //       leaderboard: {
    //         ...action.payload,
    //         ...state.users.leaderboard,
    //       },
    //     },
    //   });

    // Failed Types
    case SET_COMMUNITY_LEADERBOARD_ERRORS:
      return setErrorsBaseReducer(state, "communities", null, {
        error: action.payload,
      });
    case SET_USER_LEADERBOARD_ERRORS:
      return setErrorsBaseReducer(state, "users", null, {
        error: action.payload,
      });

    // Idle Types
    case CLEAR_COMMUNITY_LEADERBOARD_ERRORS:
      return clearErrorsBaseReducer(state, "communities");
    case CLEAR_USER_LEADERBOARD_ERRORS:
      return clearErrorsBaseReducer(state, "users");
    default:
      return state;
  }
}
