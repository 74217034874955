/**
 * Copyright 2022 Loop Technology, Inc.
 */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

// Redux
import { connect } from "react-redux";

// Functions
import { getNotificationCount } from "../../functions/getNotificationCount";
import { useTheme } from "@mui/system";

const SEO = ({
  description,
  lang,
  author,
  title,
  notifications,
  isAppRoute,
  lastNotificationClickDate,
}) => {
  const theme = useTheme();
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const COUNT = getNotificationCount(
      notifications,
      lastNotificationClickDate
    );
    setNotificationCount(COUNT);
  }, [notifications, lastNotificationClickDate]);

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={
        notificationCount > 0
          ? `(${notificationCount > 6 ? "6+" : notificationCount}) %s | Loop ✌️`
          : "%s | Loop ✌️"
      }
      meta={[
        {
          name: "description",
          content: description,
        },
        {
          name: "theme-color",
          content: isAppRoute
            ? theme.palette.background.paper
            : theme.palette.background.default,
          media: "(prefers-color-scheme: light)",
        },
        {
          name: "theme-color",
          content: isAppRoute
            ? theme.palette.background.paper
            : theme.palette.background.default,
          media: "(prefers-color-scheme: dark)",
        },
      ]}
      // meta={[...openGraphTags, ...twitterCardTags]}
    ></Helmet>
  );
};

const mapStateToProps = (state, props) => {
  if (props.isAppRoute) {
    return {
      notifications: state.notifications.general.notifications,
      lastNotificationClickDate: state.user.core.lastNotificationClickDate,
    };
  }
  return {};
};

export default connect(mapStateToProps, null)(SEO);

// const openGraphTags = [
//   { property: "og:type", content: "website" },
//   {
//     property: "og:site_name",
//     content:
//       "Loop | We help you find a nearby, small group where you fit in",
//   },
//   { property: "og:locale", content: "en_US" },
//   { property: "og:url", content: "https://www.looop.cool/" },
//   { property: "og:image:alt", content: "The Loop Network logo" },
//   { property: "og:image", content: "%PUBLIC_URL%/twitter_card.webp" },
//   {
//     property: "og:title",
//     content: "We help you find a nearby, small group where you fit in",
//   },
//   {
//     property: "og:description",
//     content:
//       "Loop is about community & discovering the incredible things around you.",
//   },
// ];

// const twitterCardTags = [
//   {
//     name: `twitter:card`,
//     content: "summary",
//   },
//   {
//     name: `twitter:title`,
//     content:
//       "Loop ✌️ | We help you find a nearby, small group where you fit in",
//   },
//   {
//     name: `twitter:image:alt`,
//     content: "The Loop Network logo",
//   },
//   {
//     name: `twitter:image`,
//     content: "The Loop Network logo",
//   },
//   {
//     name: `twitter:description`,
//     content:
//       "Loop is about community & discovering the incredible things around you.",
//   },
// ];
