/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Reducers
import uiReducer from "./uiReducer";
import ruleReducer from "./ruleReducer";
import feedReducer from "./feedReducer";
import userReducer from "./userReducer";
import sessionReducer from "./sessionReducer";
import contentReducer from "./contentReducer";
import discoverReducer from "./discoverReducer";
import leaderHubReducer from "./leaderHubReducers";
import leaderboardReducer from "./leaderboardReducer";
import notificationReducer from "./notificationReducer";
import activeProfileReducer from "./activeProfileReducer";
import walletReducer from "./walletReducer";

export const combinedReducer = {
  UI: uiReducer,
  rules: ruleReducer,
  feeds: feedReducer,
  user: userReducer,
  content: contentReducer,
  session: sessionReducer,
  discover: discoverReducer,
  leaderHub: leaderHubReducer,
  leaderboard: leaderboardReducer,
  notifications: notificationReducer,
  activeProfile: activeProfileReducer,
  wallet: walletReducer,
};
