/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This handler function debounces a given function 
 * until an alloted pause is recorded. 
 * 
 * @param {function} func - the debounced function
 * @param {number} time - time in milleseconds
 * @returns {function}
 */
export const debounce = (func, time) => {
  let timerId = undefined;
  return (...args) => {
    if (timerId) clearTimeout(timerId);
    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(func(...args)), time);
    });
  };
};
