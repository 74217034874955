/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";

// Material UI
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const StyledSecondary = styled("span")(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 500,
}));

const StyledMemberSetError = ({ error, resetErrorBoundary }) => {
  return (
    <React.Fragment>
      <Grid
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        sx={{ mt: 1, mx: 1 }}
        xs={10}
      >
        <Grid item xs={12}>
          <Typography variant="body1" color="textPrimary">
            <StyledSecondary>Community Members</StyledSecondary> failed to load
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mt: 0.5, mb: 1.5 }}
          >
            Try refreshing your browser window, or click "Try again".
          </Typography>
        </Grid>
      </Grid>
      <Button sx={{ py: 0.5, px: 1 }} onClick={resetErrorBoundary}>
        Try again
      </Button>
    </React.Fragment>
  );
};
 
export default StyledMemberSetError;
