/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Components
import StyledUserAvatar from "../../Avatars/styledAvatars/styledUserAvatar";
import StyledPersonAvatar from "../../Avatars/styledAvatars/styledPersonAvatar";

// Material UI
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";

const BaseUserSearchElement = ({
  user,
  userHandle,
  recents = {},
  isSearch = true,
  isRecent = false,
  handleSearchClick,
  recentHandles = [],
  userCommunityHandle,
}) => {
  const handleClick = (e, user) => {
    saveSearchToRecent(user, isSearch, recents, recentHandles);
    return handleSearchClick(e, user.handle);
  };

  return (
    <ListItemButton
      sx={{ borderRadius: 1, p: 1 }}
      onClick={(e) => handleClick(e, { ...user })}
      disableGutters
    >
      {user.imageUrl && user.imageUrlThumbs && (
        <ListItemAvatar>
          {userHandle === user.handle ? (
            <StyledUserAvatar />
          ) : (
            <StyledPersonAvatar
              image={user.imageUrl ?? null}
              imageSet={user.imageUrlThumbs ?? {}}
              isMember={
                user["community.communityHandle"] === userCommunityHandle
              }
            />
          )}
        </ListItemAvatar>
      )}
      {isRecent && (
        <ListItemIcon>
          <HistoryRoundedIcon />
        </ListItemIcon>
      )}
      <ListItemText
        primary={`${user.firstName} ${user.lastName}`}
        secondary={`${user["community.communityHandle"]}`}
      />
    </ListItemButton>
  );
};

const mapStateToProps = (state) => ({
  userHandle: state.user.core.handle,
  userCommunityHandle: state.user.core.community.communityHandle,
});

BaseUserSearchElement.propTypes = {
  user: PropTypes.object,
  userHandle: PropTypes.string,
  isRecentSearch: PropTypes.bool,
  handleSearchClick: PropTypes.func,
  userCommunityHandle: PropTypes.string,
};

export default connect(mapStateToProps, null)(BaseUserSearchElement);

/**
 * This helper function adds recently searched for
 * users to local storage.
 *
 * @param {object} recents - the recently searched/tagged users
 * @param {array} recentHandles - the recently searched/tagged keys
 * @param {object} user - the user object that was clicked
 * @param {boolean} isSearch - should use recentSearches object
 * @returns the localStorage setter for the recentSearches
 */
const saveSearchToRecent = (user, isSearch, recents, recentHandles) => {
  const noRecentSearches = Object.keys(recents).length === 0;
  const hasPreviousSearches =
    recentHandles.length > 0 && !recentHandles.includes(user.handle);
  if (noRecentSearches || hasPreviousSearches) {
    return addSearchToRecent(recents, user, isSearch);
  }
};

/**
 * This helper function adds the clicked user to the
 * recently clicked searches.
 *
 * @param {object} searches - the recently searched users
 * @param {object} user - the user object that was clicked
 * @param {boolean} isSearch - should use recentSearches object
 * @returns the localStorage setter for the recentSearches
 */
const addSearchToRecent = (searches, user, isSearch) => {
  let recent = updateRecent(user, searches);
  return localStorage.setItem(
    isSearch ? "recentSearches" : "recentTags",
    JSON.stringify(recent)
  );
};

/**
 * This helper function creates the updated recent
 * searches object.
 *
 * @param {object} user - the user object that was clicked
 * @param {object} searches - the recently searched users
 * @returns {object} the updated recent searches object
 */
const updateRecent = (user, searches) => ({
  [user.handle]: {
    handle: user.handle,
    lastName: user.lastName,
    firstName: user.firstName,
    "community.communityHandle": user["community.communityHandle"],
  },
  ...searches,
});
