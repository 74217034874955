/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Mixpanel
import mixpanel from "mixpanel-browser";

window.mixpanel = mixpanel
let env_check = process.env.NODE_ENV === "production";

if (env_check) mixpanel.init("7ddefc486b3a6f5be5f81921515743b6");
else mixpanel.init("f51b7c10e9ccb84c2a2dd95291b98f19", { debug: true });

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
    set_once: (props) => {
      if (env_check) mixpanel.people.set_once(props);
    },
  },
  time_event: (name) => {
    mixpanel.time_event(name);
  },
};

export let Mixpanel = actions;
