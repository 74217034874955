/**
 * Copyright 2022 Loop Technology, Inc.
 */

let DEFAULT_POSITION = {
  width: 100,
  height: 100,
  rotation: 0,
  y: 0,
  x: 0,
};

/**
 * This function extracts either the v1 or the v2 (optimized)
 * images from the user core object. If the browser has webP
 * present then the webp version is used.
 *
 * @param {object} image - the profileImage object
 * @param {string} image.optimized - the v2 optimized & cropped jpeg image url
 * @param {object} image.optimizedThumbs - - the smaller optimized & cropped jpeg image urls
 * @param {string} image.optimized_webp - the v2 optimized & cropped webp image url
 * @param {object} image.optimizedThumbs_webp - the smaller optimized & cropped webp image urls
 * @param {string} imageUrl - the v1 image url
 * @param {object} imageUrlThumbs - the v1 smaller image urls
 * @param {boolean} supportsWebp - indictor for webp browser support
 * @param {string} type - the type of image extracted
 * @returns {object} the image and thumbs
 */
export const extractImagesFromStorage = (
  image,
  imageUrl,
  imageUrlThumbs,
  supportsWebp,
  type = "profile"
) => {
  // if there is no profileImage
  if (
    (typeof imageUrl === "string" && type !== "profile") ||
    (!(image?.optimized ?? null) && type === "profile")
  )
    return { thumbs: imageUrlThumbs, image: imageUrl };
  const thumbs = parseImageUrls(
    image[supportsWebp ? "optimizedThumbs_webp" : "optimizedThumbs"]
  );
  return {
    thumbs,
    image: image[supportsWebp ? "optimized_webp" : "optimized"].imageUrl,
  };
};

/**
 * This function extracts either the v1 or the v2 (optimized)
 * images from the user core object. If the browser has webP
 * present then the webp version is used.
 *
 * @param {object} image - the profileImage object
 * @param {string} image.optimized - the v2 optimized & cropped jpeg image url
 * @param {object} image.optimizedThumbs - - the smaller optimized & cropped jpeg image urls
 * @param {string} image.optimized_webp - the v2 optimized & cropped webp image url
 * @param {object} image.optimizedThumbs_webp - the smaller optimized & cropped webp image urls
 * @param {string} imageUrl - the v1 image url
 * @param {object} imageUrlThumbs - the v1 smaller image urls
 * @param {boolean} supportsWebp - indictor for webp browser support
 * @returns {object} the image and thumbs
 */
export const extractOgImagesFromStorage = (image, imageUrl, supportsWebp) => {
  const position = image?.optimized ?? DEFAULT_POSITION;
  if (typeof imageUrl === "string" && !(image?.optimized ?? null)) {
    return { image: imageUrl, position };
  }
  return {
    image: image[supportsWebp ? "og_webp" : "og"]?.imageUrl ?? null,
    position,
  };
};

/**
 * This helper function parses through the nested
 * v2 keys and returns the urls.
 *
 * @param {object} thumbs - the primary thumbs
 * @returns {object} the parsed object
 */
const parseImageUrls = (thumbs) => {
  let parsed = {};
  Object.keys(thumbs).forEach((k, i) => {
    parsed[k] = thumbs[k].imageUrl;
  });
  return parsed;
};
