/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// Components
import BaseMenu from "../baseMenu";
import StyledBaseButton from "../../Buttons/styledBaseButton";
import StyledUserDetailsMenuHeader from "../../../FeatureSpecific/UserDetailsMenu/styledUserDetailsMenuHeader";
import StyledUserDetailsMenuDescription from "../../../FeatureSpecific/UserDetailsMenu/styledUserDetailsMenuDescription";
import { calculateCharacteristicsOverlap } from "../../../../functions/calculateCharacteristicsOverlap";

// Material UI
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";

const StyledDiv = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 300,
  padding: theme.spacing(1),
}));

const BASE_ORIGIN = {
  vertical: "center",
  horizontal: "center",
};

// Component
const StyledUserDetailsMenu = ({
  handle,
  isLeader,
  anchorEl,
  userHandle,
  firstName,
  lastName,
  pronouns,
  activities,
  lifestyles,
  communityHandle,
  handleMenuClose,
  userCharacteristics,
}) => {
  const similarityMetric = calculateCharacteristicsOverlap(
    { activities, lifestyles },
    userCharacteristics.activities,
    userCharacteristics.lifestyles
  );
  return (
    <BaseMenu
      gutters={false}
      anchorEl={anchorEl}
      anchorOrigin={BASE_ORIGIN}
      transformOrigin={BASE_ORIGIN}
      handleMenuClose={handleMenuClose}
      name={`Loop @${handle}'s - Details Menu`}
    >
      <StyledDiv>
        <StyledUserDetailsMenuHeader
          handle={handle}
          lastName={lastName}
          pronouns={pronouns}
          firstName={firstName}
          similarityMetric={similarityMetric}
          communityHandle={communityHandle}
          handleMenuClose={handleMenuClose}
        />
        <StyledUserDetailsMenuDescription
          isLeader={isLeader}
          activities={activities}
          lifestyles={lifestyles}
          characteristics={userCharacteristics}
          type={handle === userHandle ? "user" : "people"}
        />
        <Divider
          sx={{ borderColor: "grey.50", mt: 0.5, mb: 1, width: "100%" }}
        />
        <StyledBaseButton
          text="View Profile"
          onClick={handleMenuClose}
          component={NavLink}
          color="success"
          colorType="main"
          sx={{
            width: "100%",
            py: 1.75,
            px: 2,
            fontSize: 16,
          }}
          to={`/profile/${handle}`}
        />
      </StyledDiv>
    </BaseMenu>
  );
};

StyledUserDetailsMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleMenuClose: PropTypes.func,
  handle: PropTypes.string,
  communityHandle: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  pronouns: PropTypes.string,
  activities: PropTypes.array,
  lifestyles: PropTypes.array,
  isLeader: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userHandle: state.user.core.handle,
  userCharacteristics: state.user.core.details.characteristics,
});

export default connect(mapStateToProps)(StyledUserDetailsMenu);
