/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */

import {
  setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";
import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

import {
  //General Notifications
  CLEAR_GENERAL_NOTIFICATIONS,
  CLEAR_GENERAL_NOTIFICATION_COUNT,
  LOADING_GENERAL_NOTIFICATIONS,
  SET_GENERAL_NOTIFICATIONS,
  SET_GENERAL_NOTIFICATION_COUNT,
  SET_NEW_GENERAL_NOTIFICATIONS,
  CLEAR_GENERAL_NOTIFICATIONS_ERRORS,
  SET_GENERAL_NOTIFICATIONS_ERRORS,
  REMOVE_GENERAL_NOTIFICATION,

  //Friend Requests
  CLEAR_FRIEND_REQUEST,
  CLEAR_FRIEND_REQUESTS_COUNT,
  LOADING_FRIEND_REQUESTS,
  SET_NEW_FRIEND_REQUESTS,
  SET_FRIEND_REQUESTS,
  SET_FRIEND_REQUESTS_COUNT,
  CLEAR_FRIEND_REQUESTS_ERRORS,
  SET_FRIEND_REQUESTS_ERRORS,
  REMOVE_FRIEND_REQUEST,
} from "../actionTypes/notificationTypes";

const initState = {
  general: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    count: 0,
    lastDate: null,
    lastId: null,
    notifications: {},
    lastUpdated: null,
    isEndOfNotifications: null,
  },
  friendRequests: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    count: 0,
    lastDate: null,
    lastId: null,
    notifications: {},
    lastUpdated: null,
    isEndOfNotifications: null,
  },
  rewards: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    count: 0,
    lastDate: null,
    lastId: null,
    notifications: {},
    lastUpdated: null,
    isEndOfNotifications: null,
  },
  election: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    count: 0,
    lastDate: null,
    lastId: null,
    notifications: {},
    lastUpdated: null,
    isEndOfNotifications: null,
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case LOADING_GENERAL_NOTIFICATIONS:
      return loadingBaseReducer(state, "general");
    case LOADING_FRIEND_REQUESTS:
      return loadingBaseReducer(state, "friendRequests");

    // Success Types
    // MARK AS READ
    case CLEAR_GENERAL_NOTIFICATIONS:
      return setBaseReducer(state, "general", null, {
        l1: { ...initState.general },
      });
    case CLEAR_FRIEND_REQUEST:
      return setBaseReducer(state, "friendRequests", null, {
        l1: { ...initState.friendRequests },
      });
    case CLEAR_GENERAL_NOTIFICATION_COUNT:
      return setBaseReducer(state, "general", null, {
        l1: { ...state.general, count: 0 },
      });
    case CLEAR_FRIEND_REQUESTS_COUNT:
      return setBaseReducer(state, "friendRequests", null, {
        l1: { ...state.friendRequests, count: 0 },
      });
    case SET_GENERAL_NOTIFICATIONS:
      return setBaseReducer(state, "general", null, {
        l1: {
          lastUpdated: new Date().toISOString(),
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate, // Date of last notification
          isEndOfNotifications: action.payload.isEndOfDocs,
          notifications: {
            ...state.general.notifications,
            ...action.payload.data,
          },
        },
      });
    case SET_FRIEND_REQUESTS:
      return setBaseReducer(state, "friendRequests", null, {
        l1: {
          lastUpdated: new Date().toISOString(),
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate, // Date of last notification
          isEndOfNotifications: action.payload.isEndOfDocs,
          notifications: {
            ...state.friendRequests.notifications,
            ...action.payload.data,
          },
        },
      });
    case SET_GENERAL_NOTIFICATION_COUNT:
      return setBaseReducer(state, "general", null, {
        l1: {
          ...state.general,
          count: state.general.count + action.payload,
        },
      });
    case SET_FRIEND_REQUESTS_COUNT:
      return setBaseReducer(state, "friendRequests", null, {
        l1: {
          ...state.friendRequests,
          count: state.friendRequests.count + action.payload,
        },
      });
    case SET_NEW_GENERAL_NOTIFICATIONS:
      return setBaseReducer(state, "general", null, {
        l1: {
          lastUpdated: new Date().toISOString(),
          notifications: {
            ...action.payload,
            ...state.general.notifications,
          },
        },
      });
    case SET_NEW_FRIEND_REQUESTS:
      return setBaseReducer(state, "friendRequests", null, {
        l1: {
          lastUpdated: new Date().toISOString(),
          notifications: {
            ...action.payload,
            ...state.friendRequests.notifications,
          },
        },
      });
    case REMOVE_GENERAL_NOTIFICATION:
      const { [action.payload]: notification, ...remainingNotifications } =
        state.general.notifications;
      return setBaseReducer(state, "general", null, {
        l1: {
          lastUpdated: new Date().toISOString(),
          notifications: { ...remainingNotifications },
        },
      });
    case REMOVE_FRIEND_REQUEST:
      const { [action.payload]: friendRequest, ...remainingFriendRequests } =
        state.friendRequests.notifications;
      return setBaseReducer(state, "friendRequests", null, {
        l1: {
          lastUpdated: new Date().toISOString(),
          notifications: { ...remainingFriendRequests },
        },
      });

    // Failed Types
    case SET_GENERAL_NOTIFICATIONS_ERRORS:
      return setErrorsBaseReducer(state, "general", null, {
        error: action.payload,
      });
    case SET_FRIEND_REQUESTS_ERRORS:
      return setErrorsBaseReducer(state, "friendRequests", null, {
        error: action.payload,
      });

    // Idle Types
    case CLEAR_GENERAL_NOTIFICATIONS_ERRORS:
      return clearErrorsBaseReducer(state, "general");
    case CLEAR_FRIEND_REQUESTS_ERRORS:
      return clearErrorsBaseReducer(state, "friendRequests");
    default:
      return state;
  }
}
