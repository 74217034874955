/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { addUserCharacteristics } from "../../../../../redux/actions/user/atomic/post/addUserCharacteristics";

// Form Components
import { Formik, Form, useFormikContext } from "formik";
import charactersticsSchema from "./validationSchemas/charactersticsSchema";
import CharacteristicsFormSections from "./styledCharacteristicComponents/charactersiticsFormSections";

// Material UI
import Grid from "@mui/material/Grid";

const StyledNewCharacteristicsForm = ({
  formRef,
  handleNext,
  initialState,
  handleValidate,
  addUserCharacteristics,
}) => {
  /**
   * This helper component auto-detects if there is
   * a match between the values in the form and toggles
   * the accepted State
   * @returns {null}
   */
  const AutoDetectValid = () => {
    const { values, errors, isSubmitting } = useFormikContext();
    useEffect(() => {
      handleValidate(values, errors, isSubmitting);
    }, [values, errors, isSubmitting]);
    return null;
  };

  /**
   * This handler function submits the users updated
   * characteristic values.
   *
   * @param {object} values - the form values
   * @param {object} helpers - the form helper function
   */
  const handleSubmit = (values, helpers) => {
    helpers.setSubmitting(true);
    addUserCharacteristics(values)
      .then(() => {
        helpers.setSubmitting(false);
        handleNext();
      })
      .catch(() => {
        helpers.setSubmitting(false);
      });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={initialState}
      validationSchema={charactersticsSchema}
      onSubmit={(values, helpers) => handleSubmit(values, helpers)}
    >
      {({ values }) => (
        <Form>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <CharacteristicsFormSections values={values} />
          </Grid>
          <AutoDetectValid />
        </Form>
      )}
    </Formik>
  );
};

const mapActionsToProps = {
  addUserCharacteristics,
};

StyledNewCharacteristicsForm.propTypes = {
  handleNext: PropTypes.func,
  addUserCharacteristics: PropTypes.func,
  isDialog: PropTypes.bool,
  formRef: PropTypes.object,
  initialState: PropTypes.object,
  handleValidate: PropTypes.func,
};

export default connect(null, mapActionsToProps)(StyledNewCharacteristicsForm);
