/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This helper function assumes a leading "/" in the
 * url route, and returns the base route in the
 * url.
 *
 * @param {string} pathname the url route
 * @returns {string} the base route name ("/profile/user" => "/profile")
 */
export const getBaseRoute = (pathname) => {
  return pathname ? `/${pathname.split("/")[1]}` : null;
};
