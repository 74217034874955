/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import List from "@mui/material/List";

const BaseList = ({ dense, children, ...props }) => {
  return (
    <List dense={dense} {...props}>
      {children}
    </List>
  );
};

BaseList.propTypes = {
  dense: PropTypes.bool,
  children: PropTypes.node,
};

export default BaseList;
