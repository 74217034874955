/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import StyledIconButton from "../../UI/Buttons/styledIconButton";
import StyledRuleMenu from "../../UI/Menus/Rules/ruleMenu";
import { getRuleLevelStylesHelper } from "../../../functions/getRuleLevelStylesHelper";

// Material UI
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Typography from "@mui/material/Typography";

const StyledRule = ({ rule, handleDialogClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { sxColor } = getRuleLevelStylesHelper(
    rule.ruleDetails.content.importanceLevel
  );
  
  /**
   * This handler function toggles the
   * rule menu open
   *
   * @param {object} event the event object
   */
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * This handler function toggles the rule
   * menu closed
   */
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{ px: 1.5, my: 1.5 }}
      >
        <Grid item xs={1}>
          <Badge
            variant="dot"
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: sxColor,
              },
            }}
            showZero
          />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1" color="textSecondary">
            {rule.ruleDetails.content.title}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <StyledIconButton
            size="small"
            onClick={handleMenuClick}
            sxProps={{ width: 30, height: 30 }}
          >
            <SettingsRoundedIcon
              fontSize="small"
              sx={{ color: "text.secondary" }}
            />
          </StyledIconButton>
          {anchorEl ? (
            <StyledRuleMenu
              anchorEl={anchorEl}
              handleMenuClose={handleMenuClose}
              handleSuggestChange={(e) => handleDialogClick(e, "change", rule)}
              handleSuggestRemove={(e) => handleDialogClick(e, "removal", rule)}
              handleViewDetails={(e) => handleDialogClick(e, "details", rule)}
            />
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

StyledRule.propTypes = {
  rule: PropTypes.object,
  handleDialogClick: PropTypes.func,
};

export default StyledRule;
