/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// Components
// import MobileDeviceNavLinks from "./styledLinks/MobileDeviceNavLinks";
//import LargeDeviceNavLinks from "./styledLinks/LargeDeviceNavLinks";
import { ReactComponent as Logo } from "../../../../../images/loop_logo.svg";
import DarkModeSwitch from "../../../Toggle/darkModeSwitch";

// Material UI
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

const StyledNavLinks = ({ handleDarkMode }) => {
  // const [open, setOpen] = useState(false);

  // /**
  //  * This helper function toggles the open
  //  * state mobile nav drawer.
  //  *
  //  * @param {object} e - the event object
  //  * @returns {null}
  //  */
  // const toggleDrawer = (e, open) => {
  //   if (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift"))
  //     return;
  //   setOpen(open);
  // };

  return (
    <Toolbar>
      <IconButton
        size="small"
        component={NavLink}
        to="/"
        sx={{ mt: 1 }}
        aria-label="Home"
      >
        <Logo height={45} width={45} aria-label="Loop Logo" />
      </IconButton>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <DarkModeSwitch handleDarkMode={handleDarkMode} />
        {/* <Box sx={{ display: { xs: "none", md: "block" } }}>
          <LargeDeviceNavLinks />
        </Box> */}
        {/* <MobileDeviceNavLinks
          open={open}
          toggleDrawer={toggleDrawer}
          handleDarkMode={handleDarkMode}
        /> */}
      </Grid>
    </Toolbar>
  );
};

StyledNavLinks.propTypes = {
  handleDarkMode: PropTypes.func.isRequired,
};

export default StyledNavLinks;
