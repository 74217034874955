/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Adventures by Day
export const SET_ADVENTURES_BY_DAY = "SET_ADVENTURES_BY_DAY"
export const LOADING_ADVENTURES_BY_DAY = "LOADING_ADVENTURES_BY_DAY"
export const SET_ADVENTURES_BY_DAY_ERRORS = "SET_ADVENTURES_BY_DAY_ERRORS"
export const CLEAR_ADVENTURES_BY_DAY_ERRORS = "CLEAR_ADVENTURES_BY_DAY_ERRORS"

// Adventures by Type
export const SET_ADVENTURES_BY_TYPE = "SET_ADVENTURES_BY_TYPE"
export const LOADING_ADVENTURES_BY_TYPE = "LOADING_ADVENTURES_BY_TYPE"
export const SET_ADVENTURES_BY_TYPE_ERRORS = "SET_ADVENTURES_BY_TYPE_ERRORS"
export const CLEAR_ADVENTURES_BY_TYPE_ERRORS = "CLEAR_ADVENTURES_BY_TYPE_ERRORS"

// New Faces Recommendation
export const SET_NEW_FACES_RECOMMENDATION = "SET_NEW_FACES_RECOMMENDATION"
export const LOADING_NEW_FACES_RECOMMENDATION = "LOADING_NEW_FACES_RECOMMENDATION"
export const SET_NEW_FACES_RECOMMENDATION_ERRORS = "SET_NEW_FACES_RECOMMENDATION_ERRORS"
export const CLEAR_NEW_FACES_RECOMMENDATION_ERRORS = "CLEAR_NEW_FACES_RECOMMENDATION_ERRORS"