/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { createTwoTruthsPost } from "../../../../../redux/actions/posts/atomic/post/createTwoTruthsPost";

// Formik
import twoTruthsSchema from "./validationSchemas/twoTruthsSchema";
import { useFormikContext } from "formik";

// Components
import BaseForm from "../../baseForm";
import StyledInputField from "../../styledComponents/styledInputField";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Base State Object
const BASE_STATE = {
  truthOne: "",
  truthTwo: "",
  lie: "",
};

const TwoTruthsForm = ({
  formRef,
  handleClick,
  handleValidate,
  createTwoTruthsPost,
}) => {
  /**
   * This submit handler function is used
   * to update the core details.
   *
   * @param {object} data
   */
  const handleSubmit = async (data, helpers) => {
    helpers.setSubmitting(true);
    await createTwoTruthsPost(data);
    helpers.setSubmitting(false);
  };

  /**
   * This helper component auto-detects if there is
   * a match between the values in the form and toggles
   * the accepted State
   * @returns {null}
   */
  const AutoDetectValid = () => {
    const { values, errors, isSubmitting } = useFormikContext();
    useEffect(() => {
      handleValidate(values, errors, isSubmitting, "twoTruths");
    }, [values, errors, isSubmitting]);
    return null;
  };

  return (
    <BaseForm
      formRef={formRef}
      data={BASE_STATE}
      handleSubmit={handleSubmit}
      validationSchema={twoTruthsSchema}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1" align="center">
            Let's play, 2 Truths and a Lie
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body1" color="textSecondary" sx={{ py: 1 }}>
            Write down two things true about you, and one thing that is not
            true. Your community will try and correctly to guess which one is
            not the truth.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledInputField
            color="primary"
            name="truthOne"
            label="Truth One"
            required
          />
          <StyledInputField
            color="primary"
            name="truthTwo"
            label="Truth Two"
            required
          />
          <StyledInputField color="primary" name="lie" label="Lie" required />
        </Grid>
      </Grid>
      <AutoDetectValid />
    </BaseForm>
  );
};

TwoTruthsForm.propTypes = {
  formRef: PropTypes.object,
  handleClick: PropTypes.func,
  handleValidate: PropTypes.func,
  createTwoTruthsPost: PropTypes.func,
};

const mapActionsToProps = {
  createTwoTruthsPost,
};

export default connect(null, mapActionsToProps)(TwoTruthsForm);
