/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import BaseMenu from "../baseMenu";
import StyledIconElement from "./styledIconElement";

// Material UI
import { alpha } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";

const StyledSimpleMenu = ({
  name,
  anchorEl,
  handleMenuClose,
  elements,
  isMobile,
  color = "text",
  colorType = "primary",
}) => {
  return (
    <BaseMenu name={name} anchorEl={anchorEl} handleMenuClose={handleMenuClose}>
      {elements.map((item, index) => (
        <MenuItem
          onClick={item.handler}
          key={`menu-action-${index}`}
          sx={{
            borderRadius: 1,
            "&:focus": {
              backgroundColor: "transparent",
            },
            "&:hover": {
              backgroundColor: (theme) =>
                alpha(theme.palette[color][colorType], 0.06),
            },
          }}
        >
          <StyledIconElement item={item} textColor />
        </MenuItem>
      ))}
    </BaseMenu>
  );
};

StyledSimpleMenu.propTypes = {
  name: PropTypes.string,
  anchorEl: PropTypes.object,
  handleMenuClose: PropTypes.func,
  elements: PropTypes.array,
  isMobile: PropTypes.bool,
};

export default StyledSimpleMenu;
