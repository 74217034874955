/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { NavLink, useLocation } from "react-router-dom";

// Components
import StyledTooltip from "../../../../Tooltip/styledTooltip";
import { ReactComponent as UnselectedLogo } from "../../../../../../images/feed_white.svg";
import { ReactComponent as SelectedLogo } from "../../../../../../images/feed.svg";
import { ReactComponent as UnselectedDisco } from "../../../../../../images/disco_white.svg";
import { ReactComponent as SelectedDisco } from "../../../../../../images/disco.svg";
import { ReactComponent as UnselectedProfile } from "../../../../../../images/profile_white.svg";
import { ReactComponent as SelectedProfile } from "../../../../../../images/profile.svg";

// Material UI
import { styled, alpha } from "@mui/system";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

// Lazy Loading Imports
const StyledNavAvatarButton = lazy(() =>
  import("../Buttons/styledNavAvatarButton")
);
const StyledPlatformButton = lazy(() =>
  import("../Buttons/styledPlatformButton")
);
const StyledNavLink = styled("div")(() => ({
  marginRight: "8px",
}));

const loadingButton = (size, mr) => (
  <React.Fragment>
    <Skeleton variant="circular" sx={{ width: size, height: size, mr: mr }} />
  </React.Fragment>
);

const LargeDeviceAppLinks = ({
  isTabletDown,
  handleDarkMode,
  anchorNotifEl,
  anchorWalletEl,
  handleMenuClick,
  handleMenuClose,
  handleDialogClick,
  handleUserLogout,
  anchorSettingsEl,
  anchorReferralEl,
  anchorFriendRequestEl,
}) => {
  const location = useLocation();
  return (
    <React.Fragment>
      <Grid
        item
        xs={3}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <StyledTooltip title="Profile" placement="bottom">
            <IconButton
              sx={{
                mx: 0.5,
                "&:hover": {
                  backgroundColor: (theme) =>
                    location.pathname === "/profile"
                      ? alpha(theme.palette.primary.main, 0.08)
                      : alpha(theme.palette.grey[400], 0.1),
                },
              }}
              component={NavLink}
              to="/profile"
              size="default"
            >
              {location.pathname === "/profile" ? (
                <SelectedProfile height={30} width={30} />
              ) : (
                <UnselectedProfile height={30} width={30} />
              )}
            </IconButton>
          </StyledTooltip>
        </Grid>
        <Grid item>
          <StyledTooltip title="Community Feed" placement="bottom">
            <Button
              sx={{
                color: "grey.200",
                mx: 1.5,
                minWidth: 0,
                "&:hover": {
                  backgroundColor: (theme) =>
                    location.pathname === "/community"
                      ? alpha(theme.palette.info.main, 0.08)
                      : alpha(theme.palette.grey[400], 0.1),
                },
              }}
              size="small"
              component={NavLink}
              to="/community"
            >
              {location.pathname === "/community" ? (
                <SelectedLogo height={35} width={35} />
              ) : (
                <UnselectedLogo height={35} width={35} />
              )}
            </Button>
          </StyledTooltip>
        </Grid>
        <Grid item>
          <StyledTooltip title="Discover" placement="bottom">
            <IconButton
              sx={{
                mx: 0.5,
                "&:hover": {
                  backgroundColor: (theme) =>
                    location.pathname === "/discover"
                      ? alpha(theme.palette.secondary.main, 0.08)
                      : alpha(theme.palette.grey[400], 0.1),
                },
              }}
              component={NavLink}
              to="/discover"
              size="default"
            >
              {location.pathname === "/discover" ? (
                <SelectedDisco height={30} width={30} />
              ) : (
                <UnselectedDisco height={30} width={30} />
              )}
            </IconButton>
          </StyledTooltip>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Suspense fallback={loadingButton(45, isTabletDown ? 0.5 : 1)}>
          <StyledNavLink>
            <StyledPlatformButton
              anchorEl={anchorNotifEl}
              isTabletDown={isTabletDown}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
            />
          </StyledNavLink>
        </Suspense>
        <Suspense fallback={loadingButton(48, 0)}>
          <StyledNavAvatarButton
            anchorEl={anchorSettingsEl}
            handleMenuClick={handleMenuClick}
            handleUserLogout={handleUserLogout}
            handleMenuClose={handleMenuClose}
            handleReportBugClick={handleDialogClick}
            handleDarkMode={handleDarkMode}
          />
        </Suspense>
      </Grid>
    </React.Fragment>
  );
};

LargeDeviceAppLinks.propTypes = {
  isTabletDown: PropTypes.bool,
  handleDarkMode: PropTypes.func.isRequired,
  handleMenuClose: PropTypes.func.isRequired,
  handleMenuClick: PropTypes.func.isRequired,
  handleDialogClick: PropTypes.func.isRequired,
  handleUserLogout: PropTypes.func.isRequired,
  anchorNotifEl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  anchorSettingsEl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  anchorReferralEl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  anchorFriendRequestEl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

export default LargeDeviceAppLinks;
