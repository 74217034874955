/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import { NavLink } from "react-router-dom";

// Functions
import { navDrawerLinks } from "../styledComponents/drawerLinks";
import StyledFooterMessage from "./styledFooterMessage";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { styled, Box } from "@mui/system";

const StyledFooter = styled("footer")(({ theme }) => ({
  padding: theme.spacing(0.75),
  backgroundColor: theme.palette.primary.main,
  marginTop: theme.spacing(1),
}));

const StyledFooterSection = styled("div")(() => ({
  marginTop: "auto",
}));

const Footer = ({ cookieMsgOpen, handleCookieApproval, isTabletDown }) => {
  return (
    <StyledFooterSection>
      <StyledFooterMessage
        handleCookieApproval={handleCookieApproval}
        isTabletDown={isTabletDown}
        cookieMsgOpen={cookieMsgOpen}
      />
      <StyledFooter>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            {navDrawerLinks.map((link, index) => (
              <React.Fragment key={index}>
                <Box textAlign="center">
                  <Button
                    sx={{
                      color: "primary.contrastText",
                      py: 1,
                      px: 2,
                    }}
                    component={link.type === "nav" ? NavLink : link.type}
                    to={link.type === "nav" ? link.route : null}
                    href={link.type === "a" ? link.route : null}
                  >
                    {link.text}
                  </Button>
                </Box>
              </React.Fragment>
            ))}
          </Grid>
          <Grid item xs={12} sx={{ color: "primary.contrastText", mb: 0.5 }}>
            <Typography align="center" variant="body2">
              Proudly made with{" "}
              <span role="img" aria-label="coffee">
                ☕️
              </span>{" "}
              and{" "}
              <span role="img" aria-label="heart">
                ❤️
              </span>{" "}
              in Austin, TX{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ color: "primary.contrastText", mb: 0.5 }}>
            <Typography align="center" variant="body2">
              © {new Date().getFullYear()}
              {` `}
              Loop Technology, Inc.
            </Typography>
          </Grid>
        </Grid>
      </StyledFooter>
    </StyledFooterSection>
  );
};

export default Footer;
