/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";

// Components
import Offline from "../../../images/offline.json";
import LottieAnimation from "../Animation/LottieAnimation";
import { StyledPrimary } from "../Text/styledText";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/system";

const LayoutOffline = () => {
  const theme = useTheme();
  document.body.style.backgroundColor = theme.palette.background.default;

  return (
    <Container maxWidth="lg" sx={{ width: "100%", marginTop: "25vh" }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <LottieAnimation
          animation={Offline}
          style={{ display: "inline-block", height: "25vh" }}
        />

        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 400 }}
            color="textPrimary"
            align="center"
          >
            Your internet <StyledPrimary bold>disappeared</StyledPrimary>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textSecondary"
            align="center"
            sx={{ my: 1.5, fontWeight: 400 }}
          >
            We will be back when you return online 👍
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default React.memo(LayoutOffline);
