/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledRuleLabel from "./styledRuleLabel";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Component
const StyledRuleDetailTemplate = ({ rule }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ my: 1 }}
    >
      <StyledRuleLabel level={rule.ruleDetails.content.importanceLevel} />
      <Grid item xs={11} sx={{ my: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 0.75, fontWeight: 500 }}>
          {rule.ruleDetails.content.description}
        </Typography>
        <Typography color="textSecondary">
          {rule.ruleDetails.content.title}
        </Typography>
      </Grid>
    </Grid>
  );
};

StyledRuleDetailTemplate.propTypes = {
  rule: PropTypes.object.isRequired,
};

export default StyledRuleDetailTemplate;
