/**
 * * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";

// Components
const Lottie = lazy(() => import("react-lottie-player/dist/LottiePlayerLight"));

const LottieAnimation = ({ animation, style, ...props }) => {
  return (
    <Suspense fallback={<></>}>
      <Lottie animationData={animation} style={style} {...props} play />
    </Suspense>
  );
};

LottieAnimation.propTypes = {
  animation: PropTypes.object,
  style: PropTypes.object,
};

export default LottieAnimation;
