/**
 * * Copyright 2022 Loop Technology, Inc.
 */

//React
import React from "react";
import PropTypes from "prop-types";

// Form Components
import { useField } from "formik";
import useAlgoliaSearch from "../../../../hooks/useAlgoliaSearch";
import StyledIconButton from "../../../UI/Buttons/styledIconButton";
import BaseSearchElement from "./baseSearchElement";

// Material UI
import { alpha } from "@mui/system";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/CloseRounded";
import Typography from "@mui/material/Typography";

// Styles
const useStyles = (color, colorType) =>
  makeStyles((theme) => ({
    search: {
      position: "relative",
      borderRadius: theme.spacing(3),
      backgroundColor: alpha(theme.palette[color][colorType], 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette[color][colorType], 0.25),
      },
      width: "100%",
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: theme.palette.text.primary,
      height: theme.spacing(6),
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      fontSize: theme.spacing(2),
    },
    icon: {
      color: alpha(theme.palette[color][colorType], 0.65),
    },
    menuItem: {
      margin: theme.spacing(0.5, 0),
      padding: theme.spacing(1, 2),
      maxWidth: 400,
      borderRadius: theme.spacing(1),
      whiteSpace: "normal",
      "&:hover": {
        borderRadius: theme.spacing(1),
        backgroundColor: alpha(theme.palette[color][colorType], 0.1),
      },
    },
    emptyMessage: {
      color: theme.palette.grey[600],
      margin: theme.spacing(0.5, 0),
      width: 400,
    },
    searchResults: {
      margin: theme.spacing(1.5, 0, 0.5, 0),
    },
  }));

const StyledSearchInput = ({
  placeholder = "Find something...",
  color = "secondary",
  colorType = "main",
  required = false,
  handleClose,
  ...props
}) => {
  const classes = useStyles(color, colorType)();

  // Formik Field Hook
  const [field] = useField(props);

  // Algolia Search Results
  let { results } = useAlgoliaSearch(field.value);

  return (
    <Grid container direction="row" justifyContent="space-around" alignItems="center">
      <Grid item xs={1}>
        <StyledIconButton onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </StyledIconButton>
      </Grid>
      <Grid item xs={10}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon className={classes.icon} />
          </div>
          <InputBase
            {...field}
            placeholder={placeholder}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            required={required}
            inputProps={{ "aria-label": "platform search" }}
            autoComplete="off"
            type="input"
            fullWidth
            autoFocus
          />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.searchResults}>
        {results.length > 0 ? (
          results.slice(0, 8).map((result, index) => (
            <MenuItem
              key={index}
              className={classes.menuItem}
              alignItems="center"
            >
              <BaseSearchElement name={result.name} />
            </MenuItem>
          ))
        ) : (
          <Typography
            variant="body1"
            className={classes.emptyMessage}
            align="center"
          >
            Nothing is here 🧐
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

StyledSearchInput.propTypes = {
  placeholder: PropTypes.string,
  color: PropTypes.oneOf(["secondary", "primary", "success", "text"]),
  colorTypes: PropTypes.oneOf([
    "main",
    "light",
    "dark",
    "secondary",
    "primary",
  ]),
};

export default StyledSearchInput;
