/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This helper function returns the rule types 
 * styled details. 
 * 
 * @param {string} level - the importance level for the rule
 * @returns {object} the rule details
 */
export const getRuleLevelStylesHelper = (level) => {
  switch (level) {
    case "moderate":
      return {
        title: "Moderate",
        color: "warning",
        colorType: "dark",
        sxColor: "warning.dark"
      };
    case "important":
      return {
        title: "Important",
        color: "error",
        colorType: "main",
        sxColor: "error.main",
      };
    default:
      return {
        title: "Suggested",
        color: "success",
        colorType: "main",
        sxColor: "success.main",
      };
  }
};
