/**
 * Copyright 2022 Loop Technology, Inc.
 */
// React
import React from "react";

// Material UI
import { styled, alpha } from "@mui/system";

const StyledText = styled("span", {
  shouldForwardProp: (prop) =>
    prop !== "color" &&
    prop !== "colorType" &&
    prop !== "weight" &&
    prop !== "mutedBackground" &&
    prop !== "fontWeight",
})(({ theme, mutedBackground, color, colorType, fontWeight }) =>
  !color && !colorType
    ? { fontWeight: fontWeight ?? 400 }
    : {
        fontWeight: fontWeight ?? 400,
        color: theme.palette[color ?? "text"][colorType ?? "primary"],
        backgroundColor: mutedBackground
          ? alpha(
              theme.palette[
                theme.palette.mode === "dark" ? "text" : color ?? "text"
              ][
                theme.palette.mode === "dark"
                  ? "primary"
                  : colorType ?? "primary"
              ],
              theme.palette.mode === "dark" ? 0.15 : 0.1
            )
          : "transparent",
        padding: mutedBackground ? theme.spacing(0.25, 1.25) : 0,
        borderRadius: mutedBackground ? theme.spacing(1) : 0,
        whiteSpace: mutedBackground ? "nowrap" : "normal",
      }
);

export const StyledPrimary = ({ bold, mutedBackground, children }) => (
  <StyledText
    color="primary"
    colorType="main"
    fontWeight={bold ? 500 : 400}
    mutedBackground={mutedBackground}
  >
    {children}
  </StyledText>
);
export const StyledSuccess = ({ bold, mutedBackground, children }) => (
  <StyledText
    color="success"
    colorType="main"
    fontWeight={bold ? 500 : 400}
    mutedBackground={mutedBackground}
  >
    {children}
  </StyledText>
);
export const StyledWarning = ({ bold, mutedBackground, children }) => (
  <StyledText
    color="warning"
    colorType="main"
    fontWeight={bold ? 500 : 400}
    mutedBackground={mutedBackground}
  >
    {children}
  </StyledText>
);
export const StyledSecondary = ({ bold, mutedBackground, children }) => (
  <StyledText
    color="secondary"
    colorType="main"
    fontWeight={bold ? 500 : 400}
    mutedBackground={mutedBackground}
  >
    {children}
  </StyledText>
);
export const StyledTextSecondary = ({ bold, mutedBackground, children }) => (
  <StyledText
    color="text"
    colorType="secondary"
    fontWeight={bold ? 500 : 400}
    mutedBackground={mutedBackground}
  >
    {children}
  </StyledText>
);
export const StyledTextPrimary = ({ bold, mutedBackground, children }) => (
  <StyledText
    color="text"
    colorType="primary"
    fontWeight={bold ? 500 : 400}
    mutedBackground={mutedBackground}
  >
    {children}
  </StyledText>
);
export const StyledInfo = ({ bold, mutedBackground, children }) => (
  <StyledText
    color="info"
    colorType="main"
    fontWeight={bold ? 500 : 400}
    mutedBackground={mutedBackground}
  >
    {children}
  </StyledText>
);
export const StyledError = ({ bold, mutedBackground, children }) => (
  <StyledText
    color="error"
    colorType="main"
    fontWeight={bold ? 500 : 400}
    mutedBackground={mutedBackground}
  >
    {children}
  </StyledText>
);
export const StyledBold = ({ mutedBackground, children }) => (
  <StyledText fontWeight={500} mutedBackground={mutedBackground}>
    {children}
  </StyledText>
);
