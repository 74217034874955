/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledIconButton from "../../Buttons/styledIconButton";

//Material UI
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const InfoAppbar = ({
  isTabletDown,
  infoBarRef,
  toggleInfoBarOpen,
  infoText,
}) => {
  return (
    <Toolbar
      ref={infoBarRef}
      sx={
        isTabletDown
          ? { px: "8px !important", py: 1, backgroundColor: "primary.main" }
          : { pr: "8px !important", py: 1, backgroundColor: "primary.main" }
      }
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ px: 1 }}
      >
        <Grid xs={10} item>
          <Typography
            sx={{
              color: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.text.primary
                  : theme.palette.background.default,
            }}
          >
            {infoText}
          </Typography>
        </Grid>
        <Grid xs={2} item>
          <StyledIconButton onClick={toggleInfoBarOpen} align="end">
            <CloseRoundedIcon />
          </StyledIconButton>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

InfoAppbar.propTypes = {
  isTabletDown: PropTypes.bool.isRequired,
  toggleInfoBarOpen: PropTypes.func.isRequired,
  infoText: PropTypes.oneOf([PropTypes.string, PropTypes.node]),
};

export default InfoAppbar;
