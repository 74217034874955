/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useScrollToTopOnMount = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return;
};
