/**
 * Copyright 2022 Loop Technology, Inc.
 */

import * as yup from "yup";

const searchSchema = yup.object().shape({
  query: yup.string(),
});

export default searchSchema;
