// Active Profile Action Types
//Base
export const CLEAR_ACTIVE_PROFILE = "CLEAR_ACTIVE_PROFILE";
export const SET_ACTIVE_PROFILE = "SET_ACTIVE_PROFILE";
export const LOADING_ACTIVE_PROFILE = "LOADING_ACTIVE_PROFILE";
//Core
export const SET_ACTIVE_PROFILE_CORE = "SET_ACTIVE_PROFILE_CORE";
export const LOADING_ACTIVE_PROFILE_CORE = "LOADING_ACTIVE_PROFILE_CORE";
export const SET_ACTIVE_PROFILE_CORE_ERRORS = "SET_ACTIVE_PROFILE_CORE_ERRORS";
export const CLEAR_ACTIVE_PROFILE_CORE_ERRORS = "CLEAR_ACTIVE_PROFILE_CORE_ERRORS";
//Friends
export const SET_ACTIVE_PROFILE_FRIENDS = "SET_ACTIVE_PROFILE_FRIENDS";
export const REMOVE_ACTIVE_PROFILE_FRIEND = "REMOVE_ACTIVE_PROFILE_FRIEND";
export const LOADING_ACTIVE_PROFILE_FRIENDS = "LOADING_ACTIVE_PROFILE_FRIENDS";
export const SET_ACTIVE_PROFILE_FRIENDS_ERRORS = "SET_ACTIVE_PROFILE_FRIENDS_ERRORS";
export const CLEAR_ACTIVE_PROFILE_FRIENDS_ERRORS ="CLEAR_ACTIVE_PROFILE_FRIENDS_ERRORS";
//Members
export const SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS = "SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS";
export const CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS = "CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS";
export const LOADING_ACTIVE_PROFILE_COMMUNITY_MEMBERS = "LOADING_ACTIVE_PROFILE_COMMUNITY_MEMBERS";
export const SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS = "SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS";
export const CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS ="CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS";
//Leaders
export const SET_ACTIVE_PROFILE_COMMUNITY_LEADERS = "SET_ACTIVE_PROFILE_COMMUNITY_LEADERS";
export const CLEAR_ACTIVE_PROFILE_COMMUNITY_LEADERS = "CLEAR_ACTIVE_PROFILE_COMMUNITY_LEADERS";
export const LOADING_ACTIVE_PROFILE_COMMUNITY_LEADERS = "LOADING_ACTIVE_PROFILE_COMMUNITY_LEADERS";
export const SET_ACTIVE_PROFILE_COMMUNITY_LEADERS_ERRORS = "SET_ACTIVE_PROFILE_COMMUNITY_LEADERS_ERRORS";
export const CLEAR_ACTIVE_PROFILE_COMMUNITY_LEADERS_ERRORS ="CLEAR_ACTIVE_PROFILE_COMMUNITY_LEADERS_ERRORS";
//Community
export const SET_ACTIVE_PROFILE_COMMUNITY = "SET_ACTIVE_PROFILE_COMMUNITY";
export const LOADING_ACTIVE_PROFILE_COMMUNITY = "LOADING_ACTIVE_PROFILE_COMMUNITY";
export const SET_ACTIVE_PROFILE_COMMUNITY_ERRORS = "SET_ACTIVE_PROFILE_COMMUNITY_ERRORS";
export const CLEAR_ACTIVE_PROFILE_COMMUNITY_ERRORS ="CLEAR_ACTIVE_PROFILE_COMMUNITY_ERRORS";
//Posts
export const SET_ACTIVE_PROFILE_POSTS = "SET_ACTIVE_PROFILE_POSTS";
export const LOADING_ACTIVE_PROFILE_POSTS = "LOADING_ACTIVE_PROFILE_POSTS";
export const SET_ACTIVE_PROFILE_POSTS_ERRORS = "SET_ACTIVE_PROFILE_POSTS_ERRORS";
export const CLEAR_ACTIVE_PROFILE_POSTS_ERRORS ="CLEAR_ACTIVE_PROFILE_POSTS_ERRORS";
