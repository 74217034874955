/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { createHangmanPost } from "../../../../../redux/actions/posts/atomic/post/createHangmanPost";

// Formik
import { useFormikContext } from "formik";

// Components
import BaseForm from "../../baseForm";
import hangmanSchema from "./validationSchemas/hangmanSchema";
import StyledInputField from "../../styledComponents/styledInputField";
import StyledAutoCompleteField from "../../styledComponents/styledAutoCompleteField";

// Material UI
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// Base State Object
const BASE_STATE = {
  catagory: "Outdoor activity",
  keyword: "",
};

// Constants
const CATAGORY_TYPES = [
  "Outdoor activity",
  "Board game to play",
  "Ice cream flavor",
  "Music genre",
  "TV show to watch",
  "Vacation spot",
  "Book to read",
];

const HangmanForm = ({ formRef, handleValidate, createHangmanPost }) => {
  const ref = useRef();
  const SEASON = getNorthernSeasonHelper();

  /**
   * This handler function submits the form values for the
   * hangman game.
   *
   * @param {object} values the form values
   * @param {object} helpers the form helper functions
   */
  const handleSubmit = async (values, helpers) => {
    helpers.setSubmitting(true);
    await createHangmanPost({ ...values, season: SEASON.season });
    helpers.setSubmitting(false);
  };

  /**
   * This helper component auto-detects if there is
   * a match between the values in the form and toggles
   * the accepted State
   * @returns {null}
   */
  const AutoDetectValid = () => {
    const { values, errors, isSubmitting } = useFormikContext();
    useEffect(() => {
      handleValidate(values, errors, isSubmitting, "hangman");
    }, [values, errors, isSubmitting]);
    return null;
  };

  return (
    <BaseForm
      formRef={formRef}
      data={BASE_STATE}
      handleSubmit={handleSubmit}
      validationSchema={hangmanSchema}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sx={{ my: 1 }}>
          <Typography variant="subtitle1" align="center">
            Let's play, {SEASON.name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            This game is similar to "Hangman". We simply upgraded it to match
            the {SEASON.season} season.
            <br />
            <br />
            Choose a category in the dropdown. Then, type the word or phrase of
            your favorite thing in that category. Your new community members will
            try to guess the letters that make up your word/phrase.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledAutoCompleteField
            name="catagory"
            label="Pick a category"
            color="primary"
            array={CATAGORY_TYPES}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <StyledInputField
            color="primary"
            name="keyword"
            label="Write your favorite thing"
            required
            inputRef={ref}
          />
        </Grid>
      </Grid>
      <AutoDetectValid />
    </BaseForm>
  );
};

HangmanForm.propTypes = {
  formRef: PropTypes.object,
  handleClick: PropTypes.func,
  handleValidate: PropTypes.func,
  createHangmanPost: PropTypes.func,
};

const mapActionsToProps = {
  createHangmanPost,
};

export default connect(null, mapActionsToProps)(HangmanForm);

/**
 * This helper function returns the correct season
 * for the northern hemisphere.
 *
 * @returns {number} -
 */
const getNorthernSeasonHelper = () => {
  let today = new Date();
  let month = today.getMonth();
  if ([11, 0, 1].includes(month)) return SEASON_VARIATIONS[0];
  else if ([2, 3, 4].includes(month)) return SEASON_VARIATIONS[1];
  else if ([5, 6, 7].includes(month)) return SEASON_VARIATIONS[2];
  return SEASON_VARIATIONS[3];
};

/**
 * The season variations
 */
const SEASON_VARIATIONS = [
  {
    season: "Winter",
    name: "Snowman ⛄️",
  },
  {
    season: "Spring",
    name: "Gardener 🧑‍🌾",
  },
  {
    season: "Summer",
    name: "Surfer 🏄‍♀️",
  },
  {
    season: "Fall",
    name: "Jack O'lantern 🎃",
  },
];
