/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Wallet Address
export const SET_WALLET_ADDRESS = "SET_WALLET_ADDRESS";
export const DELETE_WALLET_ADDRESS = "DELETE_WALLET_ADDRESS";
export const LOADING_WALLET_ADDRESS = "LOADING_WALLET_ADDRESS";
export const SET_WALLET_ADDRESS_ERRORS = "SET_WALLET_ADDRESS_ERRORS";
export const CLEAR_WALLET_ADDRESS_ERRORS = "CLEAR_WALLET_ADDRESS_ERRORS";

// Wallet Balance
export const SET_WALLET_BALANCE = "SET_WALLET_BALANCE";
export const LOADING_WALLET_BALANCE = "LOADING_WALLET_BALANCE";
export const SET_WALLET_BALANCE_ERRORS = "SET_WALLET_BALANCE_ERRORS";
export const CLEAR_WALLET_BALANCE_ERRORS = "CLEAR_WALLET_BALANCE_ERRORS";
