/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
//import { getFriendRequests } from "../../../redux/actions/notifications/getFriendRequests";
import { getGeneralNotifications } from "../../../redux/actions/notifications/getGeneralNotifications";
import { setSnapshotNotifications } from "../../../redux/actions/notifications/setSnapshotNotifications";

// Components
import Appbar from "../Nav/Appbar/appbar";
import Navbar from "../Nav/Navbar/navbar";
import useRealtimeNotificationSocket from "../../../hooks/useRealtimeNotificationSocket";

// Material UI
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

// Checks if user is authenticated
const getAuthStatus = () => localStorage.getItem("auth");

const LayoutAppbar = ({
  lastId,
  handle,
  lastDate,
  //lastFRId,
  //lastFRDate,
  handleDarkMode,
  isAppRoute,
  dateJoined,
  communityHandle,
  //getFriendRequests,
  isEndOfNotifications,
  //isEndOfFriendRequests,
  getGeneralNotifications,
  setSnapshotNotifications,
  lastNotificationClickDate,
  //lastFriendRequestClickDate,
  isAuthenticated = Boolean(getAuthStatus()),
}) => {
  const theme = useTheme();
  let isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * If the app just loaded, then get the first 7 notifications.
   * The lastId and lastDate field are proxy signals indicating
   * whether this step has been done.
   */
  useEffect(() => {
    if (isAppRoute && isAuthenticated) {
      if (!lastId && !lastDate && !isEndOfNotifications)
        getGeneralNotifications(10);
      // if (!lastFRId && !lastFRDate && !isEndOfFriendRequests)
      //   getFriendRequests(10);
    }
  }, []);

  /**
   * This custom hook creates the long-polling connection
   * to the notification collection.
   */
  useRealtimeNotificationSocket(
    "general",
    communityHandle,
    handle,
    lastNotificationClickDate ? lastNotificationClickDate : dateJoined,
    setSnapshotNotifications,
    isAppRoute
  );

  /**
   * This custom hook creates the long-polling connection
   * to the friend request collection.
   */
  // useRealtimeNotificationSocket(
  //   "friendshipRequest",
  //   communityHandle,
  //   handle,
  //   lastFriendRequestClickDate ? lastFriendRequestClickDate : dateJoined,
  //   setSnapshotNotifications,
  //   isAppRoute
  // );

  return (
    <header>
      {isAppRoute ? (
        <Appbar isTabletDown={isTabletDown} handleDarkMode={handleDarkMode} />
      ) : (
        <Navbar handleDarkMode={handleDarkMode} />
      )}
    </header>
  );
};

LayoutAppbar.propTypes = {
  handle: PropTypes.string,
  lastId: PropTypes.string,
  lastDate: PropTypes.string,
  isAppRoute: PropTypes.bool,
  handleDarkMode: PropTypes.func.isRequired,
  communityHandle: PropTypes.string,
  getGeneralNotifications: PropTypes.func,
  setSnapshotNotifications: PropTypes.func,
  lastNotificationClickDate: PropTypes.string,
};

const mapStateToProps = (state) => ({
  handle: state.user.core.handle,
  dateJoined: state.user.core.dateJoined,
  lastId: state.notifications.general.lastId,
  lastDate: state.notifications.general.lastDate,
  //lastFRId: state.notifications.friendRequests.lastId,
  //lastFRDate: state.notifications.friendRequests.lastDate,
  communityHandle: state.user.core.community.communityHandle,
  lastNotificationClickDate: state.user.core.lastNotificationClickDate,
  //lastFriendRequestClickDate: state.user.core.lastFriendRequestClickDate,
  isEndOfNotifications: state.notifications.general.isEndOfNotifications,
  //isEndOfFriendRequests:
  // state.notifications.friendRequests.isEndOfNotifications,
});

const mapActionsToProps = {
  //getFriendRequests,
  getGeneralNotifications,
  setSnapshotNotifications,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(React.memo(LayoutAppbar));
