/**
 * Copyright 2022 Loop Technology, Inc.
 */

const BASE_GCS_URL =
  "https://firebasestorage.googleapis.com/v0/b/loop-d78f8.appspot.com/o/";

/**
 * This helper function parses the image filename from an 
 * existing url or the newly uploaded image filename.
 *
 * @param {string} filename - the filename url
 * @returns {string} the parsed filename
 */
export const parseImageFilename = (filename) => {
  if (filename.includes("firebasestorage")) {
    let chunks = filename.split(BASE_GCS_URL)[1].split("%2F");
    return chunks[chunks.length - 1].split(".")[0] + ".jpeg";
  } else if (filename.includes("ipfs.looop")) {
  }
  return filename.split(".")[0] + ".jpeg";
};
