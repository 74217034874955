/**
 * Copyright 2022 Loop Technology, Inc.
 */

import {
  SET_USER_CHARACTERISTICS,
  LOADING_USER_CORE,
  CLEAR_USER_CORE_ERRORS,
  SET_USER_CORE_ERRORS,
} from "../../../../actionTypes/userTypes";

import baseAxios from "../../../../../util/axios/Instances/base.instance";

/**
 * This action function allows the authenticated user to add where they
 * work. The action requests from the (/user/details/workplace) route
 * which is built on top of the "addUserWorkplace" handler in Express.
 *
 * @param {object} characteristics - The characteristic object
 * @param {string} characteristics.personality - the user's personality
 * @param {string} characteristics.identities - the user's identities
 * @param {string} characteristics.lifestyles - the user's lifestyles
 * @param {string} characteristics.activities - the user's activities
 * @param {object} dispatch - The redux dispatch object
 */
export const addUserCharacteristics = (characteristics) => async (dispatch) => {
  dispatch({ type: CLEAR_USER_CORE_ERRORS });
  dispatch({ type: LOADING_USER_CORE });

  return new Promise(async (resolve, reject) => {
    await baseAxios
      .post("/user/details/characteristics", characteristics)
      .then(async (res) => {
        dispatch({ type: SET_USER_CHARACTERISTICS, payload: res.data.data });
        resolve(true);
      })
      .catch((error) => {
        dispatch({
          // Set Errors in Redux Store
          type: SET_USER_CORE_ERRORS,
          payload: error
            ? error.response
              ? error.response.date
              : error
            : null,
        });
        reject(error ? (error.response ? error.response.date : error) : null);
      });
  });
};
