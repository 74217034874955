/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This function returns the details used to populate
 * the site metadata.
 *
 * @param {string} pathname - the current pathname
 * @returns {object} the page metadata
 */
const siteMetadata = (pathname) => {
  const { title, description } = switchPageDescription(pathname.split("/"));
  return {
    title,
    description,
    author: "Loop Technology, Inc.",
    lang: "en",
  };
};

export default siteMetadata;

/**
 *
 * @param {array} subroutes
 * @returns
 */
const switchPageDescription = (subroutes) => {
  switch (`/${subroutes[1]}`) {
    case "/":
      return {
        title: "Home",
        description:
          "Loop ✌️ is an awesome network that makes it super simple to join a small, nearby friend group.",
      };
    case "/about":
      return {
        title: "About Us",
        description: "Learn more about Loop ✌️",
      };
    case "/townhall":
      return {
        title: "Next Townhall",
        description:
          'We believe the world is better when everyone has a place where they fit in. Loop communities make it easy to "say hello" and discover the incredible things all around you.',
      };
    case "/media-kit":
      return {
        title: "Media & Press",
        description:
          "We know how powerful great stories can be. We put together some helpful resources to share Loop's story.",
      };
    case "/policies":
      return {
        title: "Policies",
        description:
          "Loop gives users the power to keep the peace ✌️. For more information check out Loop's policies & guidelines.",
      };
    case "/community":
      return {
        title: "Community Feed",
        description: "Loop community feed",
      };
    case "/updates":
      return {
        title: "Updates for you",
        description: "Relevant Loop updates for you",
      };
    case "/invites":
      return {
        title: "Invite to Loop",
        description: "Invite the awesome people you know to Loop",
      };
    case "/profile":
      if (subroutes.length === 2)
        return {
          title: "Your Profile",
          description: "Your Loop profile.",
        };
      return {
        title: `@${subroutes[2]}'s Profile`,
        description: "Someones Loop profile.",
      };
    case "/discover":
      return {
        title: "Discover",
        description: "Loop discover",
      };
    case "/settings":
      return {
        title: "Settings",
        description: "Loop user profile settings",
      };
    // case "/community-id":
    //   return {
    //     title: "Your ID",
    //     description: "",
    //   };
    case "/careers":
      return {
        title: "Join the team",
        description: "",
      };
    case "/notifications":
      return {
        title: "Notifications",
        description: "Loop community notifications",
      };
    case "/friend-requests":
      return {
        title: "New Friendships",
        description: "Loop user pending friendship requests",
      };
    case "/search":
      return {
        title: "Search",
        description: "",
      };
    case "/leader-hub":
      return {
        title: "LeaderHub",
        description: "",
      };
    case "/review":
      return {
        title: "Content Review",
        description: "",
      };
    case "/manage-users":
      return {
        title: "Manage Account",
        description: "Loop account management",
      };
    case "/forgot-password":
      return {
        title: "Forgot Password",
        description: "",
      };
    case "/post":
      return {
        title: "Post",
        description: "",
      };
    case "/leader-hub?sta=completed":
      return {
        title: "Past Reviews",
        description: "",
      };
    case "/leader-hub?sta=pending":
      return {
        title: "Pending Reviews",
        description: "",
      };
    default:
      return {
        title: "Join a Community",
        description:
          "Loop ✌️ is an awesome network that makes it super simple to join a small, nearby friend group.",
      };
  }
};
