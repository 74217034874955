/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */

import {
  setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";
import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

import {
  // Base
  SET_USER,
  LOADING_USER,
  SET_PHONE_DETAILS,
  SET_EMAIL_DETAILS,
  // Details
  SET_USER_HOMETOWN,
  SET_USER_RELATIONSHIP,
  SET_USER_WORKPLACE,
  SET_USER_HIGH_SCHOOL,
  SET_USER_COLLEGE,
  //Core
  LOADING_USER_CORE,
  SET_USER_CORE,
  SET_USER_CORE_ERRORS,
  CLEAR_USER_CORE_ERRORS,
  SET_USER_CORE_DETAILS,
  SET_USER_PROFILE_IMAGE,
  SET_USER_BACKGROUND_IMAGE,
  SET_NEW_LAST_VOTE_DATE,
  SET_USER_CHARACTERISTICS,
  SET_NEW_LAST_NOTIFICATION_CLICK_DATE,
  SET_NEW_LAST_FRIEND_REQUEST_CLICK_DATE,
  //Community
  CLEAR_USER_COMMUNITY,
  LOADING_USER_COMMUNITY,
  SET_USER_COMMUNITY,
  SET_USER_COMMUNITY_ERRORS,
  CLEAR_USER_COMMUNITY_ERRORS,
  //Friends
  LOADING_USER_FRIENDS,
  SET_USER_FRIENDS,
  REMOVE_USER_FRIEND,
  SET_USER_FRIENDS_ERRORS,
  CLEAR_USER_FRIENDS_ERRORS,
  //Community Members
  LOADING_COMMUNITY_MEMBERS,
  SET_COMMUNITY_MEMBERS,
  SET_COMMUNITY_MEMBERS_ERRORS,
  CLEAR_COMMUNITY_MEMBERS,
  CLEAR_COMMUNITY_MEMBERS_ERRORS,
  //Community Leaders
  LOADING_COMMUNITY_LEADERS,
  SET_COMMUNITY_LEADERS,
  SET_COMMUNITY_LEADERS_ERRORS,
  CLEAR_COMMUNITY_LEADERS,
  CLEAR_COMMUNITY_LEADERS_ERRORS,
  // Resignation
  SET_LEADER_RESIGNATION,
  SET_LEADER_SUPERPOWER,
  //Rules
  LOADING_COMMUNITY_RULES,
  SET_COMMUNITY_RULES,
  SET_COMMUNITY_PROPOSED_RULES,
  SET_COMMUNITY_RULES_ERRORS,
  CLEAR_COMMUNITY_RULES_ERRORS,
  //Coalitions
  LOADING_USER_COALITIONS,
  SET_USER_COALITIONS,
  SET_USER_COALITIONS_ERRORS,
  CLEAR_USER_COALITIONS_ERRORS,
  //Block
  LOADING_BLOCKED_USERS,
  SET_BLOCKED_USERS_ERRORS,
  CLEAR_BLOCKED_USERS_ERRORS,
  SET_BLOCK_USER,
  SET_UNBLOCK_USER,
  SET_BLOCKED_USERS,
} from "../actionTypes/userTypes";

const initState = {
  api: {
    status: apiStatus("idle"),
    error: {},
  },
  core: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    firstName: "",
    lastName: "",
    pronouns: "",
    description: "",
    emailDetails: {},
    phoneDetails: {},
    lastNotificationClickDate: null,
    lastFriendRequestClickDate: null,
    isNewUser: false,
    isNewMember: false,
    backgroundImage: {
      ogPosition: {},
    },
    profileImage: {
      ogPosition: {},
    },
    details: {},
    community: {
      communityHandle: null,
      communityName: null,
      isLeader: false,
      superpower: null,
    },
  },
  community: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    communityHandle: null,
    communityName: null,
    leaderCount: null,
    members: {
      api: {
        status: "idle",
        error: {},
      },
      members: {},
      lastDate: null,
      lastId: null,
      isEndOfMembers: null,
    },
    leaders: {
      api: {
        status: "idle",
        error: {},
      },
      leaders: {},
    },
    rules: {
      api: {
        status: "idle",
        error: {},
      },
      rules: {},
      lastId: null,
      lastLevel: null,
      isEndOfRules: null,
      ruleProposals: {},
      lastProposalId: null,
      lastProposalLevel: null,
      isEndOfRuleProposals: null,
    },
  },
  coalitions: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    coalitions: {},
    lastCoalitionHandle: "",
    isEndOfCoalitionsArray: null,
  },
  friends: {
    api: {
      status: "idle",
      error: {},
    },
    friends: {},
    totalFriendCount: null,
    lastUserHandle: "",
    isEndOfFriends: null,
  },
  privacy: {
    api: {
      status: "idle",
      error: {},
    },
    blockedUsers: {
      api: {
        status: "idle",
        error: {},
      },
      blockedUsers: {},
      lastId: null,
      lastDate: null,
      isEndOfBlocked: null,
    },
  },
  // adventures: {
  //   api: {
  //     status: "idle",
  //     error: {},
  //   },
  //   adventures: {},
  //   lastId: null,
  //   lastDate: null,
  //   isEndOfAdventures: null,
  // },
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case LOADING_USER:
      return loadingBaseReducer(state);
    case LOADING_USER_CORE:
      return loadingBaseReducer(state, "core");
    case LOADING_USER_COMMUNITY:
      return loadingBaseReducer(state, "community");
    case LOADING_USER_FRIENDS:
      return loadingBaseReducer(state, "friends");
    case LOADING_USER_COALITIONS:
      return loadingBaseReducer(state, "coalitions");
    case LOADING_COMMUNITY_MEMBERS:
      return loadingBaseReducer(state, "community", "members");
    case LOADING_COMMUNITY_LEADERS:
      return loadingBaseReducer(state, "community", "leaders");
    case LOADING_COMMUNITY_RULES:
      return loadingBaseReducer(state, "community", "rules");
    case LOADING_BLOCKED_USERS:
      return loadingBaseReducer(state, "privacy", "blockedUsers");

    // Success Types
    case SET_USER:
      return setBaseReducer(state);
    case SET_USER_CORE:
      return setBaseReducer(state, "core", null, {
        l1: { ...action.payload },
      });
    case SET_USER_CHARACTERISTICS:
      return setBaseReducer(state, "core", "details", {
        l2: {
          characteristics: { ...action.payload },
        },
      });
    case SET_USER_HOMETOWN:
      return setBaseReducer(state, "core", "details", {
        l2: {
          location: {
            ...state.core.details.location,
            hometown: { ...action.payload },
          },
        },
      });
    case SET_USER_HIGH_SCHOOL:
      return setBaseReducer(state, "core", "details", {
        l2: {
          highSchool: { ...action.payload },
        },
      });
    case SET_USER_COLLEGE:
      return setBaseReducer(state, "core", "details", {
        l2: { college: { ...action.payload } },
      });
    case SET_USER_RELATIONSHIP:
      return setBaseReducer(state, "core", "details", {
        l2: { relationship: { ...action.payload } },
      });
    case SET_USER_WORKPLACE:
      return setBaseReducer(state, "core", "details", {
        l2: { workplace: { ...action.payload } },
      });
    case SET_NEW_LAST_VOTE_DATE:
      return setBaseReducer(state, "core", null, {
        l1: { lastVoteDate: new Date().toISOString() },
      });
    case SET_NEW_LAST_NOTIFICATION_CLICK_DATE:
      return setBaseReducer(state, "core", null, {
        l1: {
          lastNotificationClickDate: action.payload.lastNotificationClickDate,
        },
      });
    case SET_NEW_LAST_FRIEND_REQUEST_CLICK_DATE:
      return setBaseReducer(state, "core", null, {
        l1: {
          lastFriendRequestClickDate: action.payload.lastNotificationClickDate,
        },
      });
    case SET_USER_CORE_DETAILS:
      return setBaseReducer(state, "core", null, {
        l1: { ...action.payload },
      });
    case SET_EMAIL_DETAILS:
      return setBaseReducer(state, "core", null, {
        l1: { emailDetails: { ...action.payload } },
      });
    case SET_PHONE_DETAILS:
      return setBaseReducer(state, "core", null, {
        l1: { phoneDetails: { ...action.payload } },
      });
    case SET_USER_PROFILE_IMAGE:
      return setBaseReducer(state, "core", null, {
        l1: {
          profileImage: { ...action.payload.profileImage },
        },
      });
    case SET_USER_BACKGROUND_IMAGE:
      return setBaseReducer(state, "core", null, {
        l1: {
          backgroundImage: { ...action.payload.backgroundImage },
        },
      });
    case SET_BLOCK_USER:
      return setBaseReducer(state, "privacy", "blockedUsers", {
        l2: {
          blockedUsers: {
            ...state.privacy.blockedUsers.blockedUsers,
            ...action.payload.data,
          },
        },
      });
    case SET_UNBLOCK_USER:
      const { [action.payload]: blocked, ...remainingBlocked } =
        state.privacy.blockedUsers.blockedUsers;
      return setBaseReducer(state, "privacy", "blockedUsers", {
        l2: {
          blockedUsers: { ...remainingBlocked },
        },
      });
    case SET_BLOCKED_USERS:
      return setBaseReducer(state, "privacy", "blockedUsers", {
        l2: {
          blockedUsers: {
            ...state.privacy.blockedUsers.blockedUsers,
            ...action.payload.data,
          },
          lastId: action.payload.lastId,
          lastLevel: action.payload.lastLevel,
          lastUpdated: new Date().toISOString(),
          isEndOfBlocked: action.payload.isEndOfDocs,
        },
      });
    case SET_USER_COMMUNITY:
      return setBaseReducer(state, "community", null, {
        l1: { ...action.payload },
      });
    case CLEAR_USER_COMMUNITY:
      return setBaseReducer(state, "community", null, {
        l1: { ...initState.community },
      });
    case SET_USER_COALITIONS:
      return setBaseReducer(state, "coalitions", null, {
        l1: {
          lastCoalitionHandle: action.payload.lastCoalitionHandle,
          isEndOfCoalitionsArray: action.payload.isEndOfCoalitionsArray,
          coalitions: {
            ...state.coalitions.coalitions,
            ...action.payload.coalitions,
          },
        },
      });
    case SET_USER_FRIENDS:
      return setBaseReducer(state, "friends", null, {
        l1: {
          totalFriendCount: action.payload.totalFriendCount,
          lastUserHandle: action.payload.lastUserHandle,
          isEndOfFriends: action.payload.isEndOfFriends,
          friends: {
            ...state.friends.friends,
            ...action.payload.friends,
          },
        },
      });
    case REMOVE_USER_FRIEND:
      /**
       * ES6 Logic to deconstructively remove the object element
       * from the full friend redux object
       */
      const { [action.payload]: friend, ...remainingFriends } =
        state.friends.friends;
      return setBaseReducer(state, "friends", null, {
        l1: {
          friends: { ...remainingFriends },
        },
      });
    case SET_LEADER_SUPERPOWER:
      return setBaseReducer(state, "core", null, {
        l1: {
          community: {
            ...state.community,
            superpower: action.payload.superpower,
          },
        },
      });
    case SET_LEADER_RESIGNATION:
      const { [action.payload]: leader, ...remainingLeaders } =
        state.community.leaders.leaders;
      setBaseReducer(state, "core", null, {
        l1: {
          community: {
            ...state.community,
            isLeader: false,
            superpower: null,
          },
        },
      });
      return setBaseReducer(state, "community", null, {
        l1: {
          leaderCount: state.community.leaderCount - 1,
          leaders: {
            ...state.community.leaders,
            leaders: { ...remainingLeaders },
          },
          members: {
            ...state.community.members,
            members: {
              ...state.community.members.members,
              [action.payload]: {
                ...state.community.members.members[action.payload],
                isLeader: false,
                leaderDetails: null,
              },
            },
          },
        },
      });
    case SET_COMMUNITY_MEMBERS:
      return setBaseReducer(state, "community", "members", {
        l2: {
          members: {
            ...state.community.members.members,
            ...action.payload.data,
          },
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate, // Date of last notification
          lastUpdated: new Date().toISOString(), // When reducer last updated
          isEndOfMembers: action.payload.isEndOfDocs,
        },
      });
    case SET_COMMUNITY_LEADERS:
      return setBaseReducer(state, "community", "leaders", {
        l2: {
          leaders: {
            ...state.community.leaders.leaders,
            ...action.payload.data,
          },
        },
      });
    case CLEAR_COMMUNITY_MEMBERS:
      return setBaseReducer(state, "community", "members", {
        l2: {
          members: {},
          lastUpdated: new Date().toISOString(),
          lastId: null,
          lastDate: null,
          isEndOfMembers: null,
        },
      });
    case CLEAR_COMMUNITY_LEADERS:
      return setBaseReducer(state, "community", "leaders", {
        l2: { leaders: {} },
      });
    case SET_COMMUNITY_RULES:
      return setBaseReducer(state, "community", "rules", {
        l2: {
          rules: {
            ...state.community.rules.rules,
            ...action.payload.data,
          },
          lastId: action.payload.lastId,
          lastLevel: action.payload.lastLevel,
          lastUpdated: new Date().toISOString(), // When reducer last updated
          isEndOfRules: action.payload.isEndOfDocs,
        },
      });
    case SET_COMMUNITY_PROPOSED_RULES:
      return setBaseReducer(state, "community", "ruleProposals", {
        l2: {
          ruleProposals: {
            ...state.community.rules.ruleProposals,
            ...action.payload.data,
          },
          lastProposalId: action.payload.lastId,
          lastProposalLevel: action.payload.lastLevel,
          lastUpdated: new Date().toISOString(), // When reducer last updated
          isEndOfRuleProposals: action.payload.isEndOfDocs,
        },
      });

    // Failed Types,
    case SET_USER_CORE_ERRORS:
      return setErrorsBaseReducer(state, "core", null, {
        error: action.payload,
      });
    case SET_USER_COMMUNITY_ERRORS:
      return setErrorsBaseReducer(state, "community", null, {
        error: action.payload,
      });
    case SET_USER_FRIENDS_ERRORS:
      return setErrorsBaseReducer(state, "friends", null, {
        error: action.payload,
      });
    case SET_USER_COALITIONS_ERRORS:
      return setErrorsBaseReducer(state, "coalitions", null, {
        error: action.payload,
      });
    case SET_BLOCKED_USERS_ERRORS:
      return setErrorsBaseReducer(state, "privacy", "blockedUsers", {
        error: action.payload,
      });
    case SET_COMMUNITY_MEMBERS_ERRORS:
      return setErrorsBaseReducer(state, "community", "members", {
        error: action.payload,
      });
    case SET_COMMUNITY_LEADERS_ERRORS:
      return setErrorsBaseReducer(state, "community", "leaders", {
        error: action.payload,
      });
    case SET_COMMUNITY_RULES_ERRORS:
      return setErrorsBaseReducer(state, "community", "rules", {
        error: action.payload,
      });

    // Idle Types
    case CLEAR_USER_CORE_ERRORS:
      return clearErrorsBaseReducer(state, "core");
    case CLEAR_USER_FRIENDS_ERRORS:
      return clearErrorsBaseReducer(state, "friends");
    case CLEAR_USER_COMMUNITY_ERRORS:
      return clearErrorsBaseReducer(state, "community");
    case CLEAR_USER_COALITIONS_ERRORS:
      return clearErrorsBaseReducer(state, "coalitions");
    case CLEAR_BLOCKED_USERS_ERRORS:
      return clearErrorsBaseReducer(state, "privacy", "blockedUsers");
    case CLEAR_COMMUNITY_MEMBERS_ERRORS:
      return clearErrorsBaseReducer(state, "community", "members");
    case CLEAR_COMMUNITY_LEADERS_ERRORS:
      return clearErrorsBaseReducer(state, "community", "leaders");
    case CLEAR_COMMUNITY_RULES_ERRORS:
      return clearErrorsBaseReducer(state, "community", "rules");
    default:
      return state;
  }
}
