/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Formik
import { FastField } from "formik";

// Material UI
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const StyledMultilineInputField = ({
  required = false,
  autoComplete,
  placeholder,
  rows = 1,
  rowsMax = 1,
  noMargin = false,
  color = "secondary",
  colorType = "main",
  fullWidth = true,
  characterCount = false,
  maxCharacters = 200,
  maxCharErrorMessage = "Too many characters",
  ...props
}) => {
  return (
    <FastField name={props.name}>
      {({ field, meta }) => {
        const errorText = meta.error && meta.touched ? meta.error : null;
        return (
          <React.Fragment>
            <TextField
              {...field}
              placeholder={placeholder}
              minRows={rows}
              maxRows={rowsMax}
              variant="outlined"
              required={required}
              fullWidth
              multiline
              type="input"
              color={color}
              margin="normal"
              autoComplete={autoComplete}
              error={errorText ? true : false}
              helperText={errorText}
              sx={{
                my: noMargin ? 0 : 1,
                width: fullWidth ? "100%" : "auto",
                "& .MuiOutlinedInput-root": {
                  fontSize: 16,
                  "& fieldset": {
                    borderColor: (theme) => theme.palette.grey[400],
                  },
                  "& .MuiOutlinedInput-input:-webkit-autofill": {
                    WebkitBoxShadow: (theme) =>
                      `0 0 0 100px ${theme.palette.background.paper} inset !important`,
                    backgroundColor: (theme) =>
                      `${theme.palette.background.paper} !important`,
                    backgroundClip: "content-box !important",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: (theme) => theme.palette[color][colorType],
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: 16,
                  "&.Mui-focused": {
                    color: (theme) =>
                      `${theme.palette[color][colorType]} !important`,
                  },
                },
              }}
            />
            {characterCount && (
              <Grid
                container
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item>
                  <Typography
                    variant="body2"
                    color={
                      field.value.length <= maxCharacters
                        ? "textSecondary"
                        : "error"
                    }
                  >
                    {field.value.length} /{maxCharacters}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="textSecondary">
                    {field.value.length > maxCharacters
                      ? `Must be less than ${maxCharacters} characters.`
                      : ""}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        );
      }}
    </FastField>
  );
};

StyledMultilineInputField.propTypes = {
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "success",
    "text",
    "warning",
    "info",
  ]),
  colorTypes: PropTypes.oneOf([
    "main",
    "light",
    "dark",
    "secondary",
    "primary",
  ]),
};

export default StyledMultilineInputField;
