/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";

const StyledFooterMessage = ({
  cookieMsgOpen,
  handleCookieApproval,
  isTabletDown,
}) => {
  return (
    <Collapse in={cookieMsgOpen}>
      <Alert
        color="info"
        severity="info"
        action={
          <Button
            color="inherit"
            size="medium"
            sx={{ px: 3, py: 1.5 }}
            onClick={handleCookieApproval}
          >
            Accept
          </Button>
        }
        sx={{ mx: isTabletDown ? "5vw" : "10vw" }}
      >
        Loop is powered by cookies and a lot of coffee. By accepting cookies,
        you agree to let us use cookies to make your experience even better.
      </Alert>
    </Collapse>
  );
};

StyledFooterMessage.propTypes = {
  cookieMsgOpen: PropTypes.bool,
  handleCookieApproval: PropTypes.func,
  isTabletDown: PropTypes.bool,
};

export default StyledFooterMessage;
