/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * The base routes that the user can
 * access when they are logged in to
 * the network.
 */
const loggedInRoutes = [
  "/community",
  "/settings",
  "/profile",
  "/notifications",
  "/friend-requests",
  "/discover",
  "/post",
  "/help",
  "/loop-id",
  "/update-password",
  "/update-phone",
  "/update-email",
  "/leader-hub",
  "/review",
  "/townhall",
  "/invites",
  "/updates",
];

export default loggedInRoutes;
