/**
 * * Copyright 2022 Loop Technology, Inc.
 */

import {
  LOADING_USER_COMMUNITY,
  SET_USER_COMMUNITY_ERRORS,
  CLEAR_USER_COMMUNITY_ERRORS,
} from "../../../../actionTypes/userTypes";

import baseAxios from "../../../../../util/axios/Instances/base.instance";
import { clearGeneralNotifications } from "../../../notifications/clearGeneralNotifications";
import { clearUserFeed } from "../../../feed/atomic/post/clearUserFeed";

/**
 * This action function sends a request to switch the users
 * community. This switch does not trigger the creation of
 * a new community, rather it matches the user with an existing
 * community. There are three type variants: (1) the user is
 * signing up, (2) the user updates their location, (3), the
 * user clicks switch my community.
 *
 * @param {string} type - this is the join type (switch, signup, location)
 * @param {object} location - the location data object
 * @param {string} location.locality - the community's locality
 * @param {string} location.political - the community's political area
 * @param {string} location.geocode - the community's Country
 * @param {string} location.placeId - the community's Google place id
 * @param {string} location.description - the community's location description
 * @param {object} dispatch - The redux dispatch object
 */
export const joinCommunity = (type, location) => async (dispatch) => {
  dispatch({ type: CLEAR_USER_COMMUNITY_ERRORS });
  dispatch({ type: LOADING_USER_COMMUNITY });

  return await baseAxios
    .post(`/join-community/${type}`, location)
    .then(async () => {
      if (type !== "signup") {
        dispatch(clearGeneralNotifications());
        dispatch(clearUserFeed());
      }

      // Trigger Intro Post param in user object
    })
    .catch((error) => {
      dispatch({
        type: SET_USER_COMMUNITY_ERRORS,
        payload: error ? (error.response ? error.response.data : error) : null,
      });
    });
};
