/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import { styled, alpha } from "@mui/system";
import IconButton from "@mui/material/IconButton";

const StyledDiv = styled("div", {
  shouldForwardProp: (prop) => prop !== "align",
})(({ align }) => ({
  textAlign: align,
}));

const StyledIconButton = ({
  color = "grey",
  colorType = 50,
  align = "center",
  size = "large",
  sxProps = {},
  overrideDarkTheme = false,
  children,
  ...props
}) => {
  return (
    <StyledDiv align={align}>
      <IconButton
        {...props}
        sx={{
          backgroundColor:
            color !== "transparent"
              ? (theme) =>
                  theme.palette.mode === "dark" && !overrideDarkTheme
                    ? alpha(theme.palette.grey[100], 0.65)
                    : theme.palette[color][colorType]
              : "transparent",
          color:
            color !== "grey" ? "#fff" : (theme) => theme.palette.text.primary,
          "&:hover": {
            backgroundColor:
              color !== "transparent"
                ? (theme) =>
                    theme.palette.mode === "dark" && !overrideDarkTheme
                      ? alpha(theme.palette.grey[100], 0.55)
                      : theme.palette[color][colorType]
                : "transparent",
          },
          ...sxProps,
        }}
      >
        {children}
      </IconButton>
    </StyledDiv>
  );
};

StyledIconButton.propTypes = {
  align: PropTypes.oneOf(["center", "end", "start"]),
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "success",
    "info",
    "error",
    "warning",
    "text",
    "grey",
    "transparent",
  ]),
  colorType: PropTypes.oneOf([
    "main",
    "light",
    "dark",
    "secondary",
    "primary",
    50,
    100,
    200,
    300,
    400,
    500,
  ]),
};

export default StyledIconButton;
