/**
 * Copyright 2022 Loop Technology, Inc.
 */

import * as yup from "yup";

const hangmanSchema = yup.object().shape({
  catagory: yup.string().required("You must add a second truth"),
  keyword: yup.string().required("You must write your favorite"),
});

export default hangmanSchema;
