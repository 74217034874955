/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */
import {
  setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";
import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

import {
  // Create Post
  SET_NEW_RULE,
  SET_NEW_RULE_ERRORS,
  UPLOADING_NEW_RULE,
  CLEAR_NEW_RULE_ERRORS,
} from "../actionTypes/ruleTypes";

const initState = {
  newRule: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    ruleId: null,
    postId: null,
    notifId: null,
    message: null,
    votingExpiresOn: null,
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case UPLOADING_NEW_RULE:
      return loadingBaseReducer(state, "newRule");
    // Success Types
    case SET_NEW_RULE:
      return setBaseReducer(state, "newRule", null, {
        l1: { ...action.payload },
      });
    // Failed Types
    case SET_NEW_RULE_ERRORS:
      return setErrorsBaseReducer(state, "newRule", null, {
        error: { ...action.payload },
      });
    // Idle Types
    case CLEAR_NEW_RULE_ERRORS:
      return clearErrorsBaseReducer(state, "newRule");
    default:
      return state;
  }
}
