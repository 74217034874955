/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */

import {
  setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";
import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

import {
  // Base
  CLEAR_ACTIVE_PROFILE,
  SET_ACTIVE_PROFILE,
  LOADING_ACTIVE_PROFILE,
  // Core
  SET_ACTIVE_PROFILE_CORE,
  LOADING_ACTIVE_PROFILE_CORE,
  SET_ACTIVE_PROFILE_CORE_ERRORS,
  CLEAR_ACTIVE_PROFILE_CORE_ERRORS,
  // Community
  SET_ACTIVE_PROFILE_COMMUNITY,
  SET_ACTIVE_PROFILE_COMMUNITY_ERRORS,
  CLEAR_ACTIVE_PROFILE_COMMUNITY_ERRORS,
  LOADING_ACTIVE_PROFILE_COMMUNITY,
  // Friends
  SET_ACTIVE_PROFILE_FRIENDS,
  REMOVE_ACTIVE_PROFILE_FRIEND,
  LOADING_ACTIVE_PROFILE_FRIENDS,
  SET_ACTIVE_PROFILE_FRIENDS_ERRORS,
  CLEAR_ACTIVE_PROFILE_FRIENDS_ERRORS,
  // Community Members
  SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS,
  LOADING_ACTIVE_PROFILE_COMMUNITY_MEMBERS,
  SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS,
  CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS,
  CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS,
  // Community Leaders
  SET_ACTIVE_PROFILE_COMMUNITY_LEADERS,
  LOADING_ACTIVE_PROFILE_COMMUNITY_LEADERS,
  SET_ACTIVE_PROFILE_COMMUNITY_LEADERS_ERRORS,
  CLEAR_ACTIVE_PROFILE_COMMUNITY_LEADERS,
  CLEAR_ACTIVE_PROFILE_COMMUNITY_LEADERS_ERRORS,
  // Posts
  SET_ACTIVE_PROFILE_POSTS,
  LOADING_ACTIVE_PROFILE_POSTS,
  SET_ACTIVE_PROFILE_POSTS_ERRORS,
  CLEAR_ACTIVE_PROFILE_POSTS_ERRORS,
} from "../actionTypes/activeProfileTypes";

const initState = {
  api: {
    status: apiStatus("idle"),
    error: {},
  },
  authUserIsFriend: false,
  friendshipIsPending: false,
  authUserIsSender: false,
  core: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    community: {
      communityHandle: null,
    },
    description: "",
    backgroundImage: "",
    imageUrl: "",
    imageUrlThumbs: {},
    profileImage: {},
    firstName: "",
    lastName: "",
    pronouns: "",
    details: {
      characteristics: {
        activities: [],
        lifestyles: [],
        identities: [],
      },
    },
  },
  community: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    members: {
      api: {
        status: "idle",
        error: {},
      },
      members: {},
      lastDate: null,
      lastId: null,
      isEndOfMembers: null,
    },
    leaders: {
      api: {
        status: "idle",
        error: {},
      },
      leaders: {},
    },
  },
  friends: {
    api: {
      status: "idle",
      error: {},
    },
    friends: {},
    totalFriendCount: null,
    lastUserHandle: "",
    isEndOfFriends: null,
  },
  events: {
    api: {
      status: "idle",
      error: {},
    },
  },
  adventures: {
    api: {
      status: "idle",
      error: {},
    },
  },
  posts: {
    api: {
      status: "idle",
      error: {},
    },
    isCommunityOnly: null,
    top: {},
    rising: {},
    trending: {},
  },
};

export default function (state = initState, action) {
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case LOADING_ACTIVE_PROFILE:
      return loadingBaseReducer(state);
    case LOADING_ACTIVE_PROFILE_CORE:
      return loadingBaseReducer(state, "core");
    case LOADING_ACTIVE_PROFILE_COMMUNITY:
      return loadingBaseReducer(state, "community");
    case LOADING_ACTIVE_PROFILE_FRIENDS:
      return loadingBaseReducer(state, "friends");
    case LOADING_ACTIVE_PROFILE_POSTS:
      return loadingBaseReducer(state, "posts");
    case LOADING_ACTIVE_PROFILE_COMMUNITY_MEMBERS:
      return loadingBaseReducer(state, "community", "members");
    case LOADING_ACTIVE_PROFILE_COMMUNITY_LEADERS:
      return loadingBaseReducer(state, "community", "leaders");

    // Success Types
    case CLEAR_ACTIVE_PROFILE:
      return initState;
    case SET_ACTIVE_PROFILE:
      return setBaseReducer(state);
    case SET_ACTIVE_PROFILE_CORE:
      return setBaseReducer(state, "core", null, {
        l1: { ...action.payload },
      });
    case SET_ACTIVE_PROFILE_COMMUNITY:
      return setBaseReducer(state, "community", null, {
        l1: { ...action.payload },
      });
    case SET_ACTIVE_PROFILE_FRIENDS:
      return setBaseReducer(state, "friends", null, {
        l0: {
          authUserIsFriend: action.payload.authUserIsFriend,
          friendshipIsPending: action.payload.friendshipIsPending,
          authUserIsSender: action.payload.authUserIsSender,
        },
        l1: {
          friends: {
            api: {
              ...state.friends.api,
              status: apiStatus("success"),
            },
            friends: { ...state.friends.friends, ...action.payload.friends },
            totalFriendCount: action.payload.totalFriendCount,
            lastUserHandle: action.payload.lastUserHandle,
            isEndOfFriends: action.payload.isEndOfFriends,
          },
        },
      });
    case REMOVE_ACTIVE_PROFILE_FRIEND:
      /**
       * ES6 Logic to deconstructively remove the object element
       * from the full friend redux object
       */
      const { [action.payload]: friend, ...remainingFriends } =
        state.friends.friends;
      return setBaseReducer(state, "friends", null, {
        l0: {
          authUserIsFriend: action.payload.authUserIsFriend,
          friendshipIsPending: action.payload.friendshipIsPending,
          authUserIsSender: action.payload.authUserIsSender,
        },
        l1: {
          friends: {
            api: {
              ...state.friends.api,
              status: apiStatus("success"),
            },
            friends: { ...remainingFriends },
            totalFriendCount: action.payload.totalFriendCount,
            lastUserHandle: action.payload.lastUserHandle,
            isEndOfFriends: action.payload.isEndOfFriends,
          },
        },
      });

    case SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS:
      return setBaseReducer(state, "community", "members", {
        l2: {
          members: {
            ...state.community.members.members,
            ...action.payload.data,
          },
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate, // Date of last notification
          lastUpdated: new Date().toISOString(), // When reducer last updated
          isEndOfMembers: action.payload.isEndOfDocs,
        },
      });
    case CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS:
      return setBaseReducer(state, "community", "members", {
        l2: {
          members: {},
          lastUpdated: new Date().toISOString(),
          lastId: null,
          lastDate: null,
          isEndOfMembers: null,
        },
      });
    case SET_ACTIVE_PROFILE_COMMUNITY_LEADERS:
      return setBaseReducer(state, "community", "leaders", {
        l2: {
          leaders: {
            ...state.community.leaders.leaders,
            ...action.payload.data,
          },
        },
      });
    case CLEAR_ACTIVE_PROFILE_COMMUNITY_LEADERS:
      return setBaseReducer(state, "community", "leaders", {
        l2: { leaders: {} },
      });
    case SET_ACTIVE_PROFILE_POSTS:
      return setBaseReducer(state, "posts", null, {
        l1: { ...action.payload },
      });

    // Failed Types
    case SET_ACTIVE_PROFILE_CORE_ERRORS:
      return setErrorsBaseReducer(state, "core", null, {
        error: action.payload,
      });
    case SET_ACTIVE_PROFILE_COMMUNITY_ERRORS:
      return setErrorsBaseReducer(state, "community", null, {
        error: action.payload,
      });
    case SET_ACTIVE_PROFILE_FRIENDS_ERRORS:
      return setErrorsBaseReducer(state, "friends", null, {
        error: action.payload,
      });
    case SET_ACTIVE_PROFILE_POSTS_ERRORS:
      return setErrorsBaseReducer(state, "posts", null, {
        error: action.payload,
      });
    case SET_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS:
      return setErrorsBaseReducer(state, "community", "members", {
        error: action.payload,
      });
    case SET_ACTIVE_PROFILE_COMMUNITY_LEADERS_ERRORS:
      return setErrorsBaseReducer(state, "community", "leaders", {
        error: action.payload,
      });

    // Idle Types
    case CLEAR_ACTIVE_PROFILE_CORE_ERRORS:
      return clearErrorsBaseReducer(state, "core");
    case CLEAR_ACTIVE_PROFILE_COMMUNITY_ERRORS:
      return clearErrorsBaseReducer(state, "community");
    case CLEAR_ACTIVE_PROFILE_FRIENDS_ERRORS:
      return clearErrorsBaseReducer(state, "friends");
    case CLEAR_ACTIVE_PROFILE_POSTS_ERRORS:
      return clearErrorsBaseReducer(state, "posts");
    case CLEAR_ACTIVE_PROFILE_COMMUNITY_MEMBERS_ERRORS:
      return clearErrorsBaseReducer(state, "community", "members");
    case CLEAR_ACTIVE_PROFILE_COMMUNITY_LEADERS_ERRORS:
      return clearErrorsBaseReducer(state, "community", "leaders");
    default:
      return state;
  }
}
