/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Community Leaderboard Action Types
export const CLEAR_COMMUNITY_LEADERBOARD = "CLEAR_COMMUNITY_LEADERBOARD";
export const LOADING_COMMUNITY_LEADERBOARD = "LOADING_COMMUNITY_LEADERBOARD";
export const SET_COMMUNITY_LEADERBOARD = "SET_COMMUNITY_LEADERBOARD";
export const SET_NEW_COMMUNITY_LEADERBOARD = "SET_NEW_COMMUNITY_LEADERBOARD";
export const CLEAR_COMMUNITY_LEADERBOARD_ERRORS =
  "CLEAR_COMMUNITY_LEADERBOARD_ERRORS";
export const SET_COMMUNITY_LEADERBOARD_ERRORS =
  "SET_COMMUNITY_LEADERBOARD_ERRORS";

// User Leaderboard Action Types
export const CLEAR_USER_LEADERBOARD = "CLEAR_USER_LEADERBOARD";
export const LOADING_USER_LEADERBOARD = "LOADING_USER_LEADERBOARD";
export const SET_USER_LEADERBOARD = "SET_USER_LEADERBOARD";
export const SET_NEW_USER_LEADERBOARD = "SET_NEW_USER_LEADERBOARD";
export const CLEAR_USER_LEADERBOARD_ERRORS = "CLEAR_USER_LEADERBOARD_ERRORS";
export const SET_USER_LEADERBOARD_ERRORS = "SET_USER_LEADERBOARD_ERRORS";
