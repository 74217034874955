/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

//Redux
import { Provider } from "react-redux";
import combinedStore from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

// Material UI
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { globalThemeSettings } from "./util/theme";

// Components
import SEO from "./components/SEO/seo";
import StyledApp from "./components/UI/App/styledApp";
import { getBaseRoute } from "./functions/getBaseRoute";
import siteMetadata from "./components/SEO/siteMetadata";
import { validateAppRoute } from "./functions/validateAppRoute";
import loggedInRoutes from "./components/Pages/Routes/loggedInRoutes";
import StyledAppBoundary from "./components/UI/ErrorBoundaries/StyledBoundaries/styledAppBoundary";

// Checks if app dark mode
const getDarkMode = () => localStorage.getItem("dark");
const toggleDarkMode = (on) =>
  on ? localStorage.setItem("dark", "true") : localStorage.removeItem("dark");

// Component
const App = ({ dark = Boolean(getDarkMode()) }) => {
  const location = useLocation();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [darkMode, setDarkMode] = useState(
    dark || prefersDarkMode ? true : false
  );

  /**
   * This handler toggles the dark mode on and off
   */
  const handleDarkMode = () => {
    setDarkMode(!darkMode);
    return toggleDarkMode(!darkMode);
  };

  /**
   * This determines if the contents of the
   * page should be centered or not.
   */
  let isAppRoute = validateAppRoute(
    loggedInRoutes,
    getBaseRoute(location.pathname)
  );
  // Global Material UI Theme
  const appTheme = createTheme(globalThemeSettings(darkMode, isAppRoute));

  // Metadata
  const { description, lang, author, title } = siteMetadata(location.pathname);

  return (
    <ThemeProvider theme={appTheme}>
      <Provider store={combinedStore.store}>
        <PersistGate persistor={combinedStore.persistor}>
          <SEO
            description={description}
            lang={lang}
            author={author}
            title={title}
            isAppRoute={isAppRoute}
          />
          <StyledAppBoundary>
            <StyledApp
              isAppRoute={isAppRoute}
              location={location}
              handleDarkMode={handleDarkMode}
            />
          </StyledAppBoundary>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
