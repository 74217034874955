/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This helper function returns the recently searched for
 * users from local storage.
 * @param {boolean} isSearch - if this is search or post tag
 * @returns {object} the recently searched for users
 */
export const getRecentlySearched = (isSearch = true) => {
  const recent = JSON.parse(
    localStorage.getItem(isSearch ? "recentSearches" : "recentTags")
  );
  let recents = recent ?? {};
  return {
    recents,
    recentHandles: recents ? Object.keys(recents) : [],
  };
};
