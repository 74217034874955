/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Community Feed Action Types
export const LOADING_COMMUNITY_FEED = "LOADING_COMMUNITY_FEED";
export const SET_COMMUNITY_FEED = "SET_COMMUNITY_FEED";
export const CLEAR_COMMUNITY_FEED = "CLEAR_COMMUNITY_FEED";
export const CLEAR_COMMUNITY_FEED_ERRORS = "CLEAR_COMMUNITY_FEED_ERRORS";
export const SET_COMMUNITY_FEED_ERRORS = "SET_COMMUNITY_FEED_ERRORS";

// Friends Feed Action Types
export const LOADING_FRIENDS_FEED = "LOADING_FRIENDS_FEED";
export const SET_FRIENDS_FEED = "SET_FRIENDS_FEED";
export const CLEAR_FRIENDS_FEED = "CLEAR_FRIENDS_FEED";
export const CLEAR_FRIENDS_FEED_ERRORS = "CLEAR_FRIENDS_FEED_ERRORS";
export const SET_FRIENDS_FEED_ERRORS = "SET_FRIENDS_FEED_ERRORS";

// User Feed Action Types
export const LOADING_USER_FEED = "LOADING_USER_FEED";
export const SET_USER_FEED = "SET_USER_FEED";
export const CLEAR_USER_FEED = "CLEAR_USER_FEED";
export const CLEAR_USER_FEED_ERRORS = "CLEAR_USER_FEED_ERRORS";
export const SET_USER_FEED_ERRORS = "SET_USER_FEED_ERRORS";

// Active Profile Feed Action Types
export const LOADING_ACTIVE_PROFILE_FEED = "LOADING_ACTIVE_PROFILE_FEED";
export const SET_ACTIVE_PROFILE_FEED = "SET_ACTIVE_PROFILE_FEED";
export const CLEAR_ACTIVE_PROFILE_FEED = "CLEAR_ACTIVE_PROFILE_FEED";
export const CLEAR_ACTIVE_PROFILE_FEED_ERRORS =
  "CLEAR_ACTIVE_PROFILE_FEED_ERRORS";
export const SET_ACTIVE_PROFILE_FEED_ERRORS = "SET_ACTIVE_PROFILE_FEED_ERRORS";

//Voting Action Types
export const SET_POST_UP_VOTE = "SET_POST_UP_VOTE";
export const SET_RULE_PROPOSAL_UP_VOTE = "SET_RULE_PROPOSAL_UP_VOTE";
export const SET_RULE_REMOVAL_UP_VOTE = "SET_RULE_REMOVAL_UP_VOTE";
export const SET_RULE_CHANGE_UP_VOTE = "SET_RULE_CHANGE_UP_VOTE";
export const SET_POST_DOWN_VOTE = "SET_POST_DOWN_VOTE";
export const SET_RULE_PROPOSAL_DOWN_VOTE = "SET_RULE_PROPOSAL_DOWN_VOTE";
export const SET_RULE_REMOVAL_DOWN_VOTE = "SET_RULE_REMOVAL_DOWN_VOTE";
export const SET_RULE_CHANGE_DOWN_VOTE = "SET_RULE_CHANGE_DOWN_VOTE";

// Comment Action Types
export const SET_NEW_COMMENT = "SET_NEW_COMMENT";
export const SET_POST_COMMENTS = "SET_POST_COMMENTS";
export const SET_DELETE_COMMENT = "SET_DELETE_COMMENT";
export const LOADING_POST_COMMENTS = "LOADING_POST_COMMENTS";
export const SET_POST_COMMENT_ERRORS = "SET_POST_COMMENT_ERRORS";
export const CLEAR_POST_COMMENT_ERRORS = "CLEAR_POST_COMMENT_ERRORS";
export const SET_COMMENT_UPDATE = "SET_COMMENT_UPDATE";
export const SET_COMMENT_UP_VOTE = "SET_COMMENT_UP_VOTE";
export const SET_COMMENT_DOWN_VOTE = "SET_COMMENT_DOWN_VOTE";

// Post Action Types
export const SET_NEW_POST = "SET_NEW_POST";
export const SET_DELETE_POST = "SET_DELETE_POST";
export const SET_POST_UPDATE = "SET_POST_UPDATE";
export const SET_POST_REVIEW = "SET_POST_REVIEW";
export const SET_COMMENT_REVIEW = "SET_COMMENT_REVIEW";
export const LOADING_POST_UPDATE = "LOADING_POST_UPDATE";
export const SET_POST_UPDATE_ERRORS = "SET_POST_UPDATE_ERRORS";
export const CLEAR_POST_UPDATE_ERRORS = "CLEAR_POST_UPDATE_ERRORS";

// Reply Action Types
export const SET_NEW_REPLY = "SET_NEW_REPLY";
export const SET_DELETE_REPLY = "SET_DELETE_REPLY";
export const SET_COMMENT_REPLIES = "SET_COMMENT_REPLIES";
export const LOADING_COMMENT_REPLIES = "LOADING_COMMENT_REPLIES";
export const SET_COMMENT_REPLY_ERRORS = "SET_COMMENT_REPLY_ERRORS";
export const CLEAR_COMMENT_REPLY_ERRORS = "CLEAR_COMMENT_REPLY_ERRORS";
export const SET_REPLY_UPDATE = "SET_REPLY_UPDATE";
export const SET_REPLY_UP_VOTE = "SET_REPLY_UP_VOTE";
export const SET_REPLY_DOWN_VOTE = "SET_REPLY_DOWN_VOTE";

//Active Post Action Types
export const SET_ACTIVE_POST = "SET_ACTIVE_POST";
export const LOADING_ACTIVE_POST = "LOADING_ACTIVE_POST";
export const CLEAR_ACTIVE_POST_ERRORS = "CLEAR_ACTIVE_POST_ERRORS";
export const SET_ACTIVE_POST_ERRORS = "SET_ACTIVE_POST_ERRORS";
export const CLEAR_ACTIVE_POST = "CLEAR_ACTIVE_POST";


// Adventures
export const SET_DELETE_ADVENTURE = "SET_DELETE_ADVENTURE";
export const SET_ADVENTURE_REVIEW = "SET_ADVENTURE_REVIEW";
