/**
 * Copyright 2022 Loop Technology, Inc.
 */

//React
import React, { Suspense, lazy } from "react";

// Router Helpers
import { Routes, Route } from "react-router-dom";
import PostRoute from "./CustomRoutes/postRoute";
import ProfileRoute from "./CustomRoutes/profileRoute";
import PrivateRoute from "./CustomRoutes/privateRoute";
import PublicRoute from "./CustomRoutes/publicRoute";
import LeaderRoute from "./CustomRoutes/leaderRoute";
import AuthRoute from "./CustomRoutes/authRoute";
import RouteLoading from "./RouteComponents/routeLoading";

// Lazy Loaded Imports
const NotFound = lazy(() => import("../404"));
const Home = lazy(() => import("../LoggedOut/Home/home"));
const Policies = lazy(() => import("../LoggedOut/Policies/policies"));
const MediaKit = lazy(() => import("../LoggedOut/MediaKit/mediaKit"));
const UserProfile = lazy(() => import("../LoggedIn/Profile/Pages/userProfile"));
const LeaderHub = lazy(() => import("../LoggedIn/LeaderHub/leaderHub"));
const Loop = lazy(() => import("../LoggedIn/Loop/Loop"));
const Settings = lazy(() => import("../LoggedIn/Settings/settings"));
const Post = lazy(() => import("../LoggedIn/Post/post"));
const Discover = lazy(() => import("../LoggedIn/Discover/discover"));
const Referrals = lazy(() => import("../LoggedIn/Referrals/referrals"));
const UserInfo = lazy(() => import("../LoggedIn/UserInfo/userInfo"));
const Notifications = lazy(() =>
  import("../LoggedIn/Notifications/notifications")
);
const PeopleProfile = lazy(() =>
  import("../LoggedIn/Profile/Pages/peopleProfile")
);
const ManageUsers = lazy(() =>
  import("../LoggedOut/UserManagement/manageUsers")
);
const ForgotPasswordEmailSentMessage = lazy(() =>
  import("../LoggedOut/ForgotPassword/emailSentMessage")
);
const PasswordResetSuccessMessage = lazy(() =>
  import("../LoggedOut/ForgotPassword/successMessage")
);
const ForgotPasswordFlow = lazy(() =>
  import("../LoggedOut/ForgotPassword/forgotPasswordFlow")
);
const Governance = lazy(() => import("../LoggedOut/Governance/governance"));
const Townhall = lazy(() => import("../LoggedIn/Townhall/townhall"));

const LoopRoutes = () => {
  return (
    <Suspense fallback={<RouteLoading />}>
      <Routes>
        <Route path="/community" element={<PrivateRoute component={Loop} />} />
        <Route
          path="/settings"
          element={<PrivateRoute component={Settings} />}
        />
        <Route
          path="/townhall"
          element={<PrivateRoute component={Townhall} />}
        />
        <Route
          path="/profile/:handle"
          element={<ProfileRoute component={PeopleProfile} />}
        />
        <Route
          path="/profile"
          element={<ProfileRoute component={UserProfile} />}
        />
        <Route
          path="/notifications"
          element={<PrivateRoute component={Notifications} />}
        />
        <Route
          path="/invites"
          element={<PrivateRoute component={Referrals} />}
        />
        <Route
          path="/discover/create"
          element={<PrivateRoute component={Discover} />}
        />
        <Route
          path="/discover"
          element={<PrivateRoute component={Discover} />}
        />
        <Route
          path="/updates"
          element={<PrivateRoute component={UserInfo} />}
        />
        <Route path="/post/:postId" element={<PostRoute component={Post} />} />
        <Route
          exact
          path="/leader-hub/review/:id/:type"
          element={<LeaderRoute component={LeaderHub} />}
        />
        <Route
          exact
          path="/leader-hub"
          element={<LeaderRoute component={LeaderHub} />}
        />
        <Route
          path="/update-password"
          element={<PrivateRoute component={ForgotPasswordFlow} />}
        />
        <Route
          path="/update-password/message"
          element={<PrivateRoute component={ForgotPasswordEmailSentMessage} />}
        />
        <Route
          path="/policies"
          element={<PublicRoute component={Policies} />}
        />
        <Route
          path="/media-kit"
          element={<PublicRoute component={MediaKit} />}
        />
        <Route
          path="/governance"
          element={<PublicRoute component={Governance} />}
        />
        <Route
          path="/forgot-password/message"
          element={<PublicRoute component={ForgotPasswordEmailSentMessage} />}
        />
        <Route
          path="/manage-users/passwordReset/message"
          element={<PublicRoute component={PasswordResetSuccessMessage} />}
        />
        <Route
          path="/forgot-password"
          element={<PublicRoute component={ForgotPasswordFlow} />}
        />
        <Route
          path="/manage-users"
          element={<PublicRoute component={ManageUsers} />}
        />
        <Route exact path="/" element={<AuthRoute component={Home} />} />
        <Route element={<PublicRoute component={NotFound} />} />
      </Routes>
    </Suspense>
  );
};

export default LoopRoutes;
