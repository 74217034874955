/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// Component
import StyledDialogFooter from "../../../Dialogs/styledComponents/styledDialogFooter";
import StyledDialogContent from "../../../Dialogs/styledComponents/styledDialogContent";
import StyledCharacteristicsForm from "../../../Forms/styledForms/Auth/styledCharacteristicsForm";

// Material UI
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";

const StyledText = styled("span")(({ theme }) => ({
  marginTop: theme.spacing(1),
  color: theme.palette.warning.main,
  width: "100%",
}));

/**
 * Initial Form State
 */
const BASE_STATE = {
  personality: "",
  identities: [],
  lifestyles: [],
  activities: [],
};

const StyledCharactersiticsDialog = ({ handleClick }) => {
  let formRef = useRef();
  const [valid, setValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  /**
   * This helper function dispatches the
   * form to submit outside of the formik
   * baseForm component.
   */
  const onSubmit = () => {
    formRef.current.submitForm();
  };

  /**
   * This handler function validates the form data
   * and unlocks the button for the user to submit.
   *
   * @param {object} values - the form values
   * @param {object} error - the form error
   * @param {bool} submitting - if the form is submitting
   * @returns {null}
   */
  const handleValidate = (values, error, submitting) => {
    let isValid = true;
    if (submitting !== isSubmitting) setIsSubmitting(submitting);
    if (Object.keys(error).length > 0) return setValid(false);
    Object.keys(values).forEach((value) => {
      if (values[value] === BASE_STATE[value]) isValid = false;
    });
    setValid(isValid);
  };

  return (
    <React.Fragment>
      <StyledDialogContent content="These make it easy for other users to learn more about you and what you enjoy. Choose one thing from each section below.">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12}>
            <StyledCharacteristicsForm
              formRef={formRef}
              handleNext={handleClick}
              handleValidate={handleValidate}
              initialState={BASE_STATE}
            />
          </Grid>
        </Grid>
      </StyledDialogContent>
      <StyledDialogFooter
        handleSubmit={onSubmit}
        text="Next"
        color="info"
        colorType="main"
        disabled={!valid || isSubmitting}
        isSubmitting={isSubmitting}
        footerText={
          !valid && (
            <StyledText>Choose at least one from each section</StyledText>
          )
        }
      />
    </React.Fragment>
  );
};

StyledCharactersiticsDialog.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default StyledCharactersiticsDialog;
