/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Formik
import { FastField } from "formik";

// Material UI
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";

// Styles
const useStyles = (color, colorType, type) =>
  makeStyles((theme) => ({
    cssOutlinedInput: {
      fontSize: 16,
      "&$cssFocused $notchedOutline": {
        borderColor: `${theme.palette[color][colorType]} !important`,
      },
    },
    cssFocused: {
      borderColor: `${theme.palette.grey[300]}`,
    },
    notchedOutline: {
      borderColor: `${theme.palette.grey[300]} !important`,
    },
  }));

const StyledLongInputField = ({
  required = false,
  autoComplete,
  fullWidth,
  placeholder,
  isMobile,
  hasPreviewImages = false,
  isNewPost = false,
  autoFocus = false,
  disableUnderline = false,
  rows = 1,
  rowsMax = 1,
  color = "success",
  colorType = "main",
  ...props
}) => {
  const hasImages = hasPreviewImages && isNewPost;
  const classes = useStyles(color, colorType)();

  return (
    <FastField name={props.name}>
      {({ field, meta }) => {
        const errorText = meta.error && meta.touched ? meta.error : null;
        return (
          <TextField
            {...field}
            autoFocus={autoFocus}
            placeholder={placeholder}
            minRows={rows}
            maxRows={rowsMax}
            variant={disableUnderline ? "standard" : "outlined"}
            required={required}
            fullWidth
            multiline
            sx={fullWidth ? { my: 1 } : { my: 1, width: "100%" }}
            type="input"
            margin="normal"
            autoComplete={autoComplete}
            error={errorText ? true : false}
            helperText={errorText}
            InputLabelProps={{ sx: { fontSize: 16 } }}
            InputProps={
              disableUnderline
                ? {
                    disableUnderline: disableUnderline,
                    sx: {
                      p: 0,
                      fontSize: "20px",
                      lineHeight: isMobile || hasImages ? "20px" : "26px",
                      fontFamily: isMobile || hasImages ? "DM Sans" : "Poppins",
                    },
                  }
                : {
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                  }
            }
          />
        );
      }}
    </FastField>
  );
};

StyledLongInputField.propTypes = {
  hasPreviewImages: PropTypes.bool,
  isNewPost: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  color: PropTypes.oneOf([
    "secondary",
    "primary",
    "success",
    "info",
    "error",
    "warning",
    "text",
    "grey",
  ]),
  colorTypes: PropTypes.oneOf([
    "main",
    "light",
    "dark",
    "secondary",
    "primary",
    200,
    300,
    400,
    500,
  ]),
};

export default StyledLongInputField;
