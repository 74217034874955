/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components & functions
import { createSrcSetHelper } from "../../../../functions/createSrcSetHelper";

// Material UI
import { Box } from "@mui/system";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

// Component
const BaseAvatar = ({
  type,
  image,
  onClick,
  height = 5,
  width = 5,
  imageSet = {},
  isFriend = false,
  isMember = false,
  button = false,
  whiteBorder = false,
}) => {
  const src = ["user", "people"].includes(type) && image ? image : null;
  const srcSet =
    typeof imageSet === "object" && Object.keys(imageSet).length > 0
      ? createSrcSetHelper(imageSet)
      : null;
  const { borderColor, backgroundColor } = getLoopColorHelper(
    type,
    isMember,
    isFriend
  );
  return (
    <Box
      textAlign="center"
      sx={{ width: "100%", maxWidth: width * 9.5, maxHeight: width * 9.5 }}
    >
      <IconButton
        id="outer-loop"
        component="div"
        onClick={onClick}
        disabled={!button}
        size="large"
        sx={{
          background: "transparent",
          borderStyle: "solid",
          position: "relative",
          borderColor: whiteBorder ? "grey.100" : borderColor,
          borderWidth: 2,
          p: 0.25,
        }}
      >
        <Avatar
          src={src}
          srcSet={srcSet}
          aria-labelledby={`loop-${type}-avatar`}
          sx={{
            width: width * 8,
            height: height * 8,
            backgroundColor: backgroundColor,
          }}
        >
          {["user", "people"].includes(type) && (
            <Avatar
              src={src}
              aria-labelledby={`loop-${type}-avatar`}
              sx={{
                width: width * 8,
                height: height * 8,
                backgroundColor: backgroundColor,
              }}
            />
          )}
        </Avatar>
      </IconButton>
    </Box>
  );
};

BaseAvatar.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  image: PropTypes.string,
  imageSet: PropTypes.object,
  type: PropTypes.oneOf(["user", "people"]).isRequired,
  onClick: PropTypes.func,
  button: PropTypes.bool,
  isFriend: PropTypes.bool,
  isMember: PropTypes.bool,
  whiteBorder: PropTypes.bool,
};

export default BaseAvatar;

/**
 * This helper function sets the color of the loop
 * around a given avatar type.
 *
 * @param {string} type - the avatar type
 * @param {boolean} isMember - indicator for avatar member
 * @param {boolean} isFriend - indicator for avatar friend
 * @returns {object} the loop border color and background color
 */
const getLoopColorHelper = (type, isMember, isFriend) => {
  switch (type) {
    case "user":
      return {
        borderColor: "primary.main",
        backgroundColor: "primary.light",
      };
    case "people":
      return isMember
        ? {
            borderColor: "secondary.main",
            backgroundColor: "secondary.light",
          }
        : isFriend
        ? {
            borderColor: "info.main",
            backgroundColor: "info.light",
          }
        : {
            borderColor: "grey.700",
            backgroundColor: "grey.400",
          };
    default:
      return {
        borderColor: "grey.700",
        backgroundColor: "grey.400",
      };
  }
};
