/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { getUserCore } from "../../../../redux/actions/user/atomic/get/getUserCore";
import { getUserCommunity } from "../../../../redux/actions/user/atomic/get/getUserCommunity";

// Components & hooks
import useWebp from "../../../../hooks/useWebp";
import OnboardingFlow from "../../../UI/Flows/Onboarding/onboardingFlow";
import StyledSnackbar from "../../../UI/Snackbar/styledSnackbar";

// Material UI
import { useTheme } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

const ProtectedComponent = ({
  details,
  isNewUser,
  isNewMember,
  getUserCore,
  postId = null,
  getUserCommunity,
  component: Component,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const isLargeTablet = useMediaQuery(theme.breakpoints.only("md"));
  const isComputer = useMediaQuery(theme.breakpoints.only("lg"));
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgDetails, setMsgDetails] = useState(null);
  const supportsWebp = useWebp();

  useEffect(() => {
    if (Object.keys(details).length === 0) {
      getUserCore();
      getUserCommunity();
    }
  }, []);

  /**
   * This handler function opens the message
   * snackbard.
   *
   * @param {object} e - the event object
   * @param {object} details - the snackbar details
   * @param {number} [details.duration] - the snackbar details
   * @param {number} [details.text] - the snackbar text
   * @param {number} [details.color] - the snackbar color
   * @param {number} [details.colorType] - the snackbar color type
   * @returns {null}
   */
  const handleMsgOpen = (e, details) => {
    setMsgOpen(true);
    setMsgDetails(details);
  };

  /**
   * This handler function closes the message in
   * the snackbar notification.
   *
   * @param {object} e - the event object
   * @param {string} reason event reason
   * @returns {null}
   */
  const handleMsgClose = (e, reason) => {
    if (reason === "clickaway") return;
    setMsgOpen(false);
    setMsgDetails(null);
  };

  return (
    <React.Fragment>
      <Component
        postId={postId}
        isMobile={isMobile}
        isTablet={isTablet}
        isComputer={isComputer}
        supportsWebp={supportsWebp}
        isLargeTablet={isLargeTablet}
        handleMsgOpen={handleMsgOpen}
      />
      {isNewUser && (
        <OnboardingFlow
          isMobile={isMobile}
          isTablet={isTablet}
          handleMsgOpen={handleMsgOpen}
        />
      )}
      {isNewMember && !isNewUser &&(
        <OnboardingFlow
          isMobile={isMobile}
          isTablet={isTablet}
          isNewMember={isNewMember}
          handleMsgOpen={handleMsgOpen}
        />
      )}
      {msgOpen && (
        <StyledSnackbar
          isMobile={isMobile}
          open={msgOpen}
          duration={msgDetails?.duration ?? 3000}
          handleClose={handleMsgClose}
          text={msgDetails?.text ?? "success!"}
          color={msgDetails?.color ?? "text"}
          colorType={msgDetails?.colorType ?? "primary"}
          anchorOrigin={{
            vertical: msgDetails?.origin?.vertical ?? "bottom",
            horizontal: msgDetails?.origin?.horizontal ?? "left",
          }}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isNewUser: state.user.core.isNewUser,
  isNewMember: state.user.core.isNewMember,
  details: state.user.core.details,
});

ProtectedComponent.propTypes = {
  isNewUser: PropTypes.bool,
  isNewMember: PropTypes.bool,
};

const mapActionsToProps = {
  getUserCore,
  getUserCommunity,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(React.memo(ProtectedComponent));
