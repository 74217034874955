/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";

// Component
import StyledDialogFooter from "../../../Dialogs/styledComponents/styledDialogFooter";
import StyledDialogContent from "../../../Dialogs/styledComponents/styledDialogContent";
import StyledEditProfileImage from "../../../Dialogs/styledDialogs/styledComponents/styledEditProfileImage";

// Material UI
import Grid from "@mui/material/Grid";

const StyledUploadPhoto = ({ imageUrlThumbs, handleClick }) => {
  return (
    <React.Fragment>
      <StyledDialogContent content="">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} sx={{ my: 4 }}>
            <StyledEditProfileImage />
          </Grid>
        </Grid>
      </StyledDialogContent>
      <StyledDialogFooter
        text="Next"
        handleSubmit={handleClick}
        color="primary"
        colorType="main"
        disabled={!Object.keys(imageUrlThumbs).length > 0}
        secondaryButton={{
          color: "text",
          colorType: "secondary",
          isSubmitting: false,
          handleSubmit: handleClick,
          text: "Skip this for now",
          disabled: false,
        }}
      />
    </React.Fragment>
  );
};

StyledUploadPhoto.propTypes = {
  handleClick: PropTypes.func,
  imageUrlThumbs: PropTypes.object,
};

const mapStateToProps = (state) => ({
  imageUrlThumbs: state.user.core.imageUrlThumbs,
});

export default connect(mapStateToProps)(StyledUploadPhoto);
