/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Material UI
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LeftTransition } from "../Transitions/slides";

const StyledSnackbar = ({
  open,
  text,
  color,
  colorType,
  duration,
  handleClose,
  isMobile,
  ...props
}) => {
  return (
    <Snackbar
      {...props}
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      message={text}
      TransitionComponent={LeftTransition}
      sx={{ mb: isMobile ? 8 : 0 }}
      ContentProps={{
        sx: {
          py: 1,
          fontSize: 15,
          backgroundColor: `${color}.${colorType}`,
          boxShadow: (theme) => theme.shadows[3],
        },
      }}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

StyledSnackbar.propTypes = {
  open: PropTypes.bool,
  color: PropTypes.string,
  colorType: PropTypes.string,
  duration: PropTypes.number,
  isMobile: PropTypes.bool,
  handleClose: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default StyledSnackbar;
