/**
 * Copyright 2022 Loop Technology, Inc.
 */

import axios from "axios";

const getAuthToken = () => localStorage.getItem("AuthToken");

// Obtain the fresh token each time the instance is invoked
let AUTH_TOKEN = getAuthToken();

const refreshAxios = axios.create({
  baseURL: "https://api.looop.cool",
  withCredentials: true,
  credentials: "include",
});

// Set the default instance Auth header o
refreshAxios.defaults.headers.common["Authorization"] = AUTH_TOKEN;

export default refreshAxios;
