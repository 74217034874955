/**
 * Copyright 2022 Loop Technology, Inc.
 */

// Redux
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { combinedReducer } from "./reducers/combinedReducer";

// Initial State
const initState = {};

// Middleware
const middleware = [thunk];

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  //whitelist: ['']
};

const reducers = combineReducers(combinedReducer);

const persistedReducer = persistReducer(persistConfig, reducers);

let store =
  process.env.NODE_ENV === "production"
    ? createStore(
        persistedReducer,
        initState,
        compose(applyMiddleware(...middleware))
      )
    : createStore(
        persistedReducer,
        initState,
        compose(
          applyMiddleware(...middleware),
          window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
        )
      );

const persistor = persistStore(store);

let combinedStore = { store, persistor };

export default combinedStore;
