/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable react-hooks/exhaustive-deps */

// React
import React, { lazy, Suspense, useEffect } from "react";
import PropTypes from "prop-types";

// Redux
import { connect } from "react-redux";
import { getCommunityLeaders } from "../../../redux/actions/user/atomic/get/getCommunityLeaders";
import { getActiveProfileCommunityLeaders } from "../../../redux/actions/activeProfile/atomic/get/getActiveProfileCommunityLeaders";

// Components & Functions
import { getMemberSetDetailsHelper } from "./getMemberSetDetailsHelper";

// Material UI
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import useMediaQuery from "@mui/material/useMediaQuery";

// Lazy Loaded Imports
const StyledUserAvatar = lazy(() =>
  import("../../UI/Avatars/styledAvatars/styledUserAvatar")
);
const StyledPersonAvatar = lazy(() =>
  import("../../UI/Avatars/styledAvatars/styledPersonAvatar")
);

const StyledLeaderSet = ({
  type,
  status,
  isMobile,
  isTablet,
  userHandle,
  communityHandle,
  getCommunityLeaders,
  userCommunityHandle,
  getActiveProfileCommunityLeaders,
  LEADERS = {},
}) => {
  const isTiny = useMediaQuery("(max-width:340px)");
  const isMidMobile = useMediaQuery("(max-width:385px)");
  const isSmallTablet = useMediaQuery("(max-width:575px)");
  const isSmallDesktop = useMediaQuery(
    "(min-width:768px) and (max-width:930px)"
  );
  let { spacing, avatarSize } = getMemberSetDetailsHelper(
    isTiny,
    isMidMobile,
    isMobile,
    isSmallTablet,
    isTablet,
    isSmallDesktop
  );

  useEffect(() => {
    handleFetchLeaders();
  }, []);

  /**
   * This handler function dispatches the fetch
   * to get the community leaders.
   * @returns {function} - the action handler to get leaders
   */
  const handleFetchLeaders = async () => {
    if (type === "user") return await getCommunityLeaders();
    return await getActiveProfileCommunityLeaders(communityHandle);
  };

  const loadingSet = (
    <React.Fragment>
      {[0, 1, 2, 3, 4].map((_, index) => (
        <Skeleton
          key={`community-leader-skeleton-${index}`}
          variant="circular"
          width={avatarSize * 9.5}
          height={avatarSize * 9.5}
          sx={{ my: 1, mx: spacing }}
        />
      ))}
    </React.Fragment>
  );

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ mb: 0.5 }}
    >
      <Grid
        item
        xs={10}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        wrap="nowrap"
      >
        {status !== "loading" ? (
          <React.Fragment>
            {Object.keys(LEADERS).map((leader) => (
              <Suspense fallback={loadingSet}>
                <Grid
                  item
                  sx={{ my: 1, mx: spacing }}
                  key={`community-leader-${LEADERS[leader].handle}`}
                >
                  {leader === userHandle ? (
                    <StyledUserAvatar
                      height={avatarSize}
                      width={avatarSize}
                      isMenuButton
                    />
                  ) : (
                    <StyledPersonAvatar
                      height={avatarSize}
                      width={avatarSize}
                      image={LEADERS[leader].userImage}
                      imageSet={LEADERS[leader]?.imageUrlThumbs ?? {}}
                      firstName={LEADERS[leader].firstName}
                      lastName={LEADERS[leader].lastName}
                      handle={LEADERS[leader].handle}
                      communityHandle={LEADERS[leader].communityHandle}
                      isLeader={LEADERS[leader].isLeader}
                      pronouns={LEADERS[leader]?.pronouns ?? ""}
                      lifestyles={
                        LEADERS[leader]?.characteristics?.lifestyles ?? []
                      }
                      activities={
                        LEADERS[leader]?.characteristics?.activities ?? []
                      }
                      isMember={userCommunityHandle === communityHandle}
                      isMenuButton
                    />
                  )}
                </Grid>
              </Suspense>
            ))}
          </React.Fragment>
        ) : (
          loadingSet
        )}
      </Grid>
    </Grid>
  );
};

StyledLeaderSet.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool,
  communityHandle: PropTypes.string,
  lastMember: PropTypes.string,
  LEADERS: PropTypes.object,
  isEndOfLeaders: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const type = props.type === "user" ? "user" : "activeProfile";
  return {
    status: state[type].community.leaders.api.status,
    userHandle: state[type].core.handle,
    communityHandle: state[type].core.community.communityHandle,
    userCommunityHandle: state.user.core.community.communityHandle,
    LEADERS: state[type].community.leaders.leaders,
  };
};

const mapActionsToProps = {
  getCommunityLeaders,
  getActiveProfileCommunityLeaders,
};

export default connect(mapStateToProps, mapActionsToProps)(StyledLeaderSet);
