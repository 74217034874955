/**
 * Copyright 2022 Loop Technology, Inc.
 */

// React
import React from "react";
import PropTypes from "prop-types";

// Components
import StyledNavLinks from "./Nav/styledNavLinks";

// Matierial UI
import AppBar from "@mui/material/AppBar";

// Component
const Navbar = ({ handleDarkMode }) => {
  return (
    <AppBar color="transparent" position="relative" elevation={0}>
      <StyledNavLinks handleDarkMode={handleDarkMode} />
    </AppBar>
  );
};

Navbar.propTypes = {
  handleDarkMode: PropTypes.func.isRequired,
};

export default Navbar;
