/**
 * Copyright 2022 Loop Technology, Inc.
 */

/**
 * This function returns an indicator if the route is
 * a valid app route.
 *
 * @param {array} loggedInRoutes an array of the logged in routes
 * @param {string} pathname the url route
 * @returns {boolean} if the route is a valid logged out route
 */
export const validateAppRoute = (loggedInRoutes, pathname) => {
  return loggedInRoutes.includes(pathname);
};
