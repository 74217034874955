/**
 * Copyright 2022 Loop Technology, Inc.
 */

/* eslint-disable import/no-anonymous-default-export */
import {
  //setBaseReducer,
  loadingBaseReducer,
  setErrorsBaseReducer,
  clearErrorsBaseReducer,
} from "./HelperFunctions/baseReducers";
import { apiStatus } from "../apiEnums";
import { PURGE } from "redux-persist";

import {
  //Community
  LOADING_COMMUNITY_FEED,
  CLEAR_COMMUNITY_FEED,
  SET_COMMUNITY_FEED,
  CLEAR_COMMUNITY_FEED_ERRORS,
  SET_COMMUNITY_FEED_ERRORS,
  //User
  LOADING_USER_FEED,
  CLEAR_USER_FEED,
  SET_USER_FEED,
  CLEAR_USER_FEED_ERRORS,
  SET_USER_FEED_ERRORS,
  //ActiveProfile
  LOADING_ACTIVE_PROFILE_FEED,
  CLEAR_ACTIVE_PROFILE_FEED,
  SET_ACTIVE_PROFILE_FEED,
  CLEAR_ACTIVE_PROFILE_FEED_ERRORS,
  SET_ACTIVE_PROFILE_FEED_ERRORS,
  //ActivePost
  LOADING_ACTIVE_POST,
  SET_ACTIVE_POST,
  CLEAR_ACTIVE_POST_ERRORS,
  SET_ACTIVE_POST_ERRORS,
  //Votes
  SET_REPLY_UP_VOTE,
  SET_REPLY_DOWN_VOTE,
  SET_POST_UP_VOTE,
  SET_POST_DOWN_VOTE,
  SET_COMMENT_UP_VOTE,
  SET_COMMENT_DOWN_VOTE,
  SET_RULE_CHANGE_UP_VOTE,
  SET_RULE_CHANGE_DOWN_VOTE,
  SET_RULE_REMOVAL_UP_VOTE,
  SET_RULE_REMOVAL_DOWN_VOTE,
  SET_RULE_PROPOSAL_UP_VOTE,
  SET_RULE_PROPOSAL_DOWN_VOTE,
  //Comments
  SET_DELETE_COMMENT,
  SET_NEW_COMMENT,
  SET_POST_COMMENTS,
  LOADING_POST_COMMENTS,
  SET_POST_COMMENT_ERRORS,
  CLEAR_POST_COMMENT_ERRORS,
  SET_COMMENT_UPDATE,
  //Replies
  SET_DELETE_REPLY,
  SET_NEW_REPLY,
  SET_REPLY_UPDATE,
  SET_COMMENT_REPLIES,
  LOADING_COMMENT_REPLIES,
  SET_COMMENT_REPLY_ERRORS,
  CLEAR_COMMENT_REPLY_ERRORS,
  //Posts
  SET_NEW_POST,
  SET_DELETE_POST,
  SET_POST_REVIEW,
  SET_POST_UPDATE,
} from "../actionTypes/feedTypes";

const initState = {
  activePost: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    posts: {},
    lastUpdated: null,
  },
  user: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    posts: {},
    lastDate: null,
    lastId: null,
    isEndOfPosts: false,
    lastUpdated: null,
  },
  activeProfile: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    posts: {},
    lastDate: null,
    lastId: null,
    isEndOfPosts: false,
    lastUpdated: null,
  },
  community: {
    api: {
      status: apiStatus("idle"),
      error: {},
    },
    posts: {},
    lastDate: null,
    lastId: null,
    isEndOfPosts: false,
    lastUpdated: null,
  },
};

const feeds = ["community", "activeProfile", "user", "activePost"];

export default function (state = initState, action) {
  let base;
  switch (action.type) {
    case PURGE:
      return initState;
    // Loading Types
    case LOADING_ACTIVE_POST:
      return loadingBaseReducer(state, "activePost");
    case LOADING_COMMUNITY_FEED:
      return loadingBaseReducer(state, "community");
    case LOADING_USER_FEED:
      return loadingBaseReducer(state, "user");
    case LOADING_ACTIVE_PROFILE_FEED:
      return loadingBaseReducer(state, "activeProfile");
    case LOADING_POST_COMMENTS:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    api: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .api,
                      status: apiStatus("loading"),
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case LOADING_COMMENT_REPLIES:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                      [action.payload.parentCommentId]: {
                        ...state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.parentCommentId],
                        replies: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .replies,
                          api: {
                            ...state[reducer].posts[action.payload.postId]
                              .comments.comments[action.payload.parentCommentId]
                              .replies.api,
                            status: apiStatus("loading"),
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;

    // Success Types
    case SET_ACTIVE_POST:
      return {
        ...state,
        activePost: {
          ...state.activePost,
          api: {
            ...state.activePost.api,
            status: apiStatus("success"),
          },
          posts: { ...action.payload.data },
          lastUpdated: new Date().toISOString(), // When reducer last updated
        },
      };
    case SET_USER_FEED:
      return {
        ...state,
        user: {
          ...state.user,
          api: {
            ...state.user.api,
            status: apiStatus("success"),
          },
          posts: { ...state.user.posts, ...action.payload.data },
          lastUpdated: new Date().toISOString(), // When reducer last updated
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate, // Date of last post
          isEndOfPosts: action.payload.isEndOfDocs,
        },
      };
    case SET_ACTIVE_PROFILE_FEED:
      return {
        ...state,
        activeProfile: {
          ...state.activeProfile,
          api: {
            ...state.activeProfile.api,
            status: apiStatus("success"),
          },
          posts: { ...state.activeProfile.posts, ...action.payload.data },
          lastUpdated: new Date().toISOString(), // When reducer last updated
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate, // Date of last post
          isEndOfPosts: action.payload.isEndOfDocs,
        },
      };
    case SET_COMMUNITY_FEED:
      return {
        ...state,
        community: {
          ...state.community,
          api: {
            ...state.community.api,
            status: apiStatus("success"),
          },
          posts: { ...state.community.posts, ...action.payload.data },
          lastUpdated: new Date().toISOString(), // When reducer last updated
          lastId: action.payload.lastId,
          lastDate: action.payload.lastDate, // Date of last post
          isEndOfPosts: action.payload.isEndOfDocs,
        },
      };
    case CLEAR_COMMUNITY_FEED:
      return {
        ...state,
        community: {
          ...state.community,
          posts: {},
          lastDate: null,
          lastId: null,
          isEndOfPosts: false,
          lastUpdated: null,
        },
      };
    case CLEAR_USER_FEED:
      return {
        ...state,
        user: {
          ...state.user,
          posts: {},
          lastDate: null,
          lastId: null,
          isEndOfPosts: false,
          lastUpdated: null,
        },
      };
    case CLEAR_ACTIVE_PROFILE_FEED:
      return {
        ...state,
        activeProfile: {
          ...state.activeProfile,
          posts: {},
          lastDate: null,
          lastId: null,
          isEndOfPosts: false,
          lastUpdated: null,
        },
      };
    case SET_POST_COMMENTS:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    api: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .api,
                      status: apiStatus("success"),
                    },
                    lastUpdated: new Date().toISOString(), // When reducer last updated
                    lastId: action.payload.lastId,
                    lastDate: action.payload.lastDate, // Date of last comment
                    isEndOfComments: action.payload.isEndOfDocs,
                    comments: {
                      ...action.payload.data,
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_COMMENT_REPLIES:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                      [action.payload.parentCommentId]: {
                        ...state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.parentCommentId],
                        replies: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .replies,
                          api: {
                            ...state[reducer].posts[action.payload.postId]
                              .comments.comments[action.payload.parentCommentId]
                              .replies.api,
                            status: apiStatus("success"),
                          },
                          lastUpdated: new Date().toISOString(), // When reducer last updated
                          lastId: action.payload.lastId,
                          lastDate: action.payload.lastDate, // Date of last reply
                          isEndOfReplies: action.payload.isEndOfDocs,
                          replies: {
                            ...action.payload.data,
                            ...state[reducer].posts[action.payload.postId]
                              .comments.comments[action.payload.parentCommentId]
                              .replies.replies,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_NEW_POST:
      base = { ...state };
      for (const reducer of ["community", "user"]) {
        base = {
          ...base,
          [reducer]: {
            ...state[reducer],
            api: {
              ...state[reducer].api,
              status: apiStatus("success"),
            },
            lastUpdated: new Date().toISOString(),
            posts: {
              [action.payload.postId]: {
                ...action.payload,
              },
              ...state[reducer].posts,
            },
          },
        };
      }
      return base;
    case SET_NEW_COMMENT:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  postDetails: {
                    ...state[reducer].posts[action.payload.postId].postDetails,
                    counts: {
                      ...state[reducer].posts[action.payload.postId].postDetails
                        .counts,
                      commentCount:
                        state[reducer].posts[action.payload.postId].postDetails
                          .counts.commentCount + 1,
                    },
                  },
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    api: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .api,
                      status: apiStatus("success"),
                    },
                    lastUpdated: new Date().toISOString(),
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                      [action.payload.commentId]: {
                        ...action.payload,
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_NEW_REPLY:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  postDetails: {
                    ...state[reducer].posts[action.payload.postId].postDetails,
                    counts: {
                      ...state[reducer].posts[action.payload.postId].postDetails
                        .counts,
                      commentCount:
                        state[reducer].posts[action.payload.postId].postDetails
                          .counts.commentCount + 1,
                    },
                  },
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                      [action.payload.parentCommentId]: {
                        ...state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.parentCommentId],
                        commentDetails: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .commentDetails,
                          counts: {
                            ...state[reducer].posts[action.payload.postId]
                              .comments.comments[action.payload.parentCommentId]
                              .commentDetails.counts,
                            commentCount:
                              state[reducer].posts[action.payload.postId]
                                .comments.comments[
                                action.payload.parentCommentId
                              ].commentDetails.counts.commentCount + 1,
                          },
                        },
                        replies: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .replies,
                          api: {
                            ...state[reducer].posts[action.payload.postId]
                              .comments.comments[action.payload.parentCommentId]
                              .replies.api,
                            status: apiStatus("success"),
                          },
                          lastUpdated: new Date().toISOString(),
                          replies: {
                            ...state[reducer].posts[action.payload.postId]
                              .comments.comments[action.payload.parentCommentId]
                              .replies.replies,
                            [action.payload.commentId]: {
                              ...action.payload,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_POST_UPDATE:
      base = { ...state };
      for (const reducer of ["community", "user", "activePost"]) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              api: {
                ...state[reducer].api,
                status: apiStatus("success"),
              },
              lastUpdated: new Date().toISOString(),
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...action.payload,
                },
              },
            },
          };
        }
      }
      return base;
    case SET_COMMENT_UPDATE:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    api: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .api,
                      status: apiStatus("success"),
                    },
                    lastUpdated: new Date().toISOString(),
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                      [action.payload.commentId]: {
                        ...action.payload,
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_REPLY_UPDATE:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments[action.payload.parentCommentId],
                      replies: {
                        ...state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.parentCommentId].replies,
                        api: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .replies.api,
                          status: apiStatus("success"),
                        },
                        lastUpdated: new Date().toISOString(),
                        replies: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .replies.replies,
                          [action.payload.commentId]: {
                            ...action.payload,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_POST_REVIEW:
      base = { ...state };
      for (const reducer of [
        "community",
        "activeProfile",
        "friends",
        "activePost",
      ]) {
        const reviewType = action.payload.isLoopReview
          ? "underLoopReview"
          : "underLeaderReview";
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              api: {
                ...state[reducer].api,
                status: apiStatus("success"),
              },
              lastUpdated: new Date().toISOString(),
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  postDetails: {
                    ...state[reducer].posts[action.payload.postId].postDetails,
                    entity: {
                      ...state[reducer].posts[action.payload.postId].postDetails
                        .entity,
                      [reviewType]: true,
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_DELETE_POST:
      base = { ...state };
      for (const reducer of ["community", "user", "activePost"]) {
        if (state[reducer].posts[action.payload.postId]) {
          const { [action.payload.postId]: deletedPost, ...remainingPosts } =
            state[reducer].posts;
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              api: {
                ...state[reducer].api,
                status: apiStatus("success"),
              },
              lastUpdated: new Date().toISOString(),
              posts: {
                ...remainingPosts,
              },
            },
          };
        }
      }
      return base;
    case SET_DELETE_COMMENT:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          const {
            [action.payload.commentId]: deletedComment,
            ...remainingComments
          } = state[reducer].posts[action.payload.postId].comments.comments;
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  postDetails: {
                    ...state[reducer].posts[action.payload.postId].postDetails,
                    counts: {
                      ...state[reducer].posts[action.payload.postId].postDetails
                        .counts,
                      commentCount:
                        state[reducer].posts[action.payload.postId].postDetails
                          .counts.commentCount -
                        state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.commentId].commentDetails
                          .counts.commentCount -
                        1,
                    },
                  },
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    api: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .api,
                      status: apiStatus("success"),
                    },
                    lastUpdated: new Date().toISOString(),
                    comments: {
                      ...remainingComments,
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_DELETE_REPLY:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          const {
            [action.payload.commentId]: deletedReply,
            ...remainingReplies
          } =
            state[reducer].posts[action.payload.postId].comments.comments[
              action.payload.parentCommentId
            ].replies.replies;
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  postDetails: {
                    ...state[reducer].posts[action.payload.postId].postDetails,
                    counts: {
                      ...state[reducer].posts[action.payload.postId].postDetails
                        .counts,
                      commentCount:
                        state[reducer].posts[action.payload.postId].postDetails
                          .counts.commentCount -
                        state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.commentId].commentDetails
                          .counts.commentCount -
                        1,
                    },
                  },
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments[action.payload.parentCommentId],
                      replies: {
                        ...state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.parentCommentId].replies,
                        api: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .replies.api,
                          status: apiStatus("success"),
                        },
                        lastUpdated: new Date().toISOString(),
                        replies: {
                          ...remainingReplies,
                        },
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_POST_UP_VOTE:
    case SET_POST_DOWN_VOTE:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              api: {
                ...state[reducer].api,
                status: apiStatus("success"),
              },
              lastUpdated: new Date().toISOString(),
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  isPostDeleted: action.payload.isPostDeleted,
                  postDetails: {
                    ...state[reducer].posts[action.payload.postId].postDetails,
                    counts: {
                      ...state[reducer].posts[action.payload.postId].postDetails
                        .counts,
                      voteCount: action.payload.voteCount,
                    },
                  },
                  userVotes: action.payload.userVotes,
                },
              },
            },
          };
        }
      }
      return base;
    case SET_COMMENT_UP_VOTE:
    case SET_COMMENT_DOWN_VOTE:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              api: {
                ...state[reducer].api,
                status: apiStatus("success"),
              },
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    lastUpdated: new Date().toISOString(),
                    api: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .api,
                      status: apiStatus("success"),
                    },
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                      [action.payload.commentId]: {
                        ...state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.commentId],
                        isCommentDeleted: action.payload.isCommentDeleted,
                        userVotes: action.payload.userVotes,
                        commentDetails: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.commentId]
                            .commentDetails,
                          counts: {
                            ...state[reducer].posts[action.payload.postId]
                              .comments.comments[action.payload.commentId]
                              .commentDetails.counts,
                            voteCount: action.payload.voteCount,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_REPLY_UP_VOTE:
    case SET_REPLY_DOWN_VOTE:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              api: {
                ...state[reducer].api,
                status: apiStatus("success"),
              },
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                      [action.payload.parentCommentId]: {
                        ...state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.parentCommentId],
                        replies: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .replies,
                          api: {
                            ...state[reducer].posts[action.payload.postId]
                              .comments.comments[action.payload.parentCommentId]
                              .replies.api,
                            status: apiStatus("success"),
                          },
                          lastUpdated: new Date().toISOString(),
                          replies: {
                            ...state[reducer].posts[action.payload.postId]
                              .comments.comments[action.payload.parentCommentId]
                              .replies.replies,
                            [action.payload.commentId]: {
                              ...state[reducer].posts[action.payload.postId]
                                .comments.comments[
                                action.payload.parentCommentId
                              ].replies.replies[action.payload.commentId],
                              isCommentDeleted: action.payload.isCommentDeleted,
                              userVotes: action.payload.userVotes,
                              commentDetails: {
                                ...state[reducer].posts[action.payload.postId]
                                  .comments.comments[
                                  action.payload.parentCommentId
                                ].replies.replies[action.payload.commentId]
                                  .commentDetails,
                                counts: {
                                  ...state[reducer].posts[action.payload.postId]
                                    .comments.comments[
                                    action.payload.parentCommentId
                                  ].replies.replies[action.payload.commentId]
                                    .commentDetails.counts,
                                  voteCount: action.payload.voteCount,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_RULE_PROPOSAL_UP_VOTE:
    case SET_RULE_REMOVAL_UP_VOTE:
    case SET_RULE_CHANGE_UP_VOTE:
      base = { ...state };
      for (const reducer of ["community", "activePost"]) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              api: {
                ...state[reducer].api,
                status: apiStatus("success"),
              },
              lastUpdated: new Date().toISOString(),
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  isPostDeleted: action.payload.isPostDeleted,
                  postDetails: {
                    ...state[reducer].posts[action.payload.postId].postDetails,
                    counts: {
                      ...state[reducer].posts[action.payload.postId].postDetails
                        .counts,
                      forCount: action.payload.forCount,
                    },
                  },
                  userHasVoted: true,
                  userVotes: action.payload.userVotes,
                },
              },
            },
          };
        }
      }
      return base;
    case SET_RULE_PROPOSAL_DOWN_VOTE:
    case SET_RULE_REMOVAL_DOWN_VOTE:
    case SET_RULE_CHANGE_DOWN_VOTE:
      base = { ...state };
      for (const reducer of ["community", "activePost"]) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              api: {
                ...state[reducer].api,
                status: apiStatus("success"),
              },
              lastUpdated: new Date().toISOString(),
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  isPostDeleted: action.payload.isPostDeleted,
                  postDetails: {
                    ...state[reducer].posts[action.payload.postId].postDetails,
                    counts: {
                      ...state[reducer].posts[action.payload.postId].postDetails
                        .counts,
                      againstCount: action.payload.againstCount,
                    },
                  },
                  userHasVoted: true,
                  userVotes: action.payload.userVotes,
                },
              },
            },
          };
        }
      }
      return base;

    // Failed Types
    case SET_ACTIVE_POST_ERRORS:
      return setErrorsBaseReducer(state, "activePost", null, {
        error: action.payload,
      });

    case SET_COMMUNITY_FEED_ERRORS:
      return setErrorsBaseReducer(state, "community", null, {
        error: action.payload,
      });
    case SET_USER_FEED_ERRORS:
      return setErrorsBaseReducer(state, "user", null, {
        error: action.payload,
      });
    case SET_ACTIVE_PROFILE_FEED_ERRORS:
      return setErrorsBaseReducer(state, "activeProfile", null, {
        error: action.payload,
      });
    case SET_POST_COMMENT_ERRORS:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    api: {
                      status: apiStatus("failed"),
                      error: action.payload.error,
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case SET_COMMENT_REPLY_ERRORS:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                      [action.payload.parentCommentId]: {
                        ...state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.parentCommentId],
                        replies: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .replies,
                          api: {
                            status: apiStatus("failed"),
                            error: action.payload.error,
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;

    // Idle Types
    case CLEAR_ACTIVE_POST_ERRORS:
      return clearErrorsBaseReducer(state, "activePost");
    case CLEAR_COMMUNITY_FEED_ERRORS:
      return clearErrorsBaseReducer(state, "community");
    case CLEAR_USER_FEED_ERRORS:
      return clearErrorsBaseReducer(state, "user");
    case CLEAR_ACTIVE_PROFILE_FEED_ERRORS:
      return clearErrorsBaseReducer(state, "activeProfile");
    case CLEAR_POST_COMMENT_ERRORS:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    api: {
                      status: apiStatus("idle"),
                      error: {},
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    case CLEAR_COMMENT_REPLY_ERRORS:
      base = { ...state };
      for (const reducer of feeds) {
        if (state[reducer].posts[action.payload.postId]) {
          base = {
            ...base,
            [reducer]: {
              ...state[reducer],
              posts: {
                ...state[reducer].posts,
                [action.payload.postId]: {
                  ...state[reducer].posts[action.payload.postId],
                  comments: {
                    ...state[reducer].posts[action.payload.postId].comments,
                    comments: {
                      ...state[reducer].posts[action.payload.postId].comments
                        .comments,
                      [action.payload.parentCommentId]: {
                        ...state[reducer].posts[action.payload.postId].comments
                          .comments[action.payload.parentCommentId],
                        replies: {
                          ...state[reducer].posts[action.payload.postId]
                            .comments.comments[action.payload.parentCommentId]
                            .replies,
                          api: {
                            status: apiStatus("idle"),
                            error: {},
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          };
        }
      }
      return base;
    default:
      return state;
  }
}